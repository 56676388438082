/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Grid, LinearProgress, Typography,
} from '@material-ui/core';
import {
  change, Field, Form, formValueSelector, reduxForm,
} from 'redux-form';
import WarningIcon from '@material-ui/icons/Warning';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import {
  InputCurrencyRender, InputRender, SelectRender, InputPhoneRender,
} from '../../../../../shared/fields';
import { maxLength5, required } from '../../../../../shared/fields/validate';
import PAYMENT_OPTIONS from '../../../../../shared/utils/paymentOptions';
import PAYMENT_OPTIONS_NO_PIX from '../../../../../shared/utils/paymentOptionsNoPix';
import PAYMENT_OPTIONS_NO_BP from '../../../../../shared/utils/paymentOptions';
import PAYMENT_OPTIONS_NITROUS from '../../../../../shared/utils/paymentOptionsNitrous';
import PAYMENT_OPTIONS_NITROUS_NO_PIX from '../../../../../shared/utils/paymentOptionsNitrousNoPix';

import {
  getInstallmentsAction, openDialogPaymentAction, updatePaymentTypeAction, getPixQRCodeAction,
  openDialogPixAction, postPixSmsAction, postPixWhatsAction, postPixEmailAction,
} from './actions';
import {
  generateLinkAction, savePaymentAction, savePaymentBilletDiscountAction, savePaymentPixAction,
} from '../actions';
import {
  removeAllDiscountAction,
} from '../../../actions';
import AutocompleteRender from '../../../../../shared/fields/AutocompleteRender';
import cardExpirationValidate from '../../../../../shared/validateFields';
import { cardExpiration, cardFormat } from '../../../../../shared/formatFields';
import { cardExpirationNormalize, cardNormalize } from '../../../../../shared/normalizeFields';
import makePayment from '../../../../../shared/utils/makePayment';
import {toCNPJ, toCPF, toCurrency, toMoney} from '../../../../../shared/utils/parsers';
import getCardBrand from '../../../../../shared/utils/getCardBrand';
import { getFreight } from '../../../../../shared/services/checkoutService';
import TradePaymentPix from './TradePaymentPix';
import useStyles from './styles';
const formName = 'checkout/advanced';

const AdvancedPaymentForm = ({
  updatePaymentType, typePayment, savePayment, handleSubmit, checkoutId, conditions,
  dispatch, total, cardInstallments, sellerName, loading, remainderInstallment,
  installments, snack, getInstallments, loadingInstallmets, discountCart,
  customer, paymentTermId, savePaymentBilletDiscount, savePaymentPix, removeAllDiscount,
  remainder, generateLink, hasDiscount, awaitPix, payments, checkout, config, statusCreditRequest,
  getStatusInfoCreditRequest, closeDialogPayment,
}) => {
  const classes = useStyles();
  const [paymentPix, setPaymentPix] = useState('');
  const [inputValue, setInputValue] = useState('');
  const validatePix = config.validadePixEmSegundosPdv / 60;
  const entregaNitroSelecionada = getFreight(checkoutId) === 'entregaNitro';

  const getBrand = (num) => dispatch(change(
    formName,
    'brand',
    getCardBrand(num),
  ));
  useEffect(() => {
    if (typePayment === 'CC' && cardInstallments) {
      const value = installments.find((installment) => (
        installment.quantidadeParcelas === cardInstallments
      ));
      const installmentValue = total / value.quantidadeParcelas;
      dispatch(change(formName, 'installmentsValue', installmentValue));
    }
  }, [typePayment, cardInstallments, total, dispatch, installments]);

  const pPix = payments.filter((a) => { return a.type === 'PX' && a.paymentStatus === 1 })?.length > 0;

  const handleChangeType = (e) => {
    const isDiscountMethod = ['B', 'DN', 'DC', 'PX', 'LP', 'JS', 'B2', 'AZ', 'ML'].includes(e.target.value);
    updatePaymentType(e.target.value);
    dispatch(change(formName, 'total', 0));
  };
  useEffect(() => {
    dispatch(change(formName, 'total', 0));
  }, [paymentTermId, dispatch, hasDiscount]);

  const billetDiscount = [1].includes(paymentTermId);

  const maxPayment = !['B', 'DN', 'DC', 'FN', 'CS', 'LP', 'JS', 'B2', 'AZ', 'ML'].includes(typePayment)
    && !(hasDiscount === 'V' && billetDiscount)
    ? remainder
    : remainder;

  const typesPayment = payments.map((item) => ['PX', 'VA', 'CP', 'B', 'DN', 'DC', 'FN', 'CS', 'LP', 'JS', 'B2', 'AZ', 'ML', 'MZ', 'CC'].includes(item.type));
  const remainderInstallmentPix = config.valorMaximoPixPdv > remainderInstallment ? remainderInstallment : config.valorMaximoPixPdv;
  const remainderPix = config.valorMaximoPixPdv > remainder ? remainder : config.valorMaximoPixPdv;
  const maxPaymentPix = !['PX'].includes(typePayment)
    && !(hasDiscount === 'V' && billetDiscount)
    ? remainderInstallmentPix
    : remainderPix;

  const [pixApiId, setPixApiId] = useState(0);
  const onSubmit = async (info) => {
    try {
      const json = makePayment(checkoutId, info, sellerName, customer);
      if (typePayment === 'PX' && inputValue > 0) {
        if (json.hasDiscount) return savePaymentBilletDiscount(json);
        const { checkoutIdPayment } = await savePaymentPix(json, true);
        setPixApiId(checkoutIdPayment);
        return;
      }
      if (typePayment === 'BP') {
        const valueCondition = conditions.find((condition) => (
          condition.id === json.paymentTermId
        ));
        json.choosedPaymentTerm = valueCondition.nome;
      }
      if (json.hasDiscount) return savePaymentBilletDiscount(json);

      return savePayment(json, true);
    } catch (error) {
      return snack.enqueueSnackbar(error.message || error, { variant: 'warning' });
    }
  };

  useEffect(() => {
    if (statusCreditRequest && typePayment === 'BP') {
      dispatch(change('checkout/advanced', 'total', statusCreditRequest.limiteAprovado));
    }
  }, [statusCreditRequest, typePayment]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1} className={classes.advancedPaymentGrid}>
          <Grid item xs={12} sm={12}>
            <Field
              disabled={typePayment !== 'PX' ? loading : awaitPix}
              name="type"
              type="text"
              label="Forma de Pagamento"
              labelselect="nome"
              valueselect="id"
              component={SelectRender}
              variant="outlined"
              options={entregaNitroSelecionada && !pPix
                ? PAYMENT_OPTIONS_NITROUS : entregaNitroSelecionada && pPix
                ? PAYMENT_OPTIONS_NITROUS_NO_PIX : config.bitPixPdv && !typesPayment.includes(true) && !pPix
                ? PAYMENT_OPTIONS : typesPayment.includes(true) && config.bitPixPdv && !pPix
                ? PAYMENT_OPTIONS_NO_BP : PAYMENT_OPTIONS_NO_PIX}
              validate={[required]}
              model={{
                id: 'code',
                name: 'name',
                disabled: 'disabled',
              }}
              onChange={handleChangeType}
              classes={{ root: classes.selectStyled }}
            />
          </Grid>
          {typePayment !== 'PX'
            ? (
              <>
                <Grid item xs={12} sm={12} >
                  <Field
                    disabled={loading}
                    name="total"
                    component={InputCurrencyRender}
                    label="Valor do pagamento"
                    onBlur={(_e, value) => getInstallments(value)}
                    validate={required}
                    max={maxPayment}
                    variant="outlined"
                  />
                  {
                    statusCreditRequest && typePayment === 'BP' && statusCreditRequest.status !== 'Pendente' &&
                    statusCreditRequest.status !== 'Aberto' && (
                      <Grid item xs={12} sm={12}>
                        {
                          statusCreditRequest.status === 'Reprovado' ? (
                            <Typography classes={{ root: classes.reprovedValue }}>
                              Limite solicitado foi reprovado. Valor:
                              <span>{toCurrency(statusCreditRequest.limiteSolicitado)}</span>
                            </Typography>
                          ) : (
                            <Typography classes={{ root: classes.suggestedValue }}>
                              Valor do pagamento sugerido:
                              <span>{toCurrency(statusCreditRequest.limiteAprovado)}</span>
                            </Typography>
                          )
                        }
                      </Grid>
                    )
                  }
                </Grid>
              </>
            ) : (
              <Grid item xs={12} sm={12} >
                <Field
                  disabled={awaitPix}
                  name="total"
                  component={InputCurrencyRender}
                  label="Valor do pix"
                  onBlur={(_e, value) => getInstallments(value)}
                  validate={required}
                  onChange={(text) => setInputValue(text)}
                  max={maxPaymentPix}
                  endAdornment={
                    <Button className={classes.buttonGerar} type="submit" disabled={awaitPix}>Gerar Pagamento</Button>
                  }
                  variant="outlined"
                />
              </Grid>
            )}

          {typePayment === 'PX' && (
            awaitPix === false ? (
              <Grid item xs={12} className={classes.gridInfo}>
                <Grid item xs={12} className={classes.gridInfoGray}>
                  <div className={classes.divWarning}>
                    <WarningIcon />
                    <span style={{ marginLeft: 5 }}>Atenção</span>
                  </div>
                  <div>
                    <span>
                      <span>Ao clicar em </span>
                      <span style={{ color: '#F44336' }}>GERAR PAGAMENTO</span>
                      <span>, um </span>
                      <span style={{ fontWeight: 'bold' }}>PAGAMENTO PIX </span>
                      <span>sera gerado, o mesmo deve ser ultilizado em até </span>
                      <span style={{ fontWeight: 'bold' }}>
                        {validatePix}
                        {' '}
                        minutos
                      </span>
                      <span> ou o pagamento será cancelado.</span>
                    </span>
                  </div>
                </Grid>
              </Grid>
            )
              : (
                <TradePaymentPix
                  setPaymentPix={setPaymentPix}
                  paymentPix={paymentPix}
                  valueInput={inputValue}
                  pix={pPix !== undefined ? pPix : 0}
                  pixApiId={pixApiId}
                  checkout={checkout}
                />
              )
          )}

          {typePayment === 'BP' && (
          <>
            <Grid item xs={12} sm={12} >
              <Field
                disabled={loading}
                name="paymentTermId"
                component={AutocompleteRender}
                label="Condição"
                validate={required}
                options={conditions}
                model={{
                  id: 'id',
                  name: 'nome',
                }}
                variant="outlined"
                classes={{ root: classes.autocompleteStyled }}
              />
            </Grid>

            {billetDiscount && (
            <Grid item xs={12} sm={12} >
              <Box display="flex" width="100%">
                <Field
                  name="hasDiscount"
                  component={SelectRender}
                  label="Forma de Pagamento"
                  validate={required}
                  options={[{
                    id: 'V',
                    name: 'Valor À Vista',
                  }, {
                    id: 'P',
                    name: 'Valor À Prazo',
                  }]}
                  disabled={loading}
                  variant="outlined"
                />
              </Box>
            </Grid>
            )}
          </>
          )}

          {typePayment === 'CC' && (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}  sm={12} >
                  <Field
                    disabled={loading}
                    name="installments"
                    label="Parcelas *"
                    options={installments}
                    component={SelectRender}
                    validate={[required]}
                    model={{
                      id: 'quantidadeParcelas',
                      name: 'label',
                    }}
                    variant="outlined"
                  />

                  {loadingInstallmets && <LinearProgress />}
                </Grid>

                <Grid item xs={12}  sm={12} >
                  <Field
                    name="installmentsValue"
                    component={InputCurrencyRender}
                    label="Valor da parcela"
                    validate={required}
                    disabled
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Field
                  disabled={loading}
                  name="number"
                  component={InputRender}
                  label="Numero *"
                  validate={required}
                  format={cardFormat}
                  normalize={cardNormalize}
                  onChange={(e) => {
                    getBrand(e.target.value);
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid xs={12}>
                <Field
                  disabled={loading}
                  name="name"
                  component={InputRender}
                  label="Nome *"
                  validate={required}
                  variant="outlined"
                />
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}  sm={12} >
                  <Field
                    disabled={loading}
                    name="cardValidate"
                    component={InputRender}
                    label="Validade *"
                    validate={[required, cardExpirationValidate]}
                    format={cardExpiration}
                    normalize={cardExpirationNormalize}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}  sm={12} >
                  <Field
                    disabled={loading}
                    name="cod"
                    component={InputRender}
                    label="Cod *"
                    normalize={(value = '') => value.replace(/[\D]/g, '').substring(0, 6)}
                    validate={[required, maxLength5]}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12}  sm={12} >
                  <Field
                    disabled={loading}
                    name="phone"
                    component={InputPhoneRender}
                    label="Telefone *"
                    validate={required}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}  sm={12} >
                  <Field
                    disabled={loading}
                    name="cpfCnpj"
                    component={InputRender}
                    label="CPF/CNPJ *"
                    validate={required}
                    format={(value) => {
                      const normalized = value.replace(/\D/gi, '');
                      return normalized.length > 11 ? toCNPJ(normalized) : toCPF(normalized);
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Box pt={3} pb={2} display="flex" gridGap={10} justifyContent="flex-end">
          {['CC'].includes(typePayment) && (
            <Button
              type="button"
              disabled={loading}
              onClick={() => (typePayment === 'CC' && generateLink({
                idCheckout: checkoutId,
                value: total,
                idClient: customer.customerId,
                installments: cardInstallments,
                cep: customer.address.zipCode,
                name: customer.name,
                installmentValue: total / cardInstallments,
              }))}
            >

            </Button>
          )}

          {typePayment !== 'PX' && (
            <>
              <Button
                type="button"
                color="secondary"
                disabled={loading}
                onClick={closeDialogPayment}
                classes={{ root: classes.cancelPaymentBtn }}
              >
                Cancelar
              </Button>

              <Button
                type="submit"
                color="secondary"
                disabled={loading}
                classes={{ root: classes.addPaymentBtn }}
              >
                Adicionar
              </Button>
            </>
          )}
        </Box>
      </Form>
    </>
  );
};

AdvancedPaymentForm.propTypes = {
  updatePaymentType: PropTypes.func.isRequired,
  typePayment: PropTypes.string,
  sellerName: PropTypes.string.isRequired,
  checkoutId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  remainderInstallment: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  remainder: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  total: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  cardInstallments: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  savePayment: PropTypes.func.isRequired,
  savePaymentPix: PropTypes.func.isRequired,
  removeAllDiscount: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getInstallments: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  generateLink: PropTypes.func.isRequired,
  snack: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  awaitPix: PropTypes.bool.isRequired,
  discountCart: PropTypes.number.isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
  loadingInstallmets: PropTypes.bool.isRequired,
  conditions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  installments: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  savePaymentBilletDiscount: PropTypes.func.isRequired,
  paymentTermId: PropTypes.number,
  hasDiscount: PropTypes.string,
  payments: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  checkout: PropTypes.instanceOf(Object).isRequired,
  config: PropTypes.instanceOf(Object).isRequired,
  statusCreditRequest: PropTypes.instanceOf(Object),
  closeDialogPayment: PropTypes.func,
};

AdvancedPaymentForm.defaultProps = {
  typePayment: '',
  conditions: null,
  total: null,
  cardInstallments: null,
  paymentTermId: null,
  hasDiscount: null,
  statusCreditRequest: null,
  closeDialogPayment: () => {},
};

const seletor = formValueSelector(formName);
export default connect((state) => {
  const { config } = state.main.app;
  const { geral, payment } = state.main.checkout;
  const {
    conditions, loading, awaitPix, statusCreditRequest,
  } = payment;
  const {
    customer, checkoutId, resume, discountCart, payments,
  } = geral.checkout;
  const total = seletor(state, 'total');
  const paymentTermId = seletor(state, 'paymentTermId');

  return {
    ...state.main.checkout.advanced,
    statusCreditRequest: statusCreditRequest,
    hasDiscount: seletor(state, 'hasDiscount'),
    snack: state.main.app.snack,
    sellerName: state.auth.login.user.nomeVendedor,
    checkoutId,
    conditions,
    payments,
    remainderInstallment: resume.remainderInstallment,
    paymentTermId,
    remainder: resume.remainder,
    loading,
    awaitPix,
    pixEnabled: !!config.bitPix,
    installments: state.main.checkout.advanced.installments.map((installment) => ({
      ...installment,
      label: `${installment.quantidadeParcelas} - ${toMoney(installment.valorParcela)}`,
    })),
    total,
    cardInstallments: seletor(state, 'installments'),
    discountCart,
    hasPayments: !!payments.length,
    customer,
    initialValues: {
      phone: customer.phone,
      cpfCnpj: customer.document,
      paymentTermId: 11,
    },
    config,
  };
}, {
  openDialogPayment: openDialogPaymentAction,
  openDialogPix: openDialogPixAction,
  updatePaymentType: updatePaymentTypeAction,
  savePayment: savePaymentAction,
  savePaymentPix: savePaymentPixAction,
  removeAllDiscount: removeAllDiscountAction,
  getInstallments: getInstallmentsAction,
  getPixQRCode: getPixQRCodeAction,
  postPixSms: postPixSmsAction,
  postPixWhats: postPixWhatsAction,
  postPixEmail: postPixEmailAction,
  generateLink: generateLinkAction,
  savePaymentBilletDiscount: savePaymentBilletDiscountAction,
})(reduxForm({
  form: 'checkout/advanced',
})(AdvancedPaymentForm));
