import React from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Box, Card, CardContent,
  Typography, Button, CardActions,
} from '@material-ui/core';
import { InputRender } from '../../shared/fields';
import { required } from '../../shared/fields/validate';
import { singinAction } from './action';

const User = ({ handleSubmit, isLoading, singIn }) => (
  <Card>
    <form onSubmit={handleSubmit(async (form) => {
      await singIn({ user: form.identificacao, senha: form.senha });
    })}
    >
      <CardContent>
        <Typography variant="h6" align="left">Portal de Vendas</Typography>
        <Box m={2} />
        <Field
          required
          name="identificacao"
          component={InputRender}
          placeholder="joaosilva"
          label="Usuário"
          validate={required}
        />
        <Box m={2} />
        <Field
          required
          name="senha"
          component={InputRender}
          placeholder="********"
          label="Senha"
          type="password"
          validate={required}
        />
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <Button type="submit" color="primary" disabled={isLoading}>ENTRAR</Button>
      </CardActions>
    </form>
  </Card>
);

const mapStateToProps = (state) => state.auth.login;

User.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  user: PropTypes.instanceOf(Object),
  singIn: PropTypes.func.isRequired,
};

User.defaultProps = {
  user: null,
};

export default connect(mapStateToProps, {
  singIn: singinAction,
})(reduxForm({
  form: 'login/user',
})(User));
