/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  Button,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Select from 'react-select';
import colorStyle from '../../../../../shared/utils/selectBorder';
import useStyles from '../../../styles';
import {
  getVendedoresAtivosAction, postVendedoresAction, putVendedoresAction,
  getCanalDeVendasFilterAction,
} from '../../../actions';

const EditVendedor = ({
  detailsVendedor, putVendedores, page, setEditUser, canaisDeVendaFilter, unNegocios,
  getCanalDeVendasFilter, cargos, permissoes, niveis,
}) => {
  const classes = useStyles();
  const [nome, setNome] = useState(detailsVendedor.nomeVendedor !== null ? `${detailsVendedor.nomeVendedor && detailsVendedor.nomeVendedor}` : '');
  const [nomeExibicao, setNomeExibicao] = useState(detailsVendedor.nomeExibicao !== null ? `${detailsVendedor.nomeExibicao}` : '');
  const [idVendedorERP, setIdVendedorERP] = useState(detailsVendedor.idVendedorERP !== null ? `${detailsVendedor.idVendedorERP}` : '');
  const [login, setLogin] = useState(detailsVendedor.login !== null ? `${detailsVendedor.login}` : '');
  const [emailVendedor, setEmailVendedor] = useState(detailsVendedor.emailVendedor !== null ? `${detailsVendedor.emailVendedor}` : '');
  
  const [ramal, setRamal] = useState(detailsVendedor.ramal !== null ? `${detailsVendedor.ramal}` : '');
  const [status, setStatus] = useState('');
  const [unNegocio, setUnNegocio] = useState('');
  const [canalVendas, setCanalVendas] = useState('');
  const [cargo, setCargo] = useState('');
  const [nivel, setNivel] = useState('');
  const [permissao, setPermissao] = useState(detailsVendedor.permissoesTela !== null ? detailsVendedor.permissoesTela : []);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState('');
  const [reloadField, setReloadField] = useState(false);

  const [blurName, setBlurName] = useState(false);
  const [blurBSeller, setBlurBSeller] = useState(false);
  const [blurEmail, setBlurEmail] = useState(false);
  const [blurLogin, setBlurLogin] = useState(false);
  const [blurRamal, setBlurRamal] = useState(false);
  const [blurNomeExibicao, setBlurNomeExibicao] = useState(false);
  const [blurSenha, setBlurSenha] = useState(false);

  const statusOptions = [
    { value: true, label: 'Ativo' },
    { value: false, label: 'Inativo' },
  ];

  // Alterando valores das variaveis do array para label e value
  const arrayCanalVendas = [];
  canaisDeVendaFilter.forEach((item) => {
    const { idCanalVendas: value, descricao: label } = item;
    arrayCanalVendas.push({ value, label });
  });

  const arrayUnNegocios = [];
  unNegocios.forEach((item) => {
    const { idUnidadeNegocio: value, nomeUnidadeNegocio: label } = item;
    arrayUnNegocios.push({ value, label });
  });

  const arrayCargos = [];
  cargos.forEach((item) => {
    const { id: value, nome: label } = item;
    arrayCargos.push({ value, label });
  });

  const arrayPermissoes = [];
  permissoes.forEach((item) => {
    const { id: value, nome: label } = item;
    arrayPermissoes.push({ value, label });
  });

  const arrayNiveis = [];
  niveis.forEach((item) => {
    const { id: value, nomeCategoria: label } = item;
    arrayNiveis.push({ value, label });
  });

  const handleChangePermissao = (e) => {
    setPermissao(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  // Buscando se existe o mesmo item nos 2 arrays
  const optionCanalVendas = arrayCanalVendas.find((a) => a.value
 === detailsVendedor.idCanalDeVendas);
  // Buscando se existe o mesmo item nos 2 arrays
  const optionUnNegocios = arrayUnNegocios.find((a) => a.value
=== detailsVendedor.idUnidadeDeNegocio);

  const optionCargo = cargos.find((a) => a.id
  === detailsVendedor.idCargo);

  const optionNivel = niveis.find((a) => a.id
  === detailsVendedor.idNivel);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // selectClearRef.current.clearValue();
    setReloadField(!reloadField);
    if (unNegocio !== '') {
      getCanalDeVendasFilter(unNegocio);
    }
  }, [unNegocio]);

  const renderPutVendedores = () => {
    putVendedores({
      idVendedor: detailsVendedor.idVendedor,
      nomeVendedor: nome === '' ? detailsVendedor.nomeVendedor : nome,
      nomeExibicao: nomeExibicao === '' ? detailsVendedor.nomeExibicao : nomeExibicao,
      idVendedorERP: idVendedorERP,
      emailVendedor: emailVendedor,
      login: login,
      ativo: status === '' ? detailsVendedor.ativo : status,
      vendedorLoja3: detailsVendedor.vendedorLoja3,
      abandonoCarrinho: detailsVendedor.abandonoCarrinho,
      idUnidadeDeNegocio: unNegocio === '' ? detailsVendedor.idUnidadeDeNegocio : unNegocio,
      idCanalDeVendas: canalVendas === '' ? detailsVendedor.idCanalDeVendas : canalVendas,
      idCargo: cargo === '' ? detailsVendedor.idCargo : cargo,
      idNivel: nivel === '' ? detailsVendedor.idNivel : nivel,
      permissoesTela: permissao === '' ? detailsVendedor.permissoesTela : permissao,
      senha: password === '' ? detailsVendedor.senha : password,
      ramal,
      page,
    });
    setEditUser(false);
  };
  return (
    <>
      <Paper elevation={1} style={{ width: '95%', padding: 20 }}>
        <Grid item spacing={1} container xs={12}>
          <Grid item xs={6}>
            <Typography className={classes.textsSilver}>NOME COMPLETO</Typography>
            <TextField
              error={blurName}
              onBlur={() => ((nome !== '' && nome.length < 5)
                ? setBlurName(true) : setBlurName(false))}
              helperText={blurName ? 'Minimo 5 Digitos' : ''}
              className={classes.helperMessage}
              size="small"
              fullWidth
              variant="outlined"
              onChange={(e) => setNome(e.target.value)}
              value={nome}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.textsSilver}>NOME EXIBIÇÃO</Typography>
            <TextField
              error={blurNomeExibicao}
              onBlur={() => ((nomeExibicao !== '' && nomeExibicao.length < 5)
                ? setBlurNomeExibicao(true) : setBlurNomeExibicao(false))}
              helperText={blurNomeExibicao ? 'Minimo 5 Digitos' : ''}
              className={classes.helperMessage}
              size="small"
              fullWidth
              variant="outlined"
              onChange={(e) => setNomeExibicao(e.target.value)}
              value={nomeExibicao}
            />
          </Grid>
        </Grid>
        <Grid item spacing={1} container xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={3}>
            <Typography className={classes.textsSilver}>RAMAL</Typography>
            <TextField
              error={blurRamal}
              onBlur={() => ((ramal.length > 0 && ramal.length > 6)
                ? setBlurRamal(true) : setBlurRamal(false))}
              helperText={blurRamal ? 'Máximo 6 Digitos' : ''}
              size="small"
              className={classes.helperMessage}
              fullWidth
              variant="outlined"
              value={ramal.replace(/\D/g, '')}
              onChange={(e) => setRamal(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.textsSilver}>LOGIN</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              value={login}
              error={blurLogin}
              onBlur={() => ((login.length < 5)
                ? setBlurLogin(true) : setBlurLogin(false))}
              helperText={blurLogin ? 'Minimo 5 Digitos' : ''}
              onChange={(e) => setLogin(e.target.value)}
              placeholder={detailsVendedor.login}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.textsSilver}>NOVA SENHA</Typography>
            <TextField
              error={blurSenha}
              onBlur={() => ((password !== '' && password.length < 4)
                ? setBlurSenha(true) : setBlurSenha(false))}
              helperText={blurSenha ? 'Minimo 4 Digitos' : ''}
              className={classes.helperMessage}
              size="small"
              fullWidth
              autoComplete="new-password"
              variant="outlined"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item spacing={1} container xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={5}>
            <Typography className={classes.textsSilver}>E-MAIL</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              onChange={(e) => setEmailVendedor(e.target.value)}
              error={blurEmail}
              onBlur={() => ((!(/([@])\w+/g.test(emailVendedor)))
                ? setBlurEmail(true) : setBlurEmail(false))}
              helperText={blurEmail ? 'Digite um E-mail Valido' : ''}
              value={emailVendedor}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.textsSilver}>ID BSELLER</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              value={idVendedorERP}
              error={blurBSeller}
              onBlur={() => ((idVendedorERP.length < 3 || idVendedorERP.length > 5)
                ? setBlurBSeller(true) : setBlurBSeller(false))}
              helperText={blurBSeller ? 'Minimo 3 e Maximo 5  Digitos' : ''}
              onChange={(e) => setIdVendedorERP(e.target.value)}
              placeholder={detailsVendedor.idVendedorERP}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.textsSilver}>STATUS</Typography>
            <Select
              styles={colorStyle}
              placeholder={detailsVendedor.ativo ? 'Ativo' : 'Inativo'}
              options={statusOptions}
              onChange={(e) => setStatus(e.value)}
            />
          </Grid>
        </Grid>
        <Grid item spacing={2} container xs={12} style={{ marginTop: 10 }}>
          <Grid item xs={3}>
            <Typography className={classes.textsSilver}>UNIDADE DE NEGÓCIO</Typography>
            <Select
              styles={colorStyle}
              placeholder={optionUnNegocios ? optionUnNegocios.label : 'Selecione Aqui...'}
              options={arrayUnNegocios}
              onChange={(e) => setUnNegocio(e.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.textsSilver}>CANAL DE VENDAS</Typography>
            <Select
              styles={colorStyle}
              placeholder={optionCanalVendas && unNegocio === '' ? optionCanalVendas.label : 'Selecione Aqui...'}
              options={arrayCanalVendas}
              onChange={(e) => setCanalVendas(e.value)}
              key={reloadField}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.textsSilver}>CARGO</Typography>
            <Select
              styles={colorStyle}
              placeholder={optionCargo ? optionCargo.nome : 'Selecione Aqui...'}
              options={arrayCargos}
              onChange={(e) => setCargo(e.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.textsSilver}>NÍVEL RECUPERAÇÃO</Typography>
            <Select
              styles={colorStyle}
              placeholder={optionNivel ? optionNivel.nomeCategoria : 'Selecione Aqui...'}
              options={arrayNiveis}
              onChange={(e) => setNivel(e.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.textsSilver}>PERMISSÕES DO VENDEDOR</Typography>
            <Select
              styles={colorStyle}
              isMulti
              placeholder="Selecione aqui..."
              value={arrayPermissoes.filter((obj) => permissao.includes(obj.value))}
              options={arrayPermissoes}
              onChange={handleChangePermissao}
            />
          </Grid>

        </Grid>
        <div className={classes.divButtonCriar}>
          <Tooltip title={detailsVendedor.emailVendedor === null ? 'Este cadastro não possui e-mail, procure algum resposavel para adiciona-lo!' : ''}>
            <span style={{ height: 50 }}>
              <Button
                disabled={
                  (nome.length < 5)
                  || (nomeExibicao.length < 5)
                  || (password !== '' && password.length < 4)
                  || (detailsVendedor.emailVendedor === null)
                  || (ramal.length > 6)
                  || login.length < 5
                  || !(/([@])\w+/g.test(emailVendedor))
                  || idVendedorERP.length < 3
                  || idVendedorERP.length >= 6
                }
                className={classes.buttonCriar}
                onClick={() => renderPutVendedores()}
              >
                Editar
              </Button>
            </span>
          </Tooltip>
        </div>
      </Paper>
    </>
  );
};

EditVendedor.propTypes = {
  putVendedores: PropTypes.func.isRequired,
  detailsVendedor: PropTypes.instanceOf(Object).isRequired,
  canaisDeVendaFilter: PropTypes.arrayOf(Object).isRequired,
  cargos: PropTypes.arrayOf(Object).isRequired,
  niveis: PropTypes.arrayOf(Object).isRequired,
  permissoes: PropTypes.arrayOf(Object).isRequired,
  page: PropTypes.number.isRequired,
  setEditUser: PropTypes.func.isRequired,
  getCanalDeVendasFilter: PropTypes.func.isRequired,
  unNegocios: PropTypes.arrayOf(Object).isRequired,
};

const mapState = (state) => state.main.sales;

export default connect(mapState, {
  getVendedoresAtivos: getVendedoresAtivosAction,
  getCanalDeVendasFilter: getCanalDeVendasFilterAction,
  postVendedores: postVendedoresAction,
  putVendedores: putVendedoresAction,
})(EditVendedor);
