import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  Box, Divider, Grid, Paper,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { toCEP, toMoney } from '../../../../../../shared/utils/parsers';
import useStyles from './styles';

const OrderInfo = ({
  enderecoEntrega, pagamentos, juros, totalPagamento,
}) => {
  const classes = useStyles();

  const mappingPayment = {
    B: 'Boleto',
    PX: 'Pix',
    CC: 'Crédito',
    DB: 'Débito',
    CS: 'Consórcio',
    DC: 'Depósito',
    BP: 'Boleto Prazo',
    CP: 'Cupom',
    DN: 'Dinheiro',
    FN: 'Financiamento',
    VA: 'Vale',
  };

  const showInstallments = (type) => type === 'CC' || type === 'BP';

  const {
    infoTitle,
    infoContent,
    infoPayment,
    infoPaymentTitle,
    infoPaymentValue,
    infoContainer,
    infoContentPayment,
    infoPaymentTotal,
    infoPaper,
  } = classes;

  const {
    cep,
    cidade,
    bairro,
    estado,
    numero,
    endereco,
  } = enderecoEntrega;

  const {
    frete,
    subTotal,
    desconto,
    valorVoucherCupom,
    garantiaEstendida,
  } = pagamentos[0];

  return (
    <div>
      <Grid container className={infoContainer} spacing={2}>
        <Grid item xs={4}>
          <Typography className={infoTitle}>Endereço para entrega:</Typography>
          <Paper className={infoPaper}>
            <Typography className={infoContent}>
              {`${endereco}, ${numero}`}
            </Typography>
            <Typography className={infoContent}>{bairro}</Typography>
            <Typography className={infoContent}>
              {`${cidade} - ${estado}`}
            </Typography>
            <Typography className={infoContent}>{toCEP(cep)}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Typography className={infoTitle}>Detalhes do pagamento:</Typography>
          <Paper className={infoPaper}>
            <Typography className={`${infoContent} ${infoPayment}`}>
              Subtotal:
              <span>{toMoney(subTotal)}</span>
            </Typography>
            <Typography className={`${infoContent} ${infoPayment}`}>
              Frete:
              <span>{toMoney(frete)}</span>
            </Typography>
            <Typography className={`${infoContent} ${infoPayment}`}>
              Desconto:
              <span>{toMoney(desconto)}</span>
            </Typography>
            <Typography className={`${infoContent} ${infoPayment}`}>
              Vale:
              <span>{toMoney(valorVoucherCupom)}</span>
            </Typography>
            <Typography className={`${infoContent} ${infoPayment}`}>
              Garantia:
              <span>{toMoney(garantiaEstendida)}</span>
            </Typography>
            <Typography className={`${infoContent} ${infoPayment}`}>
              Juros:
              <span>{toMoney(juros)}</span>
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Typography className={infoTitle}>Forma de pagamento:</Typography>
          <Paper className={`${infoPaper} center`}>
            <Box
              display="flex"
              flexDirection="column"
              className={infoContentPayment}
              alignItems={pagamentos.length > 1 ? 'flex-start' : 'center'}
            >
              {pagamentos.map((pagamento) => {
                const {
                  type,
                  installmentValue,
                  installmentQuantity,
                } = pagamento;

                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    key={`${pagamento.nomeExibicaoFormaPagamento}-${pagamento.codPedido}`}
                    mb={1}
                  >
                    <Typography className={infoPaymentTitle}>
                      {mappingPayment[type]}
                    </Typography>
                    <Typography className={infoPaymentValue} style={{ marginLeft: 8 }}>
                      {`${showInstallments(type) ? (`${installmentQuantity}x de`) : ''} ${toMoney(installmentValue)}`}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Box height={18}>
              <Divider />
              <Typography className={`${infoContent} ${infoPayment} ${infoPaymentTotal}`}>
                Total:
                <span>{toMoney(totalPagamento || 0)}</span>
              </Typography>
            </Box>
          </Paper>
        </Grid>

      </Grid>
    </div>
  );
};

OrderInfo.defaultProps = {
  totalPagamento: 0,
  juros: 0,
  enderecoEntrega: null,
  pagamentos: null,
};

OrderInfo.propTypes = {
  enderecoEntrega: PropTypes.instanceOf(Object),
  pagamentos: PropTypes.instanceOf(Array),
  totalPagamento: PropTypes.number,
  juros: PropTypes.number,
};

export default OrderInfo;
