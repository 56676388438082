import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import {
	Box,
	Button,
	Chip,
	Divider,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Grid,
	Icon,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	FormHelperText,
	FormControl,
	Input,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Close } from '@material-ui/icons';

import { toMoney, toPercent } from '../../../../shared/utils/parsers';
import { updateStatusDiscountAction } from '../actions';

import useStyles from './styles';
import {DetailsDialog, envVariablesAlgolia} from "../../../../shared/components/algolia";
import algoliasearch from "algoliasearch";

const DiscountDialogDetails = ({
		open,
		close,
		idCargo,
		dataFim,
		discount,
		idVendedor,
		dataInicio,
		updateStatusDiscount,
	}) => {
  const { appId, writeApiKey, indexName, } = envVariablesAlgolia;
  const searchClient = algoliasearch(appId, writeApiKey);
  const indexAlgolia = searchClient.initIndex(indexName);
	const classes = useStyles();

	const [justificativa, setJustificativa] = useState('');
	const [canAprove, setCanAprove] = useState(false);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [product, setProduct] = useState({});

	const trimString = string => string.trimEnd().trimStart();

	const handleAproveOrReprove = action => {
		if (canAprove) {
			updateStatusDiscount({
				dataInicio,
				dataFim,
				reason: justificativa,
				idDiscount: discount.idDesconto,
				idUser: idVendedor,
				action,
			}).finally(() => {
				setCanAprove(false);
				close();
			});
		}
	};

	const handleSkuUrl = sku => {
    indexAlgolia.search('', {
      filters: `objectID:${sku}`,
    })
      .then(({hits}) => {
        if(hits.length) {
          setProduct(hits[0]);
        }
      })
      .catch((error) => console.log('error', error));
  }
	const handleCheckoutId = id => window.open(`${window.location.origin}/checkout/${id}`, '_blank');
	const cargoCanAprove = () => [15,18].includes(idCargo);

	useEffect(() => {
		if (cargoCanAprove()) {
			setCanAprove(true);
		} else {
			setCanAprove(trimString(justificativa).length >= 15);
		}
	}, [justificativa]);

	useEffect(() => {
		setJustificativa('');
		setCanAprove(cargoCanAprove());

	}, [open]);

  useEffect(() => {
    if (product.objectID) {
      setOpenDetailsDialog(true);
    }
  }, [product]);

	return (
		<Dialog open={open} onClose={close} maxWidth="lg" fullWidth>
			<Box position="relative">
				<Box position="absolute" top={4} right={4}>
					<IconButton onClick={close}>
						<Close />
					</IconButton>
				</Box>
			</Box>

			<DialogTitle className={classes.dialogTitle} id="form-dialog-title">
				Detalhes do Desconto
			</DialogTitle>

			<DialogContent>
				<Box display="flex" flexDirection="column">
					<Grid className={classes.rowInfo} container spacing={2}>
						<Grid className={classes.containerInfo} item>
							<Typography className={classes.detailsRowBold} variant="subtitle2">
								Dados do Orçamento
							</Typography>
							<Box className={classes.detailsRow} marginBottom={1}>
								<Typography className={`${classes.detailsRow} ${classes.textAlignEndMobile}`} variant="subtitle2">
									Nº Orçamento
								</Typography>
								<Typography color="secondary" className={classes.tableBodyCellClickable} variant="subtitle2">
									<Box
										onClick={() => handleCheckoutId(discount.checkoutId)}
										component="span"
										display="flex"
										alignItems="center"
										justifyContent="center"
										gridGap={6}
									>
										{discount.checkoutId}
										<Icon sx={{ fontSize: 7 }} fontSize="inherit">
											open_in_new
										</Icon>
									</Box>
								</Typography>
							</Box>
							<Box className={classes.detailsRow}>
								<Typography variant="subtitle2">Margem</Typography>
								<Typography variant="subtitle2" className={classes.textAlignEndMobile}>{discount.margemTotal}</Typography>
							</Box>
							<Box className={classes.detailsRow}>
								<Typography variant="subtitle2">Desconto total em Porcentagem</Typography>
								<Typography color="primary" className={`${classes.detailsRowBold} ${classes.textAlignEndMobile}`} variant="subtitle2">
									{toPercent(discount.descTotalEmPorcentagem)}
								</Typography>
							</Box>
							<Box className={classes.detailsRow}>
								<Typography variant="subtitle2">Total Produtos sem desconto</Typography>
								<Typography variant="subtitle2" className={classes.textAlignEndMobile}>{toMoney(discount.totProdSemDesconto)}</Typography>
							</Box>
							<Box className={classes.detailsRow}>
								<Typography variant="subtitle2">Total Produtos com desconto</Typography>

								<Typography color="primary" className={`${classes.detailsRowBold} ${classes.unbreakingLineMobile} ${classes.textAlignEndMobile}`} variant="subtitle2">
									{toMoney(discount.totProdComDesconto)}
								</Typography>
							</Box>
						</Grid>

						<Grid className={classes.containerInfo} item>
							<Box marginBottom={1}>
								<Typography className={classes.detailsRowBold} variant="subtitle2">
									Dados da Venda
								</Typography>
							</Box>

							<Box className={classes.detailsRow}>
								<Typography variant="subtitle2">Cliente</Typography>
								<Typography variant="subtitle2" className={classes.textAlignEndMobile}>{discount.nomeCliente}</Typography>
							</Box>

							<Box className={classes.detailsRow}>
								<Typography variant="subtitle2">Vendedor</Typography>
								<Typography variant="subtitle2" className={classes.textAlignEndMobile}>{discount.nomeVendedor}</Typography>
							</Box>

							<Box className={classes.detailsRow}>
								<Typography variant="subtitle2">Canal de Vendas</Typography>
								<Typography variant="subtitle2" className={classes.textAlignEndMobile}>{discount.canalDeVendas}</Typography>
							</Box>

							<Box className={classes.detailsRow}>
								<Typography variant="subtitle2">Data do Orçamento</Typography>
								<Typography variant="subtitle2" className={classes.textAlignEndMobile}>{discount.dtCriacaoOrcamento}</Typography>
							</Box>

							<Box className={classes.detailsRow} display="flex" justifyContent="space-between">
								<Typography variant="subtitle2">Data Solicitação desconto</Typography>
								<Typography color="primary" className={`${classes.detailsRowBold} ${classes.textAlignEndMobile}`} variant="subtitle2">
									{discount.dtSolicitacaoDesconto}
								</Typography>
							</Box>
						</Grid>
					</Grid>

					<Divider />

					<Box paddingBottom={2} paddingTop={2}>
						<Typography color="primary" className={classes.detailsRowBold} variant="subtitle2">
							Justificativa da Solicitação
						</Typography>
						<Typography className={classes.detailsRow} variant="subtitle2">
							{discount.justificativa}
						</Typography>
					</Box>

					<Typography className={classes.detailsRowBold} variant="subtitle2">
						Produtos
					</Typography>

					<TableContainer style={{ overflow: 'auto', maxHeight: 200 }}>
						<Table stickyHeader sx={{ minWidth: 650, height: 50 }} size="small" aria-label="a dense table">
							<TableHead>
								<TableRow className={classes.tableHeadRow}>
									<TableCell align="center">SKU</TableCell>
									<TableCell align="left">Preço S/ Desc.</TableCell>
									<TableCell align="left">Preço C/ Desc.</TableCell>
									<TableCell align="left">Preço site</TableCell>
									<TableCell align="center">Margem</TableCell>
									<TableCell align="center">Porcentagem</TableCell>
									<TableCell align="center">Qtde</TableCell>
									<TableCell align="left">Desconto Total</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{discount.produtos?.map(produto => (
									<TableRow key={produto.idSku} className={produto.precoSiteMaiorQuePdv && classes.tableRowError}>
										<TableCell component="th" scope="row">
											<Typography variant="subtitle2" color="secondary" className={classes.tableBodyCellClickable}>
												<Box
													onClick={() => handleSkuUrl(produto.idSku)}
													component="span"
													display="flex"
													alignItems="center"
													justifyContent="center"
													gridGap={6}
												>
													{produto.idSku}
													<Icon sx={{ fontSize: 7 }} fontSize="inherit">
														open_in_new
													</Icon>
												</Box>
											</Typography>
										</TableCell>
										<TableCell align="left">{toMoney(produto.precoSemDesconto)}</TableCell>
										<TableCell align="left">{toMoney(produto.precoComDesconto)}</TableCell>
										<TableCell align="left">{toMoney(produto.precoSite)}</TableCell>
										<TableCell align="center">{produto.margem}</TableCell>
										<TableCell align="center">
											<Chip label={toPercent(produto.descEmPorcentagem)} color={'primary'} />
										</TableCell>
										<TableCell align="center">{produto.quantidade}</TableCell>
										<TableCell align="left" className={classes.detailsRowBold}>
											{toMoney(produto.descEmReais)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<Divider />

					{discount.mensagemAlerta && (
						<Box paddingTop={3}>
							<Alert severity={discount.aprovacaoPermitida ? 'warning' : 'error'}>
								<Typography className={classes.detailsRowBold} variant="subtitle2">
									{discount.mensagemAlerta}
								</Typography>
							</Alert>
						</Box>
					)}

					<Box paddingTop={3} display="flex" flexDirection="column">
						<Typography className={classes.detailsRowBold} variant="subtitle2">
							Justificativa
						</Typography>

						<FormControl onChange={e => setJustificativa(e.target.value)} error={justificativa.length > 15 && !canAprove}>
							<Input id="user" />
							<FormHelperText id="username-helper" children={<></>}>
								<Box display="flex" justifyContent="space-between">
									{!cargoCanAprove() && (
										<>
											<Typography variant="subtitle2">
												{justificativa.length > 15 && !canAprove ? 'Justificativa deve ter no mínimo 15 caracteres' : ''}
											</Typography>
											<Typography color={trimString(justificativa).length < 15 ? 'error' : ''} variant="subtitle2">
												{trimString(justificativa).length}/15 caracteres
											</Typography>
										</>
									)}
								</Box>
							</FormHelperText>
						</FormControl>
					</Box>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button disabled={!canAprove} onClick={() => handleAproveOrReprove(2)} className={classes.buttonReprove} variant="outlined">
					Reprovar
				</Button>
				<Button
					onClick={() => handleAproveOrReprove(1)}
					disabled={!discount.aprovacaoPermitida || !canAprove}
					className={classes.buttonAprove}
					disableElevation
					variant="contained"
				>
					Aprovar
				</Button>
			</DialogActions>

      <DetailsDialog
        setOpen={setOpenDetailsDialog}
        open={openDetailsDialog}
        product={product}
        pplInfo={product.ppl || null}
        outsideSearch
      />
		</Dialog>
	);
};

DiscountDialogDetails.propTypes = {
	close: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	discount: PropTypes.object.isRequired,
	dataInicio: PropTypes.string,
	dataFim: PropTypes.string,
};

const mapState = state => ({
	...state.main.discount.list,
	idVendedor: state.auth.login.user.idVendedor,
	idCargo: state.auth.login.user.idCargo,
});

export default connect(mapState, {
	updateStatusDiscount: updateStatusDiscountAction,
})(
	reduxForm({
		form: 'discount/dialog',
	})(DiscountDialogDetails)
);
