const APP_KEY = 'PDV-LOJA-DO-MECANICO';

export const getClient = () => {
  const client = localStorage.getItem(`${APP_KEY}-CLIENT`);

  return client ? JSON.parse(client) : null;
};

export const clearClient = () => localStorage.removeItem(`${APP_KEY}-CLIENT`);

export const setClient = (client) => {
  clearClient();

  localStorage.setItem(`${APP_KEY}-CLIENT`, JSON.stringify(client));
};
