import React from 'react';
import { Link } from 'react-router-dom';
import { CardActions, CardContent, Grid } from '@material-ui/core';
import { Card, Divider, ButtonActions } from '../_components';
import ButtonAccount from './_components/buttonAccount/ButtonAccount';
import useStyles from './styles';

const RegisterPage = () => {
  const {
    cardGrid,
    cardContent,
  } = useStyles();
  return (
    <Grid className={cardGrid}>
      <Card title="Olá, seja bem-vindo!" subtitle="Qual tipo de conta você deseja criar?">
        <CardContent className={cardContent}>
          <Grid>
            <ButtonAccount
              component={Link}
              to="/register/f"
              title="Conta Pessoal"
              subtitle="Para Pessoa Física"
              icon="person-natural"
            />
            <Divider />
            <ButtonAccount
              component={Link}
              to="/register/j"
              title="Conta Empresa"
              subtitle="Para Pessoa Jurídica"
              icon="person-legal"
            />
          </Grid>
        </CardContent>
        <CardActions>
          <Divider>Cliente já possui cadastro?</Divider>
          <ButtonActions
            component={Link}
            to="/client-login"
            variant="outlined"
            color="primary"
            fullWidth
          >
            Entrar
          </ButtonActions>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default RegisterPage;
