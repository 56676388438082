import types from './types';
import resolveError from '../../../shared/utils/resolveError';
import {
  getRelatorioCoordenador, getDetailsRelatorioCoordenador, takeCheckout, getVendedores,
  putVendedores, getCheckouts, getCheckoutsDetails, getCheckoutByDoc, recoveryDenied,
  agendamentoAccept, recoveryGiveUp, recuperar, getPedidos, getListDeclinio, postDeclinio,
} from './repository';
import { push } from '../../../history';

export const getRelatorioCoordenadorAction = (rpp, filterDataInicio, filterDataFim,
  filterVendedor, filterStatus, filterCanal, filterValor, page,
  filterTipo) => async (dispatch, getState) => {
  dispatch({ type: types.GET_RELATORIO_COORDENADOR_REQUEST });
  try {
    const { user: { idGrupo } } = getState().auth.login;
    const { results, totalpages } = await getRelatorioCoordenador({
      idGrupo,
      filterDataInicio,
      filterDataFim,
      filterVendedor,
      filterStatus,
      filterCanal,
      filterValor,
      filterTipo,
      page,
      rpp,
    });

    dispatch({
      type: types.GET_RELATORIO_COORDENADOR_SUCCESS,
      payload: {
        results,
        totalpages,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_RELATORIO_COORDENADOR_FAILURE });
  }
};
export const getDetailsRelatorioCoordenadorAction = (checkoutId) => async (dispatch, getState) => {
  dispatch({ type: types.GET_DETAIL_RELATORIO_COORDENADOR_REQUEST });
  try {
    const { results } = await getDetailsRelatorioCoordenador({
      checkoutId,
    });

    dispatch({
      type: types.GET_DETAIL_RELATORIO_COORDENADOR_SUCCESS,
      payload: {
        results,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_DETAIL_RELATORIO_COORDENADOR_FAILURE });
  }
};

export const getCheckoutsAction = (params = {}) => async (dispatch, getState) => {
  dispatch({ type: types.GET_CHECKOUT_REQUEST });
  try {
    const { user: { idVendedor } } = getState().auth.login;
    const { checkouts } = await getCheckouts({
      idVendedor,
    });

    dispatch({
      type: types.GET_CHECKOUT_SUCCESS,
      payload: {
        checkouts,
        params,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_CHECKOUT_FAILURE });
  }
};

export const getCheckoutByDocAction = (docCliente, params = {}) => async (dispatch, getState) => {
  dispatch({ type: types.GET_CHECKOUT_BY_DOC_REQUEST });
  try {
    const { checkoutsSearch } = await getCheckoutByDoc({
      docCliente,
    });

    if (checkoutsSearch.length < 1) {
      const { snack } = getState().main.app;
      snack.enqueueSnackbar('Sem carrinhos encontrados para os dados informados.', { variant: 'info' });
    }

    dispatch({
      type: types.GET_CHECKOUT_BY_DOC_SUCCESS,
      payload: {
        checkoutsSearch,
        params,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_CHECKOUT_BY_DOC_FAILURE });
  }
};

export const getVendedoresAction = (checkoutId) => async (dispatch, getState) => {
  dispatch({ type: types.GET_VENDEDORES_REQUEST });
  const { user: { idGrupo } } = getState().auth.login;
  try {
    const { results } = await getVendedores({
      checkoutId,
      idGrupo,
    });

    dispatch({
      type: types.GET_VENDEDORES_SUCCESS,
      payload: {
        results,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_VENDEDORES_FAILURE });
  }
};
export const putVendedoresAction = (checkoutId, filterVendedor) => async (dispatch, getState) => {
  dispatch({ type: types.PUT_VENDEDORES_REQUEST });
  const { snack } = getState().main.app;
  try {
    const { results } = await putVendedores({
      checkoutId,
      filterVendedor,
    });

    dispatch({
      type: types.PUT_VENDEDORES_SUCCESS,
      payload: {
        results,
      },
    });
    snack.enqueueSnackbar('Vendedor atualizado com sucesso!', { variant: 'success' });
  } catch (e) {
    snack.enqueueSnackbar('Erro ao atualizar vendedor', { variant: 'error' });
    dispatch({ type: types.PUT_VENDEDORES_FAILURE });
  }
};

export const getCheckoutsDetailsAction = (
  checkoutId,
) => async (dispatch, getState) => {
  dispatch({ type: types.GET_CHECKOUT_DETAIL_REQUEST });
  try {
    const checkoutDetail = await getCheckoutsDetails({ checkoutId });
    dispatch({
      type: types.GET_CHECKOUT_DETAIL_SUCCESS,
      payload: {
        checkoutDetail,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_CHECKOUT_DETAIL_FAILURE });
  }
};

export const setCheckoutMetaAction = (payload) => ({
  type: types.SET_META,
  payload,
});

export const clearMetaAction = () => ({ type: types.CLEAR_META });

export const recoveryDeniedAction = ({ checkoutId, motivo }) => async (dispatch, getState) => {
  dispatch({ type: types.PUT_RECOVERY_DENIED_REQUEST });
  const { snack } = getState().main.app;
  try {
    const { idVendedor } = getState().auth.login.user;
    await recoveryDenied({
      checkoutId,
      idVendedor,
      motivo,
    });
    dispatch({ type: types.PUT_RECOVERY_DENIED_SUCCESS });
    snack.enqueueSnackbar('Sem contato salvo com sucesso!', { variant: 'success' });
    await dispatch(getCheckoutsAction());
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.PUT_RECOVERY_DENIED_FAILURE });
  }
};
export const recoveryGiveUpAction = ({ checkoutId, motivo }) => async (dispatch, getState) => {
  dispatch({ type: types.PUT_RECOVERY_DENIED_REQUEST });
  const { snack } = getState().main.app;
  try {
    const { idVendedor } = getState().auth.login.user;
    await recoveryGiveUp({
      checkoutId,
      idVendedor,
      motivo,
    });
    dispatch({ type: types.PUT_RECOVERY_DENIED_SUCCESS });
    snack.enqueueSnackbar('Desistencia salva com sucesso!', { variant: 'success' });
    await dispatch(getCheckoutsAction());
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.PUT_RECOVERY_DENIED_FAILURE });
  }
};
export const agendamentoAction = ({
  checkoutId, observacao, dataHora,
}) => async (dispatch, getState) => {
  dispatch({ type: types.AGENDAMENTO_REQUEST });
  const { snack } = getState().main.app;
  try {
    const { idVendedor } = getState().auth.login.user;
    await agendamentoAccept({
      checkoutId,
      idVendedor,
      observacao,
      dataHora,
    });
    dispatch({ type: types.AGENDAMENTO_SUCCESS });

    snack.enqueueSnackbar('Agendamento realizado com sucesso!', { variant: 'success' });
    await dispatch(getCheckoutsAction());
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.AGENDAMENTO_FAILURE });
  }
};
export const recuperarAction = ({
  checkoutId, codCliente,
}) => async (dispatch, getState) => {
  dispatch({ type: types.AGENDAMENTO_REQUEST });
  const { snack } = getState().main.app;
  try {
    const { idVendedor } = getState().auth.login.user;
    const responseData = await recuperar({
      checkoutId,
      codCliente,
      userId: idVendedor,
    });
    dispatch({ type: types.AGENDAMENTO_SUCCESS });
    push(`/checkout/${responseData.results}`);
    snack.enqueueSnackbar('Orçamento Recuperado com sucesso!', { variant: 'success' });
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.AGENDAMENTO_FAILURE });
  }
};

export const takeCheckoutAction = ({ checkoutId }) => async (dispatch, getState) => {
  dispatch({ type: types.TAKE_CHECKOUT_REQUEST });
  const { snack } = getState().main.app;
  try {
    const { idVendedor } = getState().auth.login.user;

    const message = await takeCheckout({
      checkoutId,
      idVendedor,
    });
    dispatch({ type: types.TAKE_CHECKOUT_SUCCESS });
    snack.enqueueSnackbar(message, { variant: 'success' });

    dispatch(clearMetaAction());
    push(`/checkout/${checkoutId}`);
  } catch (e) {
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.TAKE_CHECKOUT_FAILURE });
  }
};

export const openFilterAction = () => ({
  type: types.OPEN_FILTER,
});

export const closeFilterAction = () => ({
  type: types.CLOSE_FILTER,
});

export const getPedidosAction = () => async (dispatch, getState) => {
  dispatch({ type: types.GET_PEDIDOS_REQUEST });
  try {
    const { idVendedor } = getState().auth.login.user;
    const { results: { response } } = await getPedidos({ idVendedor });
    dispatch({
      type: types.GET_PEDIDOS_SUCCESS,
      payload: {
        results: response,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({
      type: types.GET_PEDIDOS_FAILURE,
    });
  }
};

export const getListDeclinioAction = () => async (dispatch, getState) => {
  dispatch({ type: types.GET_LIST_OPTION_REQUEST });
  try {
    const { data } = await getListDeclinio();
    dispatch({
      type: types.GET_LIST_OPTION_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
    dispatch({ type: types.GET_LIST_OPTION_FAILURE });
  }
};

export const postDeclinedAction = ({
  idRecuperacao,
  idMotivo, mensagemMotivo,
}) => async (dispatch, getState) => {
  dispatch({ type: types.POST_DECLINIO_PEDIDO_REQUEST });
  try {
    const { idVendedor } = getState().auth.login.user;
    await postDeclinio({
      idRecuperacao,
      idMotivo,
      mensagemMotivo,
    });
    const { results: { response } } = await getPedidos({ idVendedor });
    dispatch({
      type: types.POST_DECLINIO_PEDIDO_SUCCESS,
      payload: {
        results: response,
      },
    });
  } catch (e) {
    dispatch({ type: types.POST_DECLINIO_PEDIDO_FAILURE });
  }
};
