import { makeStyles } from '@material-ui/core';
import { CopyIcon } from '../../../../assets/algolia';
const useStyles = makeStyles(() => ({
  cardGrid: {
    maxWidth: 1200,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  dividerContent: {
    marginTop: 12,
    marginBottom: 12,
  },

  accordionFilial: {
    '&&': {
      '&.MuiAccordion-root:before': {
        backgroundColor: 'transparent'
      },
      '&.MuiAccordion-root.Mui-expanded': {
        margin: 0,
        marginBottom: 14,
      },
      border: '1px solid #CFCFD4',
      padding: '12px 24px',
      maxWidth: '1200px',
      marginBottom: 14,
      borderRadius: 16,
      backgroundColor: '#FFFFFF',
      boxShadow: 'none',
    },
  },
  accordionFilialDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  accordionFilialSummary: {
    '&&': {
      padding: 0,
      borderRadius: 8,
      minHeight: 70,
      '&.MuiAccordionSummary-content.Mui-expanded': {
        minHeight: 70,
        margin: 0
      },
      '& .MuiSvgIcon-root': {
        color: '#FF5225',
        height: 28,
        width: 28,
      },
    }
  },


  copyIcon: {
    backgroundImage: `url(${CopyIcon})`,
    backgroundRepeat: 'no-repeat',
    '& svg path': {
      fill: '#FE5000'
    },
    width: 12,
    height: 12,
    marginRight: 4,
  },

  titleCompany:{
    fontSize: 12,
    color: '#7B7F82',

    '& span': {
      fontSize: 16,
      color: '#242424',
      fontWeight: '600',
    }
  }
}));

export default useStyles;
