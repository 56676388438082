import React, { useState } from "react";

import * as PropTypes from "prop-types";
import { Field } from "redux-form";

import { Box, IconButton, InputAdornment, Tooltip, Typography } from "@material-ui/core";
import InputRender from "../../InputRender";
import { allowedFilesExtension } from "../../../utils/creditRequests";

import useStyles from "./styles";
import {ClearRounded, LiveHelp} from "@material-ui/icons";

const InputFormFile = ({
  name,
  label,
  type,
  value,
  disabled,
  validate,
  required,
  onChange,
  hint,
  component,
  multiline,
  rows,
  endAdornment,
  accept,
  variant,
  errorInfoButton,
  ...props
}) => {
  const {
    input,
    inputText,
    errorField,
    disabledLabel,
    endAdornmentIcon,
    hintField,
    labelField,
    fieldContent,
    inputExtensionErrorIcon,
    inputExtensionErrorContainer,
    clearIconBtn,
    borderError,
    colorError,
    iconError,
  } = useStyles();

  const [fileName, setFileName] = useState("");
  const [inputExtensionError, setInputExtensionError] = useState(false);

  class FileInput extends React.Component {

    handleChange = async (e, isDrop) => {
      const { input } = this.props;

      if (!e) {
        setInputExtensionError(false);
        setFileName('');
        input.onChange(null);
        return;
      }

      const targetFile = isDrop ? e.dataTransfer.files[0] : e.target.files[0];
      const { name } = targetFile;
      const type = name.split('.').pop();

      if (!allowedFilesExtension.includes(type.toLowerCase())) {
        setInputExtensionError(type);
        return;
      } else {
        setInputExtensionError(false);
      };

      if (targetFile) {
        setFileName(targetFile.name)
        input.onChange(targetFile)
      } else {
        input.onChange(null)
      }
    }

    handleDragOver = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    handleDrop = (event) => {
      event.preventDefault();
      event.stopPropagation();

      this.handleChange(event, true);
    };

    render = () => {
      const {
        meta: { touched, error },
      } = this.props;
      return (
        <div className={fieldContent} disabled={disabled}>
          <label
            className={`
              ${labelField}
              ${disabled && disabledLabel}
            `}
            htmlFor={name}
          >
            {`${label} ${required ? "*" : ""}`}
          </label>

          <Tooltip title={fileName} interactive={!!fileName}>
            <label
              id={`${name}-label`}
              onDragOver={this.handleDragOver}
              onDrop={this.handleDrop}
              htmlFor={name} className={`${input} ${touched && error && borderError}`}>
              <Typography className={`${inputText} ${touched && error && colorError} ${disabled && 'disabled'}`}>{fileName || `Anexar ` + label}</Typography>
              {
                fileName && (
                  <IconButton
                    classes={{root: `${clearIconBtn} ${touched && error && colorError}`}}
                    onClick={(event) => {
                      this.handleChange(null);
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                    disabled={disabled}
                  >
                    <ClearRounded/>
                  </IconButton>
                )
              }
              <span className={`icon ${touched && error && iconError} ${disabled && 'disabled'}`}></span>
              <input
                id={name}
                key={name}
                type="file"
                onChange={this.handleChange}
                accept={accept}
                disabled={disabled}
              />
            </label>
          </Tooltip>

          {endAdornment && (
            <InputAdornment position="end" className={endAdornmentIcon}>
              {endAdornment}
            </InputAdornment>
          )}

          {hint && <span className={hintField}>{hint}</span>}
          {touched && error && <span className={errorField}>{error}</span>}

          {inputExtensionError && (
            <Box className={inputExtensionErrorContainer}>
              <Typography className={errorField}>Tipo de arquivo não permitido .{inputExtensionError}</Typography>

              {errorInfoButton && (
                <Tooltip title={errorInfoButton.tooltip}>
                  <IconButton className={inputExtensionErrorIcon} onClick={errorInfoButton.handle} disabled={disabled}>
                    <LiveHelp />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}

        </div>
      );
    }
  }

  return (
    <Field
      {...props}
      name={name}
      id={name}
      key={name}
      type="file"
      component={FileInput}
      onChange={onChange}
      validate={validate}
    ></Field>
  );
};

InputFormFile.defaultProps = {
  required: false,
  type: "input",
  variant: "outlined",
  disabled: false,
  multiline: false,
  rows: 4,
  validate: [],
  component: InputRender,
};

InputFormFile.propTypes = {
  component: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  hint: PropTypes.string,
  endAdornment: PropTypes.node,
  multiline: PropTypes.bool,
  accept: PropTypes.string,
  rows: PropTypes.number,
  type: PropTypes.string,
  variant: PropTypes.string,
  validate: PropTypes.array,
  required: PropTypes.bool,
  errorInfoButton: PropTypes.shape({
    handle: PropTypes.func.isRequired,
    tooltip: PropTypes.string.isRequired,
  }),
};

export default InputFormFile;
