import React, { useRef , useEffect, useState }  from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  Avatar,
  Divider,
  Button,
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardContent,
  LinearProgress,
} from '@material-ui/core';
import { useHotkeys } from 'react-hotkeys-hook';

import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  closeMenuAction, openMenuAction, setFilialAction,
} from '../../actions';
import {logoutAction, singinUserFiliaisAction} from '../../../auth/login/action';

import useStyles from './styles';
import apiService from '../../../shared/services/apiService';
import { getCheckoutAction } from '../../checkout/actions';
import {clientLogoutAction} from "../../authClient/login/actions";

const StyledMenu = withStyles({
  paper: {
    marginTop: 4,
    marginRight: 4,
  },
  list: {
    padding: 0,
  },
})(({
  anchorEl, open, onClose, children,
}) => (
  <Menu
    id="simple-menu"
    getContentAnchorEl={null}
    keepMounted
    elevation={2}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}
  >
    {children}
  </Menu>
));

function UserAvatar({
  menuRef,
  closeMenu,
  openMenu,
  logout,
  singinUserFiliais,
  user,
  loadingFilials,
  setFilial,
  filial,
  checkout,
  getCheckout,
  clientLogout,
}) {
  const classes = useStyles();
  const avatarRef = useRef(null);
  const [filiais, setFiliais] = useState(user.filiais);

  const handleClick = async () => {
    openMenu(avatarRef.current);
    const res = await apiService.get(`/login/vendedor/${user.idVendedor}`);
    // const { data: { results : r } } = res;

    // const {
    //   data: {
    //     results,
    //   },
    // } = await apiService.post('/login/vendedor', {
    //   identificacao: r.login,
    //   senha: r.senha,
    // });

    setFiliais(res?.data.results.value.filiais);
  };

  const handleClose = () => {
    closeMenu();
  };

  const exit = () => {
    handleClose();
    clientLogout();
    logout();
    localStorage.removeItem('filtersCheckout');
    localStorage.removeItem('filtersOrder');
  };

  const handleFilial = ({ target }) => {
      singinUserFiliais(filiais.find((item) => item.idFilial === target.value), user);
      setFilial(
        filiais.find((item) => item.idFilial === target.value),
      );
      if (checkout?.checkoutId != undefined) {
        getCheckout({ idCheckout: checkout.checkoutId, startRequest: true, calculateTax: false });
      }
  }

  useHotkeys('alt+a', () => (menuRef ? handleClose() : handleClick()), [
    handleClick,
    handleClose,
  ]);

  return (
    <div>
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={classes.root}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        ref={avatarRef}
      >
        <Avatar className={classes.avatar}>
          {user.nomeVendedor[0].toUpperCase()}
        </Avatar>
      </div>

      <StyledMenu
        anchorEl={menuRef}
        open={!!menuRef}
        onClose={handleClose}
      >
        <div className={classes.menu}>
          <Box p={2} textAlign="center">
            <Avatar
              className={classes.bigAvatar}
            >
              {user.nomeVendedor[0].toUpperCase()}
            </Avatar>
            <Box pt={2}>
              <Typography className={classes.userName}>
                {user.nomeVendedor.toLowerCase()}
              </Typography>
              <Typography className={classes.sectorName}>Id: {user.idVendedor}</Typography>
            </Box>
          </Box>

          <Divider />

          <CardContent>
            <FormControl fullWidth>
              <InputLabel id="filials-label">Filial</InputLabel>
              <Select
                labelId="filials-label"
                id="filial"
                onChange={handleFilial}
                style={{ textAlign: 'start' }}
                value={filial ? filial.idFilial : null}
              >
                {filiais.map((item) => (
                  <MenuItem value={item.idFilial} key={`filial-${item.idFilial}`}>
                    {item.nomeFilial}
                  </MenuItem>
                ))}
              </Select>
              {loadingFilials && <LinearProgress />}
            </FormControl>
          </CardContent>

          <Divider />

          <Box pt={2} pb={2}>
            <Button variant="outlined" onClick={exit}>
              SAIR DESSA CONTA
            </Button>
          </Box>
        </div>
      </StyledMenu>
    </div>
  );
}

UserAvatar.propTypes = {
  logout: PropTypes.func.isRequired,
  singinUserFiliais: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  openMenu: PropTypes.func.isRequired,
  menuRef: PropTypes.instanceOf(Element),
  user: PropTypes.instanceOf(Object).isRequired,
  loadingFilials: PropTypes.bool,
  setFilial: PropTypes.func.isRequired,
  getCheckout: PropTypes.func.isRequired,
  filial: PropTypes.instanceOf(Object),
  clientLogout: PropTypes.func.isRequired,
};

UserAvatar.defaultProps = {
  menuRef: null,
  loadingFilials: false,
  filial: null,
};

const mapState = (state) => {
  const { checkout } = state.main.checkout.geral;
  return ({
    ...state.auth.login,
    ...state.main.app,
    checkout,
  });
};

export default connect(mapState, {
  logout: logoutAction,
  clientLogout: clientLogoutAction,
  singinUserFiliais: singinUserFiliaisAction,
  closeMenu: closeMenuAction,
  openMenu: openMenuAction,
  getCheckout: getCheckoutAction,
  setFilial: setFilialAction,
})(UserAvatar);
