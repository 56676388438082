export default {
  RESET_CHECKOUT: '@checkout/RESET_CHECKOUT',
  OPEN_RESEND_LINK: '@checkout/OPEN_RESEND_LINK',
  CLOSE_RESEND_LINK: '@checkout/CLOSE_RESEND_LINK',
  OPEN_DIALOG_CREDIT_CARD_QRCODE: '@checkout/OPEN_DIALOG_CREDIT_CARD_QRCODE',
  CLOSE_DIALOG_CREDIT_CARD_QRCODE: '@checkout/CLOSE_DIALOG_CREDIT_CARD_QRCODE',
  OPEN_MANUAL_PAYMENT: '@checkout/OPEN_MANUAL_PAYMENT',
  CLOSE_MANUAL_PAYMENT: '@checkout/CLOSE_MANUAL_PAYMENT',
  OPEN_DIALOG_ROMOTE_LINK: '@checkout/OPEN_DIALOG_ROMOTE_LINK',
  CLOSE_DIALOG_ROMOTE_LINK: '@checkout/CLOSE_DIALOG_ROMOTE_LINK',
  SAVE_PAYMENT_REQUEST: '@checkout/SAVE_PAYMENT_REQUEST',
  SAVE_PAYMENT_SUCCESS: '@checkout/SAVE_PAYMENT_SUCCESS',
  SAVE_PAYMENT_FAIL: '@checkout/SAVE_PAYMENT_FAIL',
  SAVE_PAYMENT_PIX_REQUEST: '@checkout/SAVE_PAYMENT_PIX_REQUEST',
  SAVE_PAYMENT_PIX_SUCCESS: '@checkout/SAVE_PAYMENT_PIX_SUCCESS',
  SAVE_PAYMENT_PIX_FAIL: '@checkout/SAVE_PAYMENT_PIX_FAIL',
  REMOVE_PAYMENT_REQUEST: '@checkout/REMOVE_PAYMENT_REQUEST',
  REMOVE_PAYMENT_SUCCESS: '@checkout/REMOVE_PAYMENT_SUCCESS',
  COUPON_VALE_LIST_LOAD: '@checkout/COUPON_VALE_LIST_LOAD',
  COUPON_VALE_LIST: '@checkout/COUPON_VALE_LIST',
  REMOVE_PAYMENT_FAIL: '@checkout/REMOVE_PAYMENT_FAIL',
  GET_CONDITIONS_REQUEST: '@checkout/GET_CONDITIONS_REQUEST',
  GET_CONDITIONS_SUCCESS: '@checkout/GET_CONDITIONS_SUCCESS',
  GET_CONDITIONS_FAIL: '@checkout/GET_CONDITIONS_FAIL',
  SET_PAYMENT: '@checkout/SET_PAYMENT',
  SET_CASHIER_PAYMENT: '@checkout/SET_CASHIER_PAYMENT',
  CLEAR_CASHIER_PAYMENT: '@checkout/CLEAR_CASHIER_PAYMENT',
  SEARCH_INSTALLMENTS_REQUEST: '@checkout/SEARCH_INSTALLMENTS_REQUEST',
  SEARCH_INSTALLMENTS_SUCCESS: '@checkout/SEARCH_INSTALLMENTS_SUCCESS',
  SEARCH_INSTALLMENTS_FAIL: '@checkout/SEARCH_INSTALLMENTS_FAIL',
  CREDIT_REQUEST_RESUME_REQUEST: '@checkout/CREDIT_REQUEST_RESUME_REQUEST',
  CREDIT_REQUEST_RESUME_SUCCESS: '@checkout/CREDIT_REQUEST_RESUME_SUCCESS',
  CREDIT_REQUEST_RESUME_FAIL: '@checkout/CREDIT_REQUEST_RESUME_FAIL',
  REQUEST_CREDIT_REQUEST: '@checkout/REQUEST_CREDIT_REQUEST',
  REQUEST_CREDIT_SUCCESS: '@checkout/REQUEST_CREDIT_SUCCESS',
  REQUEST_CREDIT_FAIL: '@checkout/REQUEST_CREDIT_FAIL',
  STATUS_INFO_REQUEST_CREDIT_REQUEST: '@checkout/STATUS_INFO_REQUEST_CREDIT_REQUEST',
  STATUS_INFO_REQUEST_CREDIT_SUCCESS: '@checkout/STATUS_INFO_REQUEST_CREDIT_SUCCESS',
  STATUS_INFO_REQUEST_CREDIT_FAIL: '@checkout/STATUS_INFO_REQUEST_CREDIT_FAIL',
};
