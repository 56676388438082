import React from 'react';
import {
  FormControl, TextField, MenuItem, LinearProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const SelectRender = ({
  input,
  label,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  disabled,
  required,
  classes,
  meta: { error, touched },
  endAdornment,
  variant,
  readOnly,
  options,
  model,
  loading,
  valueSelected,
}) => (
  <FormControl fullWidth>
    <TextField
      select
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...input}
      value={valueSelected !== undefined ? valueSelected : input.value}
      label={label}
      variant={variant || 'standard'}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      autoComplete="off"
      classes={classes}
      error={touched && !!error}
      helperText={touched && error}
      onChange={(event) => {
        input.onChange(event);
        onChange(event);
      }}
      onFocus={(event) => {
        input.onFocus(event);
        onFocus(event);
      }}
      onBlur={(event) => {
        input.onBlur(event);
        onBlur(event);
      }}
      InputProps={{
        endAdornment,
        readOnly: readOnly || !options
          .filter((option) => option[model.id] !== input.value).length,
      }}
      style={{ textAlign: 'start' }}
    >
      {options
        .map((option) => (
          <MenuItem
            value={option[model.id]}
            key={`item-${option[model.id]}`}
            disabled={option.disabled}
          >
            {option[model.name]}
          </MenuItem>
        ))}
    </TextField>
    {loading && <LinearProgress />}
  </FormControl>
);

SelectRender.defaultProps = {
  placeholder: '',
  disabled: false,
  required: false,
  loading: false,
  options: [],
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  classes: '',
  model: {
    id: 'id',
    name: 'name',
    disabled: 'disabled',
  },
};

SelectRender.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  classes: PropTypes.instanceOf(Object),
  meta: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool,
  model: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  // eslint-disable-next-line react/require-default-props
  endAdornment: PropTypes.node,
  // eslint-disable-next-line react/require-default-props
  variant: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Object),
  ])),
};

export default SelectRender;
