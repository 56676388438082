import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { push } from '../../../../history';
import { Types } from '../../../authClient/login';
import { clearClient, getClient } from '../../../../shared/services/authClient';

const MenuClientSpace = ({
  client,
  handleTabChange,
  tabValue,
}) => {
  const dispatch = useDispatch();
  const {
    clientMenuGrid,
    clientMenuHeader,
    clientMenuTabs,
    icon,
    logoutBox,
    logoutBtn,
    logoutIcon,
    optionBox,
    optionTab,
    subtitle,
    title,
  } = useStyles();
  const [options, setOptions] = useState([]);
  const handleOptions = () => {
    const op = [
      {
        icon: 'data',
        title: 'Dados Cadastrais',
        subtitle: 'Edite seus dados cadastrais',
        tabName: 'registration-data',
      },
      {
        icon: 'address',
        title: client.tipoPessoa && client.tipoPessoa.includes('J') ? 'Empresas do Cliente' : 'Endereços do Cliente',
        subtitle: client.tipoPessoa && client.tipoPessoa.includes('J') ? 'Gerenciar empresas' : 'Gerenciar endereços',
        tabName: 'addresses',
      },
      {
        icon: 'orders',
        title: 'Pedidos do Cliente',
        subtitle: 'Gerenciar pedidos',
        tabName: 'client-orders',
      },
      {
        icon: 'vouchers',
        title: 'Vales',
        subtitle: 'Gerenciar vales',
        tabName: 'coupons-and-vouchers',
      },
      // {
      //   icon: 'favorites',
      //   title: 'Favoritos',
      //   subtitle: 'Ver lista de desejos',
      //   tabName: 'favorites',
      // },
    ];

    // if (client.tipoPessoa.includes('J')) {
    //   options.push({
    //     icon: 'credit-request',
    //     title: 'Solicitação de Crédito',
    //     subtitle: 'Gerenciar limite disponível',
    //     tabName: 'credit-request',
    //   });
    // }

    return op;
  };

  const exit = () => {
    dispatch({
      type: Types.SET_CLIENT_SUCCESS,
      payload: { client: null, clients: null },
    });

    clearClient();
    push('/client-login');
  };

  useEffect(() => {
    const op = handleOptions();
    setOptions(op);
  }, [client]);

  return (
    <Grid className={clientMenuGrid}>
      <Box>
        <Box className={clientMenuHeader}>
          <Typography variant="h3">Espaço do Cliente</Typography>
          {
            client.nomeRazaoSocial && (
              <Typography variant="h4">{`Cliente: ${client && client.nomeRazaoSocial}`}</Typography>
            )
          }
        </Box>
        <Tabs
          orientation="vertical"
          value={tabValue}
          onChange={handleTabChange}
          aria-label="tabs-menu-client-space"
          classes={{ root: clientMenuTabs }}
        >
          {
            options.map((option, index) => (
              <Tab
                icon={<span className={`${icon} ${option.icon}`} />}
                label={(
                  <Box className={optionBox}>
                    <span className={title}>{option.title}</span>
                    <span className={subtitle}>{option.subtitle}</span>
                  </Box>
                )}
                id={`tab-option-${index}`}
                aria-controls={`tab-panel-option-${index}`}
                classes={{ root: `${optionTab} ${index === tabValue && 'selected'}` }}
              />
            ))
          }
        </Tabs>
      </Box>
      <Box className={logoutBox}>
        <Button
          className={logoutBtn}
          onClick={exit}
          type="button"
        >
          <span className={logoutIcon} />
          <span>Sair da conta</span>
        </Button>
      </Box>
    </Grid>
  );
};

MenuClientSpace.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
  handleTabChange: PropTypes.func.isRequired,
  tabValue: PropTypes.number.isRequired,
};

const mapState = (state) => ({
  client: getClient() || state.authClient.client,
});

export default connect(mapState, {
})(MenuClientSpace);
