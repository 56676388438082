import React from 'react';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ResponsiveTable from '../../../shared/components/responsiveTable';
import { getCheckoutAction } from '../../checkout/actions';
import useStyles from './styles';
import CheckoutsTableBody from '../_components/checkoutsTableBody/CheckoutsTableBody';

const Orcamentos = ({
  checkouts, loading,
}) => {
  const classes = useStyles();
  const {
    tableHead,
    tableRow,
    tableCellHead,
    tableBody,
    checkoutTable,
  } = classes;

  return (
    <>
      <ResponsiveTable loading={loading} striped className={checkoutTable}>
        <TableHead classes={{ root: tableHead }}>
          <TableRow classes={{ root: tableRow }}>
            <TableCell classes={{ root: tableCellHead }} component="th">
              Orçamento
            </TableCell>
            <TableCell classes={{ root: tableCellHead }} component="th">
              Cliente
            </TableCell>
            <TableCell classes={{ root: tableCellHead }} component="th">
              Documento
            </TableCell>
            <TableCell classes={{ root: tableCellHead }} component="th">
              Itens
            </TableCell>
            <TableCell classes={{ root: tableCellHead }} component="th">
              Telefone
            </TableCell>
            <TableCell classes={{ root: tableCellHead }} component="th">
              Valor
            </TableCell>
            <TableCell classes={{ root: tableCellHead }} component="th">
              Última Alteração
            </TableCell>
            <TableCell classes={{ root: tableCellHead }} component="th">
              Criação
            </TableCell>
            <TableCell classes={{ root: tableCellHead }} component="th">
              Vendedor
            </TableCell>
            <TableCell classes={{ root: tableCellHead }} component="th" />
          </TableRow>
        </TableHead>
        <TableBody classes={{ root: tableBody }}>
          {loading && (
          <TableRow>
            <TableCell colSpan={12} datatype="Mensagem" className="message">
              Buscando Orçamentos
            </TableCell>
          </TableRow>
          )}

          {!loading && !checkouts.length && (
          <TableRow>
            <TableCell colSpan={10} datatype="Mensagem" className="message">
              Nenhum orçamento foi encontrado
            </TableCell>
          </TableRow>
          )}
          {!loading && checkouts.map((item, index) => (
            <CheckoutsTableBody
              checkout={item}
              index={index}
              key={index}
            />
          ))}
        </TableBody>
      </ResponsiveTable>
    </>
  );
};

const mapState = (state) => {
  const { checkout } = state.main.checkout.geral;
  return ({
    ...state.main.checkoutsAndOrders,
    checkout,
  });
};

Orcamentos.propTypes = {
  checkouts: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(mapState, {
  getCheckout: getCheckoutAction,
})(Orcamentos);
