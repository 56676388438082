import React, { useState, useEffect } from 'react';
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  Button,
  Tooltip,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHotkeys } from 'react-hotkeys-hook';

import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField/TextField';

import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import useStyles from './styles';
import { toCPF, toCNPJ } from '../../../utils/parsers';
import { cpfValid } from '../../../fields/validate';
import { hideAddInfoAction, showAddInfoAction } from '../../../../main/checkout/actions';

const AdditionalInfo = ({
  readOnly, onSubmit, initialValues, isPickup, typePerson, lockedOpen, hideAddInfo, showAddInfo,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(!!lockedOpen);
  const [form, setForm] = useState(initialValues);
  const [touchDocument, setTouchDocument] = useState(false);

  const handleChange = ({ target }) => setForm({
    ...form,
    [target.name]: target.value,
  });

  const handleChangeNumber = ({ target: { value, name } }) => handleChange({
    target: {
      name,
      value: value.replace(/([\D])/g, ''),
    },
  });

  const handleToggle = () => {
    setOpen(!open);
    if (lockedOpen) {
      hideAddInfo();
    } else {
      showAddInfo();
    }
  };

  const handleClose = () => {
    setOpen(false);
    hideAddInfo();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (typePerson === 'F' && cpfValid(form.documentoNF)) return;
    onSubmit(form);
    handleToggle();
  };

  useEffect(() => {
    if (form.classificacaoCliente !== 7 && typePerson === 'F') {
      handleChange({
        target: {
          name: 'inscricaoEstadual',
          value: '',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.classificacaoCliente]);

  useHotkeys('shift+i', handleToggle);

  useEffect(() => {
    if (lockedOpen) setOpen(lockedOpen);
  }, [lockedOpen]);

  return (
    <>
      <Tooltip title="shift + I" placement="left">
        <Box
          style={{
            fontSize: '11px', color: '#FA4616', border: '1px solid #FA4616', borderRadius: '5px', padding: '4px', marginRight: '4%',
          }}
          component="button"
          display="flex"
          justifyContent="space-between"
          onClick={handleToggle}
          width="100%"
          id="add-info-button"
          className={classes.header}
        >

          <div style={{ margin: 'auto', width: '105px' }}>INFORMAÇÕES NF</div>
        </Box>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <Box position="relative">
          <Box position="absolute" top={4} right={4}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>

          <DialogTitle>
            Informações NF
          </DialogTitle>

          <form onSubmit={handleSubmit}>
            <div className={classes.form}>
              <List className={classes.root}>
                <TextField
                  label={`${typePerson === 'F' ? 'CPF' : 'CNPJ'} da Nota`}
                  name="documentoNF"
                  className={classes.field}
                  size="small"
                  variant="outlined"
                  onChange={handleChangeNumber}
                  onBlur={() => setTouchDocument(true)}
                  disabled={typePerson === 'J'}
                  required
                  inputProps={{ readOnly }}
                  value={typePerson === 'F' ? toCPF(form.documentoNF) : toCNPJ(form.documentoNF)}
                  helperText={
                  typePerson === 'J'
                    ? 'Para mudar o cnpj, vá no espaço do cliente.'
                    : touchDocument && cpfValid(form.documentoNF)
                    }
                  error={touchDocument && !!cpfValid(form.documentoNF)}
                />

                {isPickup && (
                <TextField
                  label="Responsável Retirar Produto"
                  name="nomeClienteRetiraCD"
                  className={classes.field}
                  size="small"
                  variant="outlined"
                  onChange={handleChange}
                  inputProps={{ readOnly }}
                  value={form.nomeClienteRetiraCD}
                />
                )}

                <FormControl
                  size="small"
                  variant="outlined"
                  className={classes.field}
                >
                  <InputLabel id="classificacaoCliente-label">
                    Classificação Cliente
                  </InputLabel>

                  <Select
                    name="classificacaoCliente"
                    labelId="classificacaoCliente-label"
                    label="Classificação Cliente"
                    onChange={handleChange}
                    inputProps={{ readOnly }}
                    disabled={typePerson === 'J'}
                    value={form.classificacaoCliente}
                  >
                    <MenuItem value="">
                      <Box height={20} />
                    </MenuItem>
                    <MenuItem value={1}>Pessoa Jurídica com IE Contribuinte</MenuItem>
                    <MenuItem value={4}>Pessoa Jurídica SUFRAMA</MenuItem>
                    <MenuItem value={5}>Pessoa Jurídica sem IE</MenuItem>
                    <MenuItem value={6}>Pessoa Pública</MenuItem>
                    <MenuItem value={7}>Produtor Rural</MenuItem>
                    <MenuItem value={8}>Pessoa Jurídica com IE Não Contribuinte</MenuItem>
                  </Select>
                </FormControl>

                {(form.classificacaoCliente === 7 || typePerson === 'J') && (
                  <TextField
                    label="Inscrição Estadual"
                    name="inscricaoEstadual"
                    className={classes.field}
                    size="small"
                    variant="outlined"
                    onChange={handleChangeNumber}
                    inputProps={{ readOnly }}
                    value={form.inscricaoEstadual}
                    required={!readOnly && typePerson !== 'J'}
                    disabled={typePerson === 'J'}
                  />
                )}

                <TextField
                  label="Observação Pedido"
                  name="observacaoDePedido"
                  className={classes.field}
                  size="small"
                  variant="outlined"
                  onChange={handleChange}
                  inputProps={{
                    readOnly,
                    maxLength: 100,
                  }}
                  helperText={!readOnly && `${form.observacaoDePedido.length}/100`}
                  value={form.observacaoDePedido}
                />

                <TextField
                  label="Ordem de Compra"
                  name="ordemDeCompra"
                  className={classes.field}
                  size="small"
                  variant="outlined"
                  onChange={handleChange}
                  inputProps={{
                    readOnly,
                    maxLength: 50,
                  }}
                  helperText={!readOnly && `${form.ordemDeCompra.length}/50`}
                  value={form.ordemDeCompra}
                />
              </List>
            </div>

            <DialogActions>
              <Button onClick={handleToggle} type="button">
                Fechar
              </Button>

              {!readOnly && (
                <Button color="primary" type="submit">
                  Salvar
                </Button>
              )}
            </DialogActions>
          </form>
        </Box>
      </Dialog>
    </>
  );
};

AdditionalInfo.defaultProps = {
  readOnly: false,
  lockedOpen: false,
  onSubmit: () => { },
  initialValues: {
    observacaoDePedido: '',
    ordemDeCompra: '',
    classificacaoCliente: '',
    documentoNF: '',
    inscricaoEstadual: '',
  },
  hideAddInfo: () => {},
  showAddInfo: () => {},
};

AdditionalInfo.propTypes = {
  readOnly: PropTypes.bool,
  lockedOpen: PropTypes.bool,
  isPickup: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  typePerson: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    observacaoDePedido: PropTypes.string.isRequired,
    ordemDeCompra: PropTypes.string.isRequired,
    classificacaoCliente: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    documentoNF: PropTypes.string.isRequired,
    inscricaoEstadual: PropTypes.string,
  }),
  hideAddInfo: PropTypes.func,
  showAddInfo: PropTypes.func,
};
export default connect(() => {}, {
  hideAddInfo: hideAddInfoAction,
  showAddInfo: showAddInfoAction,
})(AdditionalInfo);
