import React, { useState } from 'react';

import {
  Box,
  Typography,
  Button
} from '@material-ui/core';

import {
  Check
} from '@material-ui/icons';

import {
  toCEP
} from '../../../utils/parsers';

import {
  FlatExpansionPanel
} from '../../flatExpansionPanel';

import AddInfoDialog from '../../../../main/checkout/_components/checkoutConfirmDialog/AddInfoDialog';

import PropTypes from 'prop-types';

const AddressInfo = ({
  street,
  number,
  city,
  state,
  zipcode,
  deliveryDate,
  readOnly,
  onChangeAddress,
  complement,
  addInfo,
  neighborhood,
  onUpdateAddInfo,
}) => {
  const [open, setOpen] = useState(false);
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);

  const handleToggle = () => setOpen(!open);
  const openInfoModal = () => setOpenModalInfo(true);
  const handleCloseInfoModal = () => setOpenModalInfo(false);

  const copyAddress = () => {
    const fullAddress = `${street}, ${number}, ${neighborhood} - ${city}/${state}- CEP ${zipcode} ${complement && `- Complemento ${complement}`}`;
    navigator.clipboard.writeText(fullAddress);
    setIsAddressCopied(true);
    setTimeout(() => { setIsAddressCopied(false); }, 1000);
  };

  return (
    <Box pl={2} pr={2}>
      <AddInfoDialog addInfo={addInfo} open={openModalInfo} onClose={handleCloseInfoModal} />

      <FlatExpansionPanel
        expanded
        onChange={handleToggle}
        style={{
          marginTop: '0px',
          backgroundColor: 'transparent',
          fontSize: '12px'
        }}
      >
        {!!street && (
          <>
            <Typography
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                color: '#1A1C26'
              }}
              > {`${street}, ${number}, ${neighborhood}` }</Typography>
          </>
        )}

        {!!zipcode && (
          <Box>
            {complement}
            {`${city}/${state} - CEP ${toCEP(zipcode)}`}
          </Box>
        )}

        {!!addInfo && (
          <Box>
            <Typography
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                color: '#1A1C26',
              }}
            >
              Informações Adicionais para Entrega:
            </Typography>
            <span style={{ lineBreak: 'anywhere' }}>
              {addInfo}
            </span>
          </Box>
        )}

        <Button
          variant="text"
          style={{
            textTransform: 'none',
            textDecoration: 'underline',
            fontWeight: 600,
            fontSize: 12,
            padding: 0,
            margin: 0
          }}
          onClick={openInfoModal}
          color="primary"
        >
          {!!addInfo ? 'Alterar' : 'Adicionar'} informações de Entrega
        </Button>

        {!!deliveryDate && (
          <>
            <br/>
            {deliveryDate}
          </>
        )}

        <Box display="flex" justifyContent="space-around" flexWrap="wrap">
          <Button
            color="primary"
            size="small"
            onClick={copyAddress}
            style={{
              backgroundColor: `${isAddressCopied ? '#FA4616' : 'transparent'}`,
              fontSize: '12px',
              fontWeight: '600',
              color: `${isAddressCopied ? 'white' : '#FA4616'}`,
              marginTop: '10px',
              border: '2px solid #FA4616',
              height: 30,
              borderRadius: '4px',
              padding: '0',
              width: '45%',
            }}
          >
            {isAddressCopied && <Check color="white" style={{ marginRight: '5px' }} />}
            {isAddressCopied ? 'Copiado' : 'Copiar'}
          </Button>

          {!readOnly && (

          <Button
            color="primary"
            size="small"
            onClick={onChangeAddress}
            style={{
              fontSize: '10px',
              fontWeight: 'bold',
              color: '#FA4616',
              border: '2px solid #FA4616',
              borderRadius: '4px',
              width: '45%',
              marginTop: '10px',
              marginBottom: '15px',
              padding: '0',
              height: 30,
            }}
          >
            Alterar
          </Button>

          )}
        </Box>
      </FlatExpansionPanel>
    </Box>
  );
};

AddressInfo.defaultProps = {
  number: '',
  zipcode: '',
  street: '',
  city: '',
  state: '',
  complement: '',
  addInfo: '',
  neighborhood: '',
  readOnly: true,
  onChangeAddress: () => {},
  onClose: PropTypes.func.isRequired,
  onUpdateAddInfo: PropTypes.func,
};

AddressInfo.propTypes = {
  zipcode: PropTypes.string,
  complement: PropTypes.string,
  addInfo: PropTypes.string,
  street: PropTypes.string,
  number: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  neighborhood: PropTypes.string,
  deliveryDate: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  onChangeAddress: PropTypes.func,
};

export default AddressInfo;
