export default {
  GET_ORDERS_LIST_REQUEST: '@orders/GET_ORDERS_LIST_REQUEST',
  GET_ORDERS_LIST_SUCCESS: '@orders/GET_ORDERS_LIST_SUCCESS',
  GET_ORDERS_LIST_FAILURE: '@orders/GET_ORDERS_LIST_FAILURE',

  GET_ORDERS_BY_ID_REQUEST: '@orders/GET_ORDERS_BY_ID_REQUEST',
  GET_ORDERS_BY_ID_SUCCESS: '@orders/GET_ORDERS_BY_ID_SUCCESS',
  GET_ORDERS_BY_ID_FAILURE: '@orders/GET_ORDERS_BY_ID_FAILURE',
  CLEAR_ORDERS_BY_ID: '@orders/CLEAR_ORDERS_BY_ID',

  SEND_EMAIL_REQUEST: '@orders/SEND_EMAIL_REQUEST',
  SEND_EMAIL_SUCCESS: '@orders/SEND_EMAIL_SUCCESS',
  SEND_EMAIL_FAILURE: '@orders/SEND_EMAIL_FAILURE',

  GET_NFE_REQUEST: '@orders/GET_NFE_REQUEST',
  GET_NFE_SUCCESS: '@orders/GET_NFE_SUCCESS',
  GET_NFE_FAILURE: '@orders/GET_NFE_FAILURE',

  NEW_CHECKOUT_REQUEST: '@orders/NEW_CHECKOUT_REQUEST',
  NEW_CHECKOUT_SUCCESS: '@orders/NEW_CHECKOUT_SUCCESS',
  NEW_CHECKOUT_FAILURE: '@orders/NEW_CHECKOUT_FAILURE',

  GET_DETAILED_DELIVERY_REQUEST: '@orders/GET_DETAILED_DELIVERY_REQUEST',
  GET_DETAILED_DELIVERY_SUCCESS: '@orders/GET_DETAILED_DELIVERY_SUCCESS',
  GET_DETAILED_DELIVERY_FAILURE: '@orders/GET_DETAILED_DELIVERY_FAILURE',
  CLEAR_DETAILED_DELIVERY: '@orders/CLEAR_DETAILED_DELIVERY',
};
