import { makeStyles, StepConnector, styled, withStyles } from '@material-ui/core';

const useStyles = makeStyles(({ theme }) => ({
  stepRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  root: {
    width: '100%',
    '& .MuiStepLabel-iconContainer': {
      height: 50,
      alignItems: 'center',
    },
    '& .MuiStepper-horizontal': {
      padding: '12px 0 24px 0'
    }
  },
  button: {
    marginRight: 12,
  },
  instructions: {
    marginTop: 12,
    marginBottom: 12,
  },
  labelStep: {
    fontSize: 12,
    fontWeight: 700,
    color: '#242424',
  },
  labelStepActive: {
    color: props => props.colorStep
  },
  labelStepDisabled: {
    color: '#CFCFD4!important'
  },
  labelStepDate: {
    fontSize: 10,
    fontWeight: 400,
    color: '#7B7F82'
  },
  buttonActions: {
    '&&': {
      marginTop: 8,
      fontSize: 12,
      fontWeight: 600,
      textTransform: 'none',
      borderRadius: 4,
      border: '1px solid',
      padding: '6px 4px',
    }
  },
  buttonActionsBlue: {
    width: '100%',
    borderColor:  props => props.colorStep,
    color:  props => props.colorStep
  }
}));

export const UseLineConnector = styled(StepConnector)(({ colorStep, stepIsDisabled }) => ({
  '&& .MuiStepConnector-line': {
    border: 'none'
  },
  top: 23,
  marginLeft: '-18px',
  height: 4,
  border: 0,
  backgroundColor: stepIsDisabled ? '#ccc' : colorStep,
  borderRadius: 1,
  width: '100%',

}));

export const useColorlibStepIconStyles = makeStyles({
  root: {
    height: 16,
    width: 16,
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    width: 40,
    height: 40,
    backgroundColor: props => props.colorStep
  },
  completed: {
    backgroundColor: props => props.colorStep
  },
});


export default useStyles;
