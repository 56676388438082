import React, { useEffect, useState } from 'react';

import {connect, useDispatch} from 'react-redux';
import * as PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from '@material-ui/core';

import CardPaper from '../../_components/cardPaper/CardPaper';
import { toCNPJ } from '../../../../shared/utils/parsers';

import TitleSelect from '../../_components/titleSelect/TitleSelect';
import CircularLoading from '../../../../shared/components/loading/CircularLoading';
import VouchersList from './_components/vouchersList/VouchersList';

import {
  getListFilialsAction,
  getVoucherAndCouponsAction
} from './actions';

import useStyles from './styles';
import { ExpandMore } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {getClient, setClient} from "../../../../shared/services/authClient";
import {Types} from "../../../authClient/login";

const CustomerInfoPage = ({
  client,
  filials,
  isLoading,
  vouchersAndCoupons,
  codClienteMatriz,
  getListFilials,
  getVoucherAndCoupons
}) => {
  const dispatch = useDispatch();
  const {
    cardGrid,
    accordionFilial,
    accordionFilialDetails,
    accordionFilialSummary,
    titleCompany
  } = useStyles();

  const [itemsSelect, setItemsSelect] = useState([]);
  const [filialsVoucher, setFilialsVoucher] = useState([]);

  const [expanded, setExpanded] = useState(false);
  const isCorporate = client && client.tipoPessoa === 'J';

  const handleClient = () => {
    const cookiesClient = getClient();
    if (client && cookiesClient) {
      if (client.codCliente !== cookiesClient.codCliente) {
        setClient(client);

        dispatch({
          type: Types.SET_CLIENT_MATRIX_ID,
          payload: {
            clientMatrixId: client.codClienteMatriz || client.codCliente,
          },
        });

        dispatch({
          type: Types.SET_CLIENT_SUCCESS,
          payload: { client },
        });
      }
    }
  };

  const handleChangeFilial = (event) => {
    handleClient();
    setFilialsVoucher([]);

    const { target: {value: value} } = event;

    if (value === 1) {
      getVoucherAndCoupons(client.codCliente, true);
    } else {
      getVoucherAndCoupons(value, false);
    }
  }

  const handleChange = (panel) => (event, isExpanded) => setExpanded(isExpanded ? panel : false);
  const getVoucherListByFilial = (codCliente) => vouchersAndCoupons.filter((voucher) => Number(voucher.codFilial) === codCliente);

  const handleFilialsByClient = () => {
    if (!vouchersAndCoupons?.length) return;

    const filialsFromVoucher = vouchersAndCoupons.map((filial) => Number(filial.codFilial));
    const filialsFromVoucherUnique = [...new Set(filialsFromVoucher)];

    filialsFromVoucherUnique.forEach((codCliente) => {
      const vouchers = getVoucherListByFilial(codCliente);
      if (!vouchers) return;

      const { cnpjFilial, codFilial, nomeFilial } = vouchers[0];

      setFilialsVoucher((state) => [
        ...state,
        {
          cnpjFilial,
          nomeFilial,
          codFilial,
          items: vouchers
        }
      ]);

      setExpanded(filialsFromVoucherUnique.length === 1 && codFilial)
    });
  }

  useEffect(() => {
    getVoucherAndCoupons(client.codCliente, true);
    getListFilials(codClienteMatriz || client.codCliente);
  }, []);

  useEffect(() => {
    if (filials && filials.length > 0) {
      setItemsSelect((state) => {
        return [
          { codCliente: 1, nome: 'Ver todos' },
          ...filials.map((filial) => ({
            codCliente: filial.codCliente,
            nome: filial.nome,
          })),
        ]
      })
    }
  }, [filials]);

  useEffect(() => {
    if (vouchersAndCoupons) {
      setFilialsVoucher([]);
      handleFilialsByClient();
    }
  }, [vouchersAndCoupons]);

  useEffect(() => {
    handleClient();
  }, []);

  return (
    <Grid className={cardGrid}>
      <CircularLoading
        open={isLoading}
        message="Carregando Vales"
      />

      <Box>
        <TitleSelect
          title="Vales"
          values={itemsSelect}
          model={{ id: 'codCliente', name: 'nome' }}
          onChange={handleChangeFilial}
          showSelect={isCorporate}
        />
          {filialsVoucher.length > 0 ? filialsVoucher.map((voucher) => {
            if (isCorporate) {
              return (
                <Accordion
                  expanded={expanded === voucher.codFilial}
                  onChange={handleChange(voucher.codFilial)}
                  key={voucher.codFilial}
                  className={accordionFilial}
                >
                  <AccordionSummary
                    className={accordionFilialSummary}
                    expandIcon={<ExpandMore />}
                  >
                    <Box display="flex" justifyContent="space-between" width="100%">
                      <Box>
                        <Typography className={titleCompany}>Empresa <span>{voucher.nomeFilial}</span> com {voucher.items.length} vales/cupons</Typography>
                        <Typography className={`${titleCompany} cnpj`}>CNPJ - {toCNPJ(voucher.cnpjFilial)}</Typography>
                      </Box>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails className={accordionFilialDetails}>
                    <Divider style={{marginBottom: 24}}/>
                    <VouchersList vouchers={voucher.items}/>
                  </AccordionDetails>
                </Accordion>
              )
            } else
              return (
                <CardPaper>
                  <VouchersList vouchers={voucher.items}/>
                </CardPaper>
              )
          }) : isLoading ? (
            <>
              <Skeleton variant="rect" height={80} />
            </>
          ) : (
              <CardPaper>
                <Box display="flex" justifyContent="center" alignItems="center" height="80px">
                  <Typography fontSize={12} fontWeight="bold" color="primary">Nenhum vale encontrado</Typography>
                </Box>
            </CardPaper>
          )}
      </Box>
    </Grid>
  );
};

CustomerInfoPage.defaultProps = {
  onCloseModal: () => {},
  customerInfo: {
    codCliente: '',
    cpf: '',
    email: '',
    obsCliente: '',
    nomeComprador: '',
    nome: '',
    telefone: '',
    telefoneSecundario: '',
    tipoPessoa: '',
  },
};

CustomerInfoPage.propTypes = {
  customerInfo: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

const mapState = (state) => {
  let { client, codClienteMatriz } = state.authClient;
  const { filials, isLoading, vouchersAndCoupons } = state.main.vouchersAndCoupons;

  return {
    client,
    filials,
    isLoading,
    vouchersAndCoupons,
    codClienteMatriz
  };
};

export default connect(mapState, {
  getVoucherAndCoupons: getVoucherAndCouponsAction,
  getListFilials: getListFilialsAction,
})(CustomerInfoPage);

