import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {
  Box,
  DialogContent,
  Tooltip as TooltipMUI,
} from '@material-ui/core';
// eslint-disable-next-line import/no-cycle
import { connect } from 'react-redux';
import {
  AddButton,
  Autocomplete,
  Breadcrumb,
  CategoryLevels,
  ContentText,
  StockList,
  Tooltip,
} from '../index';
import useStyles, {
  DialogStyled as Dialog,
  DialogTitleStyled as DialogTitle,
  DialogActionsStyled as DialogActions,
} from './styles';
import DrawerIntelligence from '../../drawerIntelligence/DrawerIntelligence';
import getYoutubeId from '../../../utils/getYoutubeId';

const DetailsDialog = ({
  open,
  setOpen,
  product,
  data,
  addProduct,
  qtyProductRequested,
  setQtyProductRequested,
  priceEstablishment,
  pplInfo,
  loading,
  isFisicStore,
  productAvailable,
  outsideSearch,
  pplActiveCheckout,
}) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const [medias, setMedias] = useState([]);
  const [urlMediaSelected, setUrlMediaSelected] = useState(null);
  const { REACT_APP_URL_IMAGES } = process.env;
  const mainUrlImages = REACT_APP_URL_IMAGES || '';
  const classes = useStyles();
  const {
    available,
    description,
    title,
    images,
    model,
    pma,
    priceEstablishments,
    stockEstablishments,
    erpId,
    productId,
    videos,
  } = product;

  const {
    descriptionProductGrid,
    header,
    closeIcon,
    detailsAndCategoriesBox,
    dialogActionsBox,
    image,
    imageAnchor,
    imageGrid,
    imageMain,
    imageStockAndFreightBox,
    pplIcon,
    addProductGrid,
    btnProductErpId,
    boxAi,
    productErpId,
    copyIcon,
    popper,
    tooltip,
  } = classes;

  const {
    stockText,
    filialText,
    localFilial,
    qtyLocalFilial,
  } = data;

  const close = () => {
    setOpen(false);
    setUrlMediaSelected(medias[0]);
  };

  const handleImage = (img) => {
    setUrlMediaSelected(img);
  };

  const handleTooltip = () => setOpenTooltip(!openTooltip);

  const copyToClipboard = (idProduct) => {
    navigator.clipboard.writeText(idProduct).catch((err) => console.log(err.message));
    handleTooltip();
  };

  useEffect(() => {
    if (openTooltip) {
      setTimeout(() => {
        handleTooltip();
      }, 1000);
    }
  }, [openTooltip]);

  useEffect(() => {
    const mediaArray = [];

    if (images) {
      images.map((img) => mediaArray.push({ url: img, isImg: true }));
    }

    if (videos) {
      videos.map((video) => mediaArray.push({ url: video, isImg: false }));
    }

    setMedias(mediaArray);
  }, [product]);

  useEffect(() => {
    setUrlMediaSelected(medias[0]);
  }, [medias]);

  return (
    <Dialog
      open={open}
      onClose={close}
      scroll="paper"
    >
      <Grid className={header}>
        {!!pplInfo && !outsideSearch && (<span className={pplIcon} />)}
        {pplActiveCheckout && (<span className={pplIcon} />)}
        <DialogTitle>
          {`${title} ${model || ''}`}
          <TooltipMUI
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltip}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            classes={{ popper, tooltip }}
            title="Código copiado"
            placement="left"
          >
            <button type="button" className={btnProductErpId} onClick={() => copyToClipboard(erpId)}>
              <div className={productErpId}>
                {`COD BSELLER: ${erpId}`}
              </div>
              <div className={copyIcon} />
            </button>
          </TooltipMUI>
        </DialogTitle>
        <button type="button" aria-label="close details dialog" className={closeIcon} onClick={close} />
      </Grid>
      <DialogContent dividers>
        <Grid className={imageStockAndFreightBox}>
          <Box className={imageStockAndFreightBox}>
            <Grid
              className={imageGrid}
            >
              {
                medias.map(
                  (media) => (
                    <>
                      <button
                        type="button"
                        aria-label="image option"
                        key={`${media.url}`}
                        onClick={() => handleImage(media)}
                        className={imageAnchor}
                      >
                        {
                          media.isImg ? (
                            <img
                              alt="imagem produto"
                              className={image}
                              src={`${mainUrlImages}${media.url}`}
                            />
                          ) : (
                            <img
                              alt="imagem produto"
                              className={image}
                              src={`http://img.youtube.com/vi/${getYoutubeId(media.url)}/sddefault.jpg`}
                            />
                          )
                        }
                      </button>
                    </>
                  ),
                )
              }
            </Grid>
            {
              !!urlMediaSelected && (
                urlMediaSelected.isImg ? (
                  <img alt={title} className={imageMain} src={`${mainUrlImages}${urlMediaSelected.url}`} />
                ) : (
                  <iframe
                    height={402}
                    width={500}
                    src={`https://www.youtube.com/embed/${getYoutubeId(urlMediaSelected.url)}?controls=0`}
                    title={title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  />
                )
              )
            }
          </Box>
          <Box>
            <div id="stockTotal">
              <StockList
                priceEstablishments={priceEstablishments}
                stockEstablishments={stockEstablishments}
              />
            </div>
          </Box>
        </Grid>
        <Grid className={descriptionProductGrid}>
          <h3 id="productDetails">Descrição do produto:</h3>
          <Box>
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </Box>
        </Grid>
      </DialogContent>
      <Box className={boxAi}>
        <DrawerIntelligence productId={productId} className={outsideSearch ? 'noFooter' : ''} />
      </Box>
      {
        !outsideSearch && (
          <DialogActions>
            <Box className={detailsAndCategoriesBox}>
              <Breadcrumb />
              <CategoryLevels
                categoryLevels={product.categoryHierarchy}
                setOpen={setOpen}
              />
            </Box>
            <Box className={dialogActionsBox}>
              <ContentText
                priceProduct
                isFisicStore={isFisicStore}
                data={{
                  ...priceEstablishment,
                  pma,
                  available,
                  ppl: pplInfo,
                }}
                className="contentDetailsDialog"
                notDiscount
                isDetails
              />
              <Grid className={`${addProductGrid} ${(!productAvailable || loading) && 'disabled'}`}>
                <Autocomplete
                  disabled={!productAvailable}
                  value={qtyProductRequested}
                  setValue={setQtyProductRequested}
                  onClickDisabled={(!productAvailable || loading)}
                />
                {productAvailable && !loading ? (
                  <>
                    <Tooltip
                      textSecondary={`${stockText} ${filialText} filial`}
                    >
                      <span>
                        <AddButton
                          localFilial={localFilial}
                          qty={qtyLocalFilial}
                          onClick={(!productAvailable || loading || !Number(qtyProductRequested))
                            ? () => {} : addProduct}
                          disabled={!productAvailable || loading || !Number(qtyProductRequested)}
                        />
                      </span>
                    </Tooltip>
                  </>
                ) : (
                  <span>
                    <AddButton
                      localFilial={localFilial}
                      qty={qtyLocalFilial}
                      disabled={!productAvailable || loading || !Number(qtyProductRequested)}
                    />
                  </span>
                )}
              </Grid>
            </Box>
          </DialogActions>
        )
      }
    </Dialog>
  );
};

DetailsDialog.defaultProps = {
  pplInfo: {},
  isFisicStore: false,
  productAvailable: false,
  outsideSearch: false,
  pplActiveCheckout: false,
  priceEstablishment: [],
  data: {},
  addProduct: () => {},
  setQtyProductRequested: () => {},
  qtyProductRequested: 0,
};

DetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  product: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Object),
  addProduct: PropTypes.func,
  qtyProductRequested: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setQtyProductRequested: PropTypes.func,
  priceEstablishment: PropTypes.instanceOf(Object),
  pplInfo: PropTypes.instanceOf(Object),
  isFisicStore: PropTypes.bool,
  outsideSearch: PropTypes.bool,
  productAvailable: PropTypes.bool,
  pplActiveCheckout: PropTypes.bool,
};

const mapState = (state) => ({
  loading: state.main.checkout.geral.loading,
});

export default connect(mapState)(DetailsDialog);
