import React from 'react';
import {
  useRefinementList,
  useSortBy,
} from 'react-instantsearch';
import PropTypes from 'prop-types';
import { Box, Button, IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { ListRounded } from '@material-ui/icons';
import useStyles from './styles';
import { handleCheckboxRefinements, handleSortBy } from '../../../utils/algolia';
// eslint-disable-next-line import/no-cycle
import { SortBy, CheckboxRefinement, CloseSearchProducts } from '../index';
import { CheckboxRefinementCustom } from '../filters';
import { searchByCategoryAction } from '../../../../main/checkout/actions';

const DrawerToFilters = ({
  user,
  customer,
  handleSameFilialCheckbox,
  handleListCard,
  handleProducts,
  handleOpenMenuFilters,
  keepPin,
}) => {
  const { openMenuFilters, setOpenMenuFilters } = handleOpenMenuFilters;
  const sortByOptions = handleSortBy();
  const checkboxList = handleCheckboxRefinements(user, customer);
  const { listCard, setListCard } = handleListCard;
  const classes = useStyles();
  const {
    boxMain,
    brandsBox,
    brandBox,
    filtersBox,
    filterBoxMain,
    filterGrid,
    sortAndCloseBtns,
    checkboxListBox,
    optionBtn,
    filtersBtn,
  } = classes;

  useRefinementList({ attribute: 'stockEstablishmentsAvailable' });
  const {
    items: brands,
    refine,
  } = useRefinementList({
    attribute: 'brand.name',
    limit: 7,
    sortBy: ['isRefined:desc'],
  });
  useSortBy({ items: sortByOptions });

  const handleListCardClick = (list) => {
    handleProducts(list);
    setListCard(list);
  };

  const handleBrandRefinement = (brandValue) => {
    refine(brandValue);
  };

  return (
    <Grid className={filterGrid}>
      <Box className={`${filterBoxMain} ${!openMenuFilters && 'hidden'}`}>
        <Box className={filtersBox}>
          <Box className={checkboxListBox}>
            <Box className={boxMain}>
              <Box className={brandsBox}>
                <span>Principais Marcas:</span>
                {
                  brands.map((brand) => (
                    <Button
                      className={`${brandBox} ${brand.isRefined && 'selected'}`}
                      onClick={() => handleBrandRefinement(brand.value)}
                    >
                      <span>{brand.label}</span>
                      <span>{`(${brand.count})`}</span>
                    </Button>
                  ))
                }
              </Box>
              <Box className={checkboxListBox}>
                {
                  !keepPin && (
                    <Button
                      className={filtersBtn}
                      onClick={() => setOpenMenuFilters(!openMenuFilters)}
                    />
                  )
                }
                {
                  checkboxList.map((checkbox, index) => {
                    if (checkbox.custom) {
                      return (
                        <CheckboxRefinementCustom
                          /* eslint-disable-next-line react/no-array-index-key */
                          key={`${checkbox.attribute}-${index}`}
                          checkbox={checkbox}
                          handleSameFilialCheckbox={handleSameFilialCheckbox}
                        />
                      );
                    }
                    return (
                      <CheckboxRefinement
                        key={checkbox.attribute}
                        checkbox={checkbox}
                      />
                    );
                  })
                }
              </Box>
            </Box>
          </Box>
          <Box>
            <CloseSearchProducts />
          </Box>
        </Box>
        <Box className={sortAndCloseBtns}>
          <SortBy />
          <IconButton
            onClick={() => handleListCardClick(!listCard)}
            classes={{ root: `${optionBtn} ${listCard && 'selected'}` }}
          >
            <ListRounded />
          </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};

DrawerToFilters.defaultProps = {
  handleSameFilialCheckbox: null,
  keepPin: false,
};

DrawerToFilters.propTypes = {
  customer: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  handleSameFilialCheckbox: PropTypes.instanceOf(Object),
  handleListCard: PropTypes.instanceOf(Object).isRequired,
  handleOpenMenuFilters: PropTypes.instanceOf(Object).isRequired,
  handleProducts: PropTypes.func.isRequired,
  keepPin: PropTypes.bool,
};

const mapState = (state) => {
  const {
    categoryHierarchy,
  } = state.main.checkout.geral;

  return ({
    categoryList: categoryHierarchy,
  });
};

export default connect(mapState, {
  searchByCategory: searchByCategoryAction,
})(DrawerToFilters);
