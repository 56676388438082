import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import {
  useInfiniteHits, useInstantSearch, useStats, useToggleRefinement,
} from 'react-instantsearch';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-cycle
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import useStyles from './styles';
// eslint-disable-next-line import/no-cycle
import { DrawerToFilters } from '../index';
import { resetSearchAction } from '../../../../main/checkout/actions';
import { getFilialVendedor, getUnidadeDeNegocio } from '../../../services/app';
import businessUnitTypes from '../../../utils/businessUnitTypes';
import LoadingDark from '../loadingDark/LoadingDark';
import Product from '../../../../main/checkout/_components/algolia/product';

const Hits = ({
  user,
  customer,
  hasFilters,
  hasHits,
  resetSearch,
  isCheckout,
  handleSameFilialCheckbox,
  setIndexFilialUser,
  handleOpenMenuFilters,
  keepPin,
}, props) => {
  const { openMenuFilters } = handleOpenMenuFilters;
  const [listCard, setListCard] = useState(false);
  const [businessUnit] = useState(getUnidadeDeNegocio || 2);
  const isFisicStore = Number(businessUnit) === businessUnitTypes.LOJAS_FISICAS;
  const [filialUserLinkedInRegister, setFilialUserLinkedInRegister] = useState(0);
  const [userFilialId, setUserFilialId] = useState(user.filiais[0].idEstabelecimento);
  const [component, setComponent] = useState([]);
  const { query } = useStats();
  const { setIndexUiState } = useInstantSearch();

  const classes = useStyles();
  const {
    hitsBox,
    drawerToFilterDiv,
    loadingBox,
  } = classes;

  const {
    hits, isLastPage, showMore,
  } = useInfiniteHits(props);
  const sentinelRef = useRef(null);
  const { value: { isRefined } } = useToggleRefinement({ attribute: 'ppl.pdv' });
  const showPpl = !isFisicStore ? true : isRefined;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      }, { threshold: 1 });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (hits.length) {
      const handleEsc = (e) => {
        if (e.key === 'Escape') resetSearch(true);
      };
      window.addEventListener('keydown', handleEsc);

      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    }
  }, [hits.length]);

  useEffect(() => {
    setUserFilialId(user.filiais[0].idEstabelecimento);
  }, [user.filiais[0].idEstabelecimento]);

  useEffect(() => {
    if (Number(businessUnit) === businessUnitTypes.LOJAS_FISICAS) {
      const res = Number(getFilialVendedor() || 2);
      setFilialUserLinkedInRegister(res);
    }
  }, []);

  useEffect(() => {
    setIndexUiState((previousIndexUiState) => ({
      ...previousIndexUiState,
      page: 0,
    }));
  }, [query]);

  const handleProducts = (listShow) => {
    if (hasHits) {
      const productComponent = [];
      hits.map((hit) => {
        if (!isRefined) {
          const selectedComponent = (
            <Product
              className="list-card ais-InfiniteHits-item"
              key={hit.objectID}
              product={hit}
              userFilialId={userFilialId}
              filialUserLinked={filialUserLinkedInRegister}
              setIndexFilialUser={setIndexFilialUser}
              isCheckout={isCheckout}
              isFisicStore={isFisicStore}
              listCard={listShow}
            />
          );

          productComponent.push(selectedComponent);
        }

        if (hit.isPpl && hit.ppl && showPpl) {
          return hit.ppl.map((ppl, index) => {
            if (ppl.pdv) {
              const selectedComponent = (
                <Product
                  className="list-card ais-InfiniteHits-item"
                  key={`${hit.objectID}--${index}`}
                  product={hit}
                  pplInfo={ppl}
                  userFilialId={userFilialId}
                  filialUserLinked={filialUserLinkedInRegister}
                  setIndexFilialUser={setIndexFilialUser}
                  isCheckout={isCheckout}
                  isFisicStore={isFisicStore}
                  listCard={listShow}
                />
              );

              productComponent.push(selectedComponent);
            }
          });
        }
      });

      setComponent(productComponent);
    }
  };

  useEffect(() => {
    handleProducts(listCard);
  }, [hasHits && hits]);

  return (
    <Box
      onClick={(e) => e.stopPropagation()}
    >
      {/* <SubheaderFilters /> */}
      {(hasFilters && !!hits.length) && (
        <div className={drawerToFilterDiv}>
          <DrawerToFilters
            customer={customer}
            user={user}
            handleSameFilialCheckbox={handleSameFilialCheckbox}
            handleListCard={{ listCard, setListCard }}
            handleProducts={handleProducts}
            handleOpenMenuFilters={handleOpenMenuFilters}
            keepPin={keepPin}
          />
        </div>
      )}

      {
        !component.length && (
          <Box className={loadingBox}>
            <LoadingDark />
          </Box>
        )
      }

      <div className="ais-InfiniteHits">
        <ul
          className={`ais-InfiniteHits-list ${hitsBox} ${listCard && 'list-card'} ${!hasHits && 'd-none'} ${!openMenuFilters && 'hidden'}`}
        >
          {!!component.length && component}
          <li
            className="ais-InfiniteHits-sentinel"
            ref={sentinelRef}
            aria-hidden="true"
          />
        </ul>
      </div>
    </Box>
  );
};

Hits.defaultProps = {
  hasFilters: false,
  hasHits: false,
  isCheckout: false,
  keepPin: false,
};

Hits.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
  resetSearch: PropTypes.func.isRequired,
  hasFilters: PropTypes.bool,
  hasHits: PropTypes.bool,
  isCheckout: PropTypes.bool,
  openMenuFilters: PropTypes.instanceOf(Object).isRequired,
  handleSameFilialCheckbox: PropTypes.instanceOf(Object).isRequired,
  setIndexFilialUser: PropTypes.func.isRequired,
  handleOpenMenuFilters: PropTypes.instanceOf(Object).isRequired,
  keepPin: PropTypes.bool,
};

export default connect(() => {}, {
  resetSearch: resetSearchAction,
})(Hits);
