/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { getFilialVendedor, getUnidadeDeNegocio} from '../../../shared/services/app';
import {
  AppBar,
  Toolbar,
  Divider,
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  DialogTitle,
  Radio,
  RadioGroup, FormControlLabel,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Close, FlashOn, RemoveCircleOutline,
  AddCircleOutline,
} from '@material-ui/icons';
import { toMoney } from '../../../shared/utils/parsers';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getCheckoutAction, showItemSearchAction } from '../../checkout/actions';
import apiService from '../../../shared/services/apiService';
import StyledButton from '../../../shared/components/styledButton/StyledButton';
import ItemDialogSearch from '../../checkout/_components/itemDialogSearch';
import logo from '../../../assets/logo.svg';
import garantiaLogo from '../../../assets/garantia_logo2.png';
import UserAvatar from '../userAvatar';
import useStyles from './styles';

const MainAppBarSearch = ({
  drawer, user, checkout, snack, getCheckout, showItemSearch,
}) => {
  const classes = useStyles();
  const [arrayProduct, setArrayProduct] = useState([]);
  const [detailProduct, setDetailProduct] = useState([]);
  const [detailButtonProduct, setDetailButtonProduct] = useState([]);
  const [tensao, setTensao] = useState([]);
  const [garantia, setGarantia] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAutoComplete, setOpenAutoComplete] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [corButton, setCorButton] = useState('');
  const [idTensao, setIdTensao] = useState(null);
  const [estoque, setEstoque] = useState(null);
  const [reloadPage, setRealoadPage] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [search, setSearch] = useState('');
  const [qtd, setQtd] = useState(1);
  const [validationList, setValidationList] = useState(false);
  const [validationFooter, setValidationFooter] = useState(false);
  const [buttonHeightList, setButtonHeightList] = useState(false);
  const [loadingTensao, setLoadingTensao] = useState(false);
  const [valueRadioButton, setValueRadioButton] = useState(0);

  useEffect(() => {
    setRealoadPage(false);
  }, [reloadPage]);

  setTimeout(() => {
    if (loading) {
      setLoading(false);
    }
  }, 2000);

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'));

  useEffect(() => {
    document.querySelector('#idAuto').addEventListener('keypress', async (e) => {
      if (e.key === 'Enter') {
        setOpenAutoComplete(true);
        setButtonHeightList(false);
        const getProduct = async () => {
          setLoading(true);
          const array = await axios.get(`https://api.linximpulse.com/engage/search/v3/search?apiKey=lojadomecanico&secretKey=b3MhyScKr076h36bodLRAA==&allowRedirect=true&showOnlyAvailable=false&resultsPerPage=100&sortBy=relevance&terms=${e.target.value}`);
          if (array.status === 200) {
            setArrayProduct(array.data.products);
            setValidationFooter(false);
            setValidationList(true);
          }
        };
        getProduct();
        setValidationList(false);
        setValidationList(true);
      }
    });
  }, []);

  const handleCloseList = async () => {
    document.getElementById('simple-menu').style.visibility = 'hidden';
    setOpenAutoComplete(false);
    setValidationList(true);
    setValidationFooter(false);
  };
  const postProduct = async (idSkus) => {
    try {
      let filial = getFilialVendedor();
      const data = await apiService.post(`/carrinho/adicionar-produto`, {
        checkoutId: checkout.checkoutId,
        quantity: qtd,
        idFilial: filial,
        idSku: idTensao === null ? +idSkus.id : idTensao,
        warrantyId: valueRadioButton ? valueRadioButton : null,
        ppl: null,
        isCompreJunto: false,
        isCatalogo: false,
        isPPL: (idTensao === null) ? (idSkus.ppl ? true : false) : false,
        userId: checkout?.idSeller,
        cep: checkout.customer?.address?.zipCode,
        customerId: checkout.customer?.customerId,
      }).then((response) => response.data.results.response);
      if (data) {
        snack.enqueueSnackbar('Produto inserido com sucesso', { variant: 'success' });
        getCheckout({ idCheckout: checkout.checkoutId, startRequest: true, calculateTax: false });
        setOpenModal(false);
        setCorButton('');
        setIdTensao(null);
        setOpenAutoComplete(false);
        document.getElementById('simple-menu').style.visibility = 'hidden';
        setQtd(1);
        setTimeout(async () => {
          setArrayProduct([]);
        }, 1000);
      }
    } catch (e) {
      snack.enqueueSnackbar(e, { variant: 'error' });
    }
  };
  const handleCloseModal = async () => {
    setOpenModal(false);
  };

  const handleClose = async () => {
    await handleCloseModal();
    setOpenAutoComplete(true);
    setValidationList(true);
    setValidationFooter(false);
  };

  const handleFocusList = () => {
    if (arrayProduct.length > 0) {
      setOpenAutoComplete(true);
      setValidationList(true);
      setValidationFooter(false);
    }
  };

  const getDetailProductList = async (idSkus) => {
    setLoadingDetails(true);
    const data = await apiService.get(`/produtos/${idSkus}/?qtd=1`).then((response) => response.data.results);
    if (data === null) {
      snack.enqueueSnackbar(`problema com o produto ${idSkus}`, { variant: 'error' });
    }
    setDetailButtonProduct(data);
    if (data) {
      setTimeout(async () => {
        await showItemSearch({ item: data });
        handleCloseList();
        setLoadingDetails(false);
      }, 2000);
    }
  };

  const getDetail = async (idSkus) => {
    const data = await apiService.get(`/produtos/${idSkus}/?qtd=1`).then((response) => response.data.results);
    if (data === null) {
      snack.enqueueSnackbar(`problema com o produto ${idSkus}`, { variant: 'error' });
    }
    setDetailProduct(data);

  };

  const getDetailProduct = async (idSkus, volts) => {
    try {
      const data = await apiService.get(`/produtos/${idSkus}/?qtd=1`).then((response) => response.data.results);
      if (data === null) {
        snack.enqueueSnackbar(`problema com o produto ${idSkus}`, { variant: 'error' });
      }
      if (data.tensao.length === 0) {
        await postProduct(data);
      } else {
        if (volts === '220 Volts') {
          setCorButton(1);
          setIdTensao(data.id);
        } else if (volts === '110 Volts') {
          setCorButton(0);
          setIdTensao(data.id);
        }
        setTensao(data.tensao);
        setDetailProduct(data);
        setGarantia(data.garantias);
        setEstoque(data.estoque);
        setOpenModal(true);
        setLoadingTensao(false);
        handleCloseList();
      }
    } catch (e) {
      snack.enqueueSnackbar(e.message || e, { variant: 'error' });
    }
  };

  const handleButtonTensao = (e, id, index) => {
    setCorButton(index);
    setIdTensao(id);
    getDetailProduct(id);
    setLoadingTensao(true);
  };

  const handleMais = (id, getProduto) => {
    const quantidade = +document.getElementById(id).textContent;
    if (qtd < detailProduct.estoque) {
      setQtd(quantidade + 1);
    }
    if (getProduto) {
      getDetail(id);
      setQtd(2);
    }
  };
  const handleMenos = (id) => {
    const quantidade = +document.getElementById(id).textContent;
    if (quantidade > 1) {
      setQtd(quantidade - 1);
    }
  };

  function insertAfter(referenceNode, newNode) {
    if (newNode !== undefined && referenceNode !== undefined) {
      referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }
  }

  const handleGarantia = (garantiaId) => {
    setValueRadioButton(garantiaId);
  };

  const handleDetailsButton = async (id) => {
    await getDetailProductList(id);
  };

  if (validationList) {
    const x = document.getElementsByClassName('MuiAutocomplete-listbox')[0];
    if (x !== undefined) {
      x.style.overflow = 'hidden';
      x.style.maxHeight = '350px';
      document.getElementById('simple-menu').style.backgroundColor = 'rgb(0, 0, 0, 0.5)';
      document.getElementById('simple-menu').style.zIndex = '1000';
      document.getElementById('simple-menu').style.visibility = 'inherit';
      document.querySelector('#simple-menu').addEventListener('click', async () => handleCloseList());
    }
    if (buttonHeightList && (document.getElementsByClassName('MuiAutocomplete-listbox')[0] !== undefined)) {
      document.getElementsByClassName('MuiAutocomplete-listbox')[0].style.maxHeight = '400px';
      document.getElementsByClassName('MuiAutocomplete-listbox')[0].style.overflow = 'auto';
      document.getElementsByClassName('MuiAutocomplete-listbox')[0].style.transition = 'all 0.7s ease-in-out';
    }
    if (!validationFooter) {
      setValidationFooter(true);
      if (document.getElementById('idback') !== null) {
        document.getElementById('idback').parentNode.removeChild(document.getElementById('idback'));
      }
      const footerList = document.createElement('div');
      footerList.style.height = '50px';
      footerList.id = 'idback';
      footerList.style.borderTopColor = '#E3E3E7';
      footerList.style.borderTopStyle = 'solid';
      footerList.style.borderTopWidth = '1px';
      footerList.style.backgroundColor = '#FFF';
      const element = (
        <div
          id="footerList"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 10,
          }}
        >
          <span style={{ fontSize: 12 }}>
            {arrayProduct.length}
            {' '}
            produtos encontrados
          </span>
          <button
            type="button"
            onClick={() => { arrayProduct.length > 3 ? setButtonHeightList(true) : {}; }}
            style={{
              height: 28,
              width: 87,
              backgroundColor: '#FFF',
              color: '#FB6B45',
              border: '1px #FB6B45 solid',
              borderRadius: 4,
              cursor: 'pointer',
              fontSize: 12,
            }}
          >
            VER MAIS

          </button>
        </div>
      );
      ReactDOM.render(element, footerList);
      insertAfter(x, footerList);
    }
  }

  return (
    <>
      <AppBar
        className={classes.appBar}
        color="default"
        position="fixed"
      >
        <Toolbar disableGutters className={drawer ? classes.toolBarOrange : classes.toolBarWhite}>
          <Button
            className={classes.button}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            tabIndex="-1"
            onClick={() => window.location.reload()}
          >
            <Box position="relative">
            {!isMobile ? (<img src={logo} alt="Loja do Mecanico" style={{ marginLeft: 85, height: 23 }} />) : (<></>)}
              <Typography variant="caption" className={classes.version}>
                {process.env.REACT_APP_VERSION}
              </Typography>
            </Box>
          </Button>

          <Box className={classes.searchInput}>
            <Autocomplete
              id="idAuto"
              options={openAutoComplete ? arrayProduct : []}
              onClose={() => arrayProduct.length === 0 && setOpenAutoComplete(false)}
              open={openAutoComplete}
              loading={false}
              onBlur={() => null}
              onOpen={() => setOpenAutoComplete(true)}
              className={classes.autoComplete}
              disableCloseOnSelect
              filterOptions={(options) => options}
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => (option.name !== undefined ? option.name : '')}
              renderOption={({ images: { '50x50': urlImage }, ...option }) => (
                <>
                  <Box
                    key={option.id}
                    className={classes.boxSelect}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div className={classes.divImageDescription}>
                    {!isMobile ? (<img alt="" src={urlImage} style={{ width: 60, height: 70 }} />) : (<img alt="" src={urlImage} style={{ width: 30, height: 35 }} />)}

                      <div style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        paddingLeft: 10,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      >
                        <div style={{ display: 'flex', gap: 5 }}>
                          <StyledButton
                            className={classes.productId}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDetailsButton(option.id);
                            }}
                            variant="outlined"
                            color="primary"
                            disableElevation
                          >
                            {loadingDetails && +option.id === detailButtonProduct.id
                              ? <CircularProgress size={10} />
                              : `Produto ${option.id}`}
                          </StyledButton>
                          {option.specs.volts !== undefined
                              && (
                                <div className={classes.voltsId}>
                                  <FlashOn style={{ fontSize: 11, color: '#fff', marginRight: 2 }} />
                                  <span>
                                    {' '}
                                    {option.specs.volts[0].label}
                                  </span>
                                </div>

                              )}
                        </div>
                        <span>
                          {option.name}
                        </span>
                        <span style={{ fontSize: 11, color: '#888E9D' }}>
                          {`Bseller ${option.skus[0].properties.details.id_item_erp} - ${option.skus[0].properties.details.brand}`}
                        </span>
                      </div>
                    </div>

                    {+option.id === detailProduct.id
                      ? (
                        <div className={classes.divQuantity}>
                          <span style={{ fontSize: 14, color: '#BDC1CA' }}>Qde</span>
                          <div style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingTop: 10,
                          }}
                          >
                            <RemoveCircleOutline
                              onClick={() => {
                                handleMenos(+option.id);
                              }}
                              className={classes.iconQtd}
                            />

                            <span style={{marginRight:5, marginLeft:5}} id={option.id}>{qtd}</span>

                            <AddCircleOutline
                              onClick={() => handleMais(+option.id)}
                              className={classes.iconQtd}
                            />
                          </div>
                        </div>
                      )
                      : (
                        <div className={classes.divQuantity}>
                          <span style={{ fontSize: 14, color: '#BDC1CA' }}>Qde</span>
                          <div style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingTop: 10,
                          }}
                          >
                            <RemoveCircleOutline
                              onClick={() => {
                                handleMenos(+option.id);
                              }}
                              className={classes.iconQtd}
                            />

                            <span id={option.id}>1</span>
                            {option.status === 'UNAVAILABLE' || (option.details.sellers !== undefined && (option.details?.sellers[0]?.sellerName !== 'Loja do Mecânico' && option.details?.sellers[0]?.sellerName !== ''))
                              ? (
                                <AddCircleOutline
                                  style={{ color: '#c4c4c4' }}
                                  className={classes.iconQtd}
                                />
                              )
                              : (
                                <AddCircleOutline
                                  onClick={() => {
                                    handleMais(+option.id, true);
                                  }}
                                  className={classes.iconQtd}
                                />
                              )}

                          </div>
                        </div>
                      )}

                    {+option.id === detailProduct.id
                      ? (
                        <div className={classes.divValue}>
                          {getUnidadeDeNegocio() != 2 && (
                            <>
                              <span style={{ fontWeight: 600 }}>
                                {`R$ ${((option.installment.count * option.installment.price) * qtd).toFixed(2)} à prazo`}
                              </span>
                              <span>{`R$ ${(qtd * option.price).toFixed(2)} à vista`}</span>
                            </>
                          )}
                          <div className={classes.divButton}>
                                <Button
                                  disabled={option.status === 'UNAVAILABLE' || (option.details.sellers !== undefined && (option.details?.sellers[0]?.sellerName !== 'Loja do Mecânico' && option.details?.sellers[0]?.sellerName !== ''))}
                                  className={classes.buttonAdicionar}
                                  onClick={() => {
                                    getDetailProduct(option.id);
                                  }}
                                >
                                  {option.status === 'UNAVAILABLE' ? (<span style={{ fontSize: 12, color: '#fff' }}>Sem Estoque</span>) : option.details.sellers !== undefined && (option.details?.sellers[0]?.sellerName !== 'Loja do Mecânico' && option.details?.sellers[0]?.sellerName !== '')  ? 'MKTPLACE' : 'ADICIONAR'}
                                </Button>
                          </div>

                        </div>
                      )
                      : (
                        <div className={classes.divValue}>
                          {getUnidadeDeNegocio() != 2 && (
                            <>
                              <span style={{ fontWeight: 600 }}>
                                {`R$ ${(option.installment.count * option.installment.price).toFixed(2)} à prazo`}
                              </span>
                              <span>{`R$ ${(option.price).toFixed(2)} à vista`}</span>
                            </>
                          )}

                                <div className={classes.divButton}>
                                  {option.specs.volts !== undefined
                                    ? (
                                      <Button
                                        disabled={option.status === 'UNAVAILABLE' || (option.details.sellers !== undefined && (option.details?.sellers[0]?.sellerName !== 'Loja do Mecânico' && option.details?.sellers[0]?.sellerName !== ''))}
                                        className={classes.buttonAdicionar}
                                        onClick={() => {
                                          getDetailProduct(option.id,
                                            option.specs.volts[0].label);
                                        }}
                                      >
                                        {option.status === 'UNAVAILABLE' ? (<span style={{ fontSize: 12, color: '#fff' }}>Sem Estoque</span>) : option.details.sellers !== undefined && (option.details?.sellers[0]?.sellerName !== 'Loja do Mecânico' && option.details?.sellers[0]?.sellerName !== '')  ? 'MKTPLACE' : 'ADICIONAR'}
                                      </Button>
                                    )
                                    : (
                                      <Button
                                        disabled={option.status === 'UNAVAILABLE' || (option.details.sellers !== undefined && (option.details?.sellers[0]?.sellerName !== 'Loja do Mecânico' && option.details?.sellers[0]?.sellerName !== ''))}
                                        className={classes.buttonAdicionar}
                                        onClick={() => {
                                          getDetailProduct(option.id);
                                        }}
                                      >
                                        {option.status === 'UNAVAILABLE' ? (<span style={{ fontSize: 12, color: '#fff' }}>Sem Estoque</span>) : option.details.sellers !== undefined && (option.details?.sellers[0]?.sellerName !== 'Loja do Mecânico' && option.details?.sellers[0]?.sellerName !== '')  ? 'MKTPLACE' : 'ADICIONAR'}
                                      </Button>
                                    )}
                                </div>

                        </div>
                      )}


                  </Box>
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="O que você procura..."
                  size="small"
                  value={search}
                  onFocus={() => handleFocusList()}
                  onChange={(e) => setSearch(e.target.value)}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress style={{ color: '#FA4616' }} size={20} /> : <SearchIcon style={{ color: '#FA4616' }} />}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box className="right-container">
            <Box>
              <Typography align="right" variant="subtitle2" style={{ fontWeight: 600, textOverflow: 'hidden', whiteSpace: 'nowrap' }}>
                Olá,
                {' '}
                {user.nomeVendedor}
              </Typography>
              {user.emailVendedor !== null
                ? (
                  <Typography variant="caption">
                    {user.emailVendedor}
                  </Typography>
                )
                : (
                  <Typography variant="caption">
                    vendedor@gurgelmix.com.br
                  </Typography>
                )}
            </Box>
            <UserAvatar />
          </Box>
        </Toolbar>
        {!isMobile ? (<Divider />) : (<></>)}
      </AppBar>
      <Dialog onClose={handleClose} open={openModal} className={classes.dialogTensao} maxWidth="sm">
        <DialogTitle position="relative">
          <Box position="absolute" top={4} right={4}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <div>
            <p style={{ textAlign: 'center', marginBottom: 40 }}>Você está selecionando um produto com a opção abaixo</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {tensao.map((item, index) => (
              <div
                style={corButton === index
                  ? {
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 4,
                    width: 156,
                    height: 150,
                    border: '1px #FA4616 solid',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    boxShadow: '0 0 10px rgba(250, 70, 22, 0.25)',
                    paddingTop: 16,
                    paddingBottom: 8,
                    backgroundColor: '#F8F9FA',
                  }
                  : {
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px #CFCFD4 solid',
                    borderRadius: 4,
                    width: 156,
                    height: 150,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: 16,
                    paddingBottom: 8,
                    cursor: 'pointer',
                  }}
                onClick={(e) => handleButtonTensao(e, item.id, index)}
              >
                <div
                  key={item.tensao}
                  style={corButton === index ? {
                    color: '#FA4616',
                    display: 'flex',
                    alignItems: 'center',
                  } : {
                    color: '#7B7F82',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FlashOn style={{ transform: 'rotate(20deg)', fontSize: 22 }} />
                  <span style={{ fontSize: 16, textTransform: 'uppercase' }}>{item.tensao}</span>
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 50,
                }}
                >
                  {corButton === index
                && (
                  <>
                    {loadingTensao
                      ? <CircularProgress size={24} />
                      : (
                        <>
                          {getUnidadeDeNegocio() != 2 && (
                              <>
                            <span className={classes.valueSelect}>
                              {`R$ ${(detailProduct.qtdeParcelas * detailProduct.valorParcela).toFixed(2)} à prazo`}
                            </span>
                            <span className={classes.valueSelectAvista}>
                              {`R$ ${detailProduct.precoVenda} à vista`}


                            </span>
                            </>
                          )}
                        </>
                      )}

                  </>
                )}
                </div>
                <Radio
                  checked={corButton === index}
                  onChange={(e) => handleButtonTensao(e, item.id, index)}
                  value={index}
                  color="primary"
                  inputProps={{ 'aria-label': 'D' }}
                />
              </div>
            ))}

          </div>

          {garantia.length > 0 && (
          <Box p={2} display="flex"  style={{marginLeft: '16px', marginTop: '23px'}}>
            <Box>
              <img src={garantiaLogo} alt="garantiaLogo"   style={{maxWidth: '80px', float: 'left', marginRight: '30px', position: 'relative', top: '6px'}} />
              <RadioGroup>
                <FormControlLabel style={{fontSize: '12px'}}  className={`${valueRadioButton===0 ? "colorSelectOp" : ""}`}
                    value="0"
                    checked={valueRadioButton===0}
                    onChange={(e) => handleGarantia(0)}
                    control={<Radio className={classes.radioRecusa} />}
                    label="Garantia de fábrica"
                  />
                {garantia.map((item) => (
                  <FormControlLabel style={{fontSize: '12px'}}   className={`${valueRadioButton===item.idGarantia ? "colorSelectOp" : ""}`}
                    value={item.idGarantia}
                    checked={valueRadioButton===item.idGarantia}
                    onChange={(e) => handleGarantia(item.idGarantia)}
                    control={<Radio className={classes.radioRecusa} />}
                    label={"+"+item.descricaoGarantia.replace("GARANTIA", "").replace("MESES", "meses")+" - "+toMoney(item.valorGarantia)}
                  />
                ))}
              </RadioGroup>
            </Box>
            <style dangerouslySetInnerHTML={{__html: `
              .MuiFormControlLabel-label {
                font-size: 12px!important;
              }
              .PrivateSwitchBase-root-132 {
                padding: 4px!important;
              }
              .MuiFormControlLabel-root {
                margin-left: -9px!important;
                margin-right: 0px!important;
              }
              .MuiRadio-colorSecondary.Mui-checked {
                color: #F94024!important;
              }
              .makeStyles-productBox-93 span {
                color: inherit!important;
              }
              .colorSelectOp {
                color: #F94024!important;
              }
            `}}>
              </style>
          </Box>
          )}

        </DialogContent>
        <DialogActions style={{
          display: 'flex',
          justifyContent: 'center',
          padding: 20,
        }}
        >
          <Button
            className={classes.buttonAdd}
            disabled={estoque === 0 || corButton === ''}
            onClick={() => postProduct()}
          >
            ADICIONAR AO CARRINHO
          </Button>
        </DialogActions>
      </Dialog>
      <ItemDialogSearch
        setOpenAutoComplete={setOpenAutoComplete}
        setValidationList={setValidationList}
        setValidationFooter={setValidationFooter}
      />
    </>
  );
};

MainAppBarSearch.defaultProps = {
  drawer: true,
  snack: null,
};

MainAppBarSearch.propTypes = {
  drawer: PropTypes.bool,
  user: PropTypes.instanceOf(Object).isRequired,
  checkout: PropTypes.instanceOf(Object).isRequired,
  snack: PropTypes.instanceOf(Object),
  getCheckout: PropTypes.func.isRequired,
  showItemSearch: PropTypes.func.isRequired,
};
const mapState = (state) => {
  const { snack } = state.main.app;
  const { checkout } = state.main.checkout.geral;
  return ({
    ...state.main.app,
    user: state.auth.login.user,
    checkout,
    snack,
  });
};
export default connect(mapState, {
  getCheckout: getCheckoutAction,
  showItemSearch: showItemSearchAction,
})(MainAppBarSearch);
