import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useStats } from 'react-instantsearch';
import { EmptyCart } from './_components';
import { push } from '../../history';
import useStyles from './styles';
import { getClient, setClient } from '../../shared/services/authClient';
import { Types } from '../authClient/login';

const HomePage = ({
  client,
}) => {
  const { query } = useStats();
  const dispatch = useDispatch();
  const { section } = useStyles();

  if (!client) {
    push('/client-login');
  } else if (!client.confirmAddress) {
    push('/client-login');
  }

  useEffect(() => {
    if (query) {
      const handleClient = () => {
        const cookiesClient = getClient();
        if (client && cookiesClient) {
          if (query && (client.codCliente !== cookiesClient.codCliente)) {
            setClient(client);

            dispatch({
              type: Types.SET_CLIENT_MATRIX_ID,
              payload: {
                clientMatrixId: client.codClienteMatriz || client.codCliente,
              },
            });

            dispatch({
              type: Types.SET_CLIENT_SUCCESS,
              payload: { client },
            });
          }
        }
      };
      handleClient();
    }
  }, [query]);

  return (
    <section className={section}>
      {!!client && !client.qtyProducts && (
        <EmptyCart />
      )}
    </section>
  );
};

HomePage.propTypes = {
  client: PropTypes.instanceOf(Object),
};

HomePage.defaultProps = {
  client: null,
};

const mapStateToProps = (state) => {
  let { client } = state.authClient;

  client = getClient() || client;

  return ({
    ...state.main.home,
    client,
  });
};

export default connect(mapStateToProps)(HomePage);
