import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import {
  change,
  Form,
  formValueSelector,
  hasSubmitSucceeded,
  reduxForm,
  submit,
} from 'redux-form';
import { HighlightOffRounded } from '@material-ui/icons';
import { connect, useDispatch } from 'react-redux';
import Divider from '../../../../../authClient/_components/divider/Divider';
import { ButtonActions } from '../../../../../authClient/_components';
import { InputFieldForm } from '../../../../../authClient/_components/forms';
import {
  alphabetic, minLength3, cpfValid, required, cellphone,
} from '../../../../../../shared/fields/validate';
import { InputCpfRender, InputPhoneRender, RadioGroupRender } from '../../../../../../shared/fields';
import { AlertMessage } from '../../../../../authClient/register/_components';

import useStyles from './styles';
import { updateClientDataAction } from '../../../../actions';
import CircularLoading from '../../../../../../shared/components/loading/CircularLoading';
import { getClient, setClient } from '../../../../../../shared/services/authClient';
import { Types } from '../../../../../authClient/login';

const ModalInfo = ({
  open,
  onClose,
  client,
  handleSubmit,
  produtorRural,
  updateClientData,
  info,
  snack,
  submitSucceeded,
  clientSelector,
  customerInfo,
}) => {
  const {
    dialogActions,
    dialogContent,
    dialogPaper,
    dialogTitleContent,
    dialogTitleTypography,
    divider,
    alertMessage,
    inputGrid,
  } = useStyles();
  const isCorporate = customerInfo.tipoPessoa === 'J';
  const [disabledCpf, setDisabledCpf] = useState(false);

  const dispatch = useDispatch();

  const customValidate = (form) => {
    const nomeComprador = form.nomeComprador || customerInfo.nomeComprador;
    if (isCorporate && !nomeComprador) return false;

    if (!isCorporate && form.nome === form.nomeSocial) return false;

    return true;
  };

  const handleClient = async () => {
    const cookiesClient = await getClient();
    if (client && cookiesClient) {
      if (client.codCliente !== cookiesClient.codCliente) {
        await setClient(client);

        dispatch({
          type: Types.SET_CLIENT_MATRIX_ID,
          payload: {
            clientMatrixId: client.codClienteMatriz || client.codCliente,
          },
        });

        dispatch({
          type: Types.SET_CLIENT_SUCCESS,
          payload: { client },
        });
      }
    }
  };

  const handleRemoteSubmit = () => {
    dispatch(submit('update/client'));
  };

  const handleRequest = async (form) => {
    await handleClient();
    await updateClientData(form, customerInfo.tipoPessoa);
  };

  useEffect(() => {
    dispatch(change('update/client', 'codCliente', customerInfo.codCliente));
    dispatch(change('update/client', 'nome', customerInfo.nome));
    dispatch(change('update/client', 'nomeSocial', customerInfo.nomeSocial));
    dispatch(change('update/client', 'telefone', customerInfo.telefone));
    dispatch(change('update/client', 'telefoneSecundario', customerInfo.telefoneSecundario));
    dispatch(change('update/client', 'obsCliente', customerInfo.obsCliente));
    dispatch(change('update/client', 'ie', customerInfo.ie));
    dispatch(change('update/client', 'produtorRural', customerInfo.produtorRural));
    dispatch(change('update/client', 'cpf', customerInfo.cpf));

    if (isCorporate) {
      dispatch(change('update/client', 'cpfComprador', customerInfo.cpfComprador));
      dispatch(change('update/client', 'nomeComprador', customerInfo.nomeComprador));
    }

    setDisabledCpf(!!customerInfo.cpf);
  }, [open, customerInfo]);

  const handleChangeProdutorRural = (_, value) => dispatch(change('update/client', 'produtorRural', Boolean(value)));

  useEffect(() => {
    if (submitSucceeded) {
      if (customValidate(clientSelector)) {
        snack.enqueueSnackbar('Dados atualizados com sucesso!', { variant: 'success' });
        onClose(true);
      }
    }
  }, [submitSucceeded]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      classes={{ paper: dialogPaper }}
    >
      <CircularLoading open={info.loading.updateClient} message="Atualizando dados" />

      <DialogTitle>
        <Box className={dialogTitleContent}>
          <Typography className={dialogTitleTypography}>Alterar dados cadastrais</Typography>
          <IconButton size="small" onClick={onClose}><HighlightOffRounded /></IconButton>
        </Box>
      </DialogTitle>

      <Divider classes={divider} />

      <DialogContent classes={{ root: dialogContent }}>
        <Form onSubmit={handleSubmit(handleRequest)}>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <InputFieldForm
                name={isCorporate ? 'nomeComprador' : 'nome'}
                label={isCorporate ? 'Nome Comprador' : 'Nome Completo'}
                maxLength={100}
                validate={[required, minLength3]}
                required
              />
            </Grid>
            {!isCorporate && (
              <>
                <Grid item sm={12} xs={12}>
                  <InputFieldForm
                    name="produtorRural"
                    onChange={handleChangeProdutorRural}
                    value={produtorRural}
                    normalize={(v) => (v === 'true')}
                    component={RadioGroupRender}
                    label="Cliente é produtor rural?"
                    options={[
                      { value: true, label: 'Sim' },
                      { value: false, label: 'Não' },
                    ]}
                  />
                  {produtorRural && (
                    <Box marginTop={2}>
                      <InputFieldForm
                        name="ie"
                        type="number"
                        label="Inscrição Estadual"
                        maxLength={50}
                        validate={[required]}
                        required
                      />
                    </Box>
                  )}
                </Grid>
                <Grid item sm={12} xs={12}>
                  <InputFieldForm
                    name="nomeSocial"
                    label="Nome Social"
                    maxLength={50}
                    validate={[alphabetic, minLength3]}
                  />
                </Grid>
              </>
            )}

            <Grid item sm={6} xs={12} className={inputGrid}>
              <InputFieldForm
                name="telefone"
                label="Telefone Principal"
                component={InputPhoneRender}
                validate={[required, cellphone]}
                required
              />
            </Grid>

            <Grid item sm={6} xs={12} className={inputGrid}>
              <InputFieldForm
                name="telefoneSecundario"
                label="Telefone Secundário"
                component={InputPhoneRender}
                validate={[cellphone]}
              />
            </Grid>

            {isCorporate && (
              <>
                <Grid item sm={12} xs={12}>
                  <InputFieldForm
                    name="cpfComprador"
                    component={InputCpfRender}
                    placeholder="ex. 100.100.100-10"
                    label="CPF do comprador"
                    validate={[cpfValid]}
                  />
                </Grid>
              </>
            )}

            {!isCorporate && (
              <>
                <Grid item sm={12} xs={12}>
                  <InputFieldForm
                    name="cpf"
                    component={InputCpfRender}
                    placeholder="ex. 100.100.100-10"
                    label="CPF"
                    required
                    disabled={disabledCpf}
                    validate={[required, cpfValid]}
                  />
                </Grid>
                <AlertMessage className={alertMessage}>
                  <bold>ATENÇÃO:</bold>
                  Após confirmado o vínculo do CPF, este campo não poderá ser alterado/editado.
                </AlertMessage>
              </>
            )}

            <Grid item sm={12} xs={12}>
              <InputFieldForm
                name="obsCliente"
                label="Informações Adicionais"
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions classes={{ root: dialogActions }}>
        <ButtonActions
          onClick={handleRemoteSubmit}
          color="primary"
          variant="contained"
        >
          Atualizar Dados
        </ButtonActions>
      </DialogActions>
    </Dialog>
  );
};

const mapState = (state) => {
  const { snack } = state.main.app;
  const selector = formValueSelector('update/client');
  const produtorRural = selector(state, 'produtorRural');
  const clientSelector = selector(state, 'nomeSocial', 'nome', 'nomeComprador', 'telefoneSecundario');
  const info = state.main.clientInfo;
  const submitSucceeded = hasSubmitSucceeded('update/client')(state);
  const client = getClient() || state.authClient.client;

  return {
    snack,
    produtorRural,
    info,
    submitSucceeded,
    clientSelector,
    client,
  };
};

ModalInfo.defaultProps = {
  customerInfo: {},
  handleSubmit: () => {},
  produtorRural: false,
};

ModalInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  client: PropTypes.instanceOf(Object).isRequired,
  customerInfo: PropTypes.instanceOf(Object),
  produtorRural: PropTypes.bool,
  clientSelector: PropTypes.instanceOf(Object).isRequired,
};

export default connect(mapState, {
  updateClientData: updateClientDataAction,
})(reduxForm({
  form: 'update/client',
  initialValues: {
    codCliente: '',
    nomeSocial: '',
    nomeComprador: '',
    telefone: '',
    telefoneSecundario: '',
    obsCliente: '',
    produtorRural: false,
    ie: '',
    cpf: '',
    cpfComprador: '',
  },
})(ModalInfo));
