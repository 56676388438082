/* eslint-disable max-len */
import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import {
  Box, Tooltip, useMediaQuery, useTheme, Button,
} from '@material-ui/core';
import { useHotkeys } from 'react-hotkeys-hook';
import { Refresh, CreditCard, Link, AttachMoney } from '@material-ui/icons';
import CardBar from '../../../../shared/components/cardBar';
import { getUnidadeDeNegocio} from '../../../../shared/services/app';
import loadImg from '../../../../assets/load.gif';
import {
  setPaymentMethodAction,
  removePaymentAction,
  generateLinkAction,
  openDialogCreditCardQrCodeAction,
  closeResendLinkAction,
  openDialogManualPaymentAction,
  closeDialogCreditCardQrCodeAction,
  closeDialogManualPaymentAction,
  getCreditRequestAction, getStatusInfoCreditRequestAction,
} from './actions';
import PaymentType from './paymentType';
import Advanced from './advanced/Advanced';
import PaymentRadio from './PaymentRadio';
import { getCheckoutAction } from '../../actions';
import { getCheckPixAction } from './advanced/actions';
import Cashier from './Cashier';
import CreditCardQrCode from './CreditCardQrCode';
import ManualPayment from './ManualPayment';
import { checkDiscountOfferAction } from '../../actions';
import CreditRequestModal from './creditRequest/CreditRequestModal';
import types from './types';
import useStyle from './styles';

const Payment = ({
  paymentMethod, setPaymentMethod, payments, drawer, removePayment, checkoutId, loading, dialogManualPaymentOpened,
  openDialogCreditCardQrCode, openDialogManualPayment, closeDialogCreditCardQrCode, dialogCreditCardQrCodeOpened, closeDialogManualPayment,
  checkout, getCheckout, disabled, chosedFreight, deniedPayments, getCheckPix, checkDiscountOffer, closeResendLink,
  creditRequestResume, getCreditRequest, getStatusInfoCreditRequest
}) => {
  const mappedPayments = useMemo(
    () => payments.filter((payment) => !['CC'].includes(payment.type)),
    [payments],
  );

  const dispatch = useDispatch();
  const classes = useStyle();
  const {
    buttonActionPayment,
    cardBarTitle
  } = classes;

  const [showLoadImg, setShowLoadImg] = useState(false);
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'));

  const openDialogQrCode  = async () => {
    let percentPermission = 10.2;
    if (getUnidadeDeNegocio() > 1) {
      percentPermission = 0;
    }
    setShowLoadImg(true);
    await refreshPayments();
    let produtoComDesconto = false;
    if (checkout?.itemsFilials !== undefined) {
      for (let i = 0; i < checkout.itemsFilials.length; i++) {
        if (checkout.itemsFilials[i].items !== undefined) {
          for (let r = 0; r < checkout.itemsFilials[i].items.length; r++) {
            let poncent = (checkout.itemsFilials[i].items[r].discount*100)/(checkout.itemsFilials[i].items[r].totalOnTime);
            if (poncent > percentPermission) {
              produtoComDesconto = true;
            }
          }
        }
      }
    }

      if(checkout.resume.remainder > 0) {
        if (checkout.discountCart <= percentPermission && !produtoComDesconto) {
          closeResendLink();
          openDialogCreditCardQrCode();
        } else {
          if (await checkDiscountOffer()) {
            closeResendLink();
            openDialogCreditCardQrCode();
          }
        }
      }

      setShowLoadImg(false);

  };


  const openManualPayment  = async () => {
    let percentPermission = 10.2;
    if (getUnidadeDeNegocio() > 1) {
      percentPermission = 0;
    }
    setShowLoadImg(true);
    await refreshPayments();
    let produtoComDesconto = false;
    if (checkout?.itemsFilials !== undefined) {
      for (let i = 0; i < checkout.itemsFilials.length; i++) {
        if (checkout.itemsFilials[i].items !== undefined) {
          for (let r = 0; r < checkout.itemsFilials[i].items.length; r++) {
            let poncent = (checkout.itemsFilials[i].items[r].discount*100)/(checkout.itemsFilials[i].items[r].totalOnTime);
            if (poncent > percentPermission) {
              produtoComDesconto = true;
            }
          }
        }
      }
    }

      if(checkout.resume.remainder > 0) {
        if (checkout.discountCart <= percentPermission && !produtoComDesconto) {
          openDialogManualPayment();
        } else {
          if (await checkDiscountOffer()) {
            openDialogManualPayment();
          }
        }
      }

      setShowLoadImg(false);

  };

  const closeDialogQrCode = () => closeDialogCreditCardQrCode();
  const closeManualPayment = () => closeDialogManualPayment();

  const { customerId } = checkout.customer;

  const checkoutIdPayment = useMemo(
    () => payments.filter((payment) => !['PIX'].includes(payment.type))
  )

  const refreshPayments = async () => {

      for (var i = 0; i < checkoutIdPayment?.length; i++) {
        await getCheckPix(checkoutId, customerId, checkoutIdPayment[i]?.paymentId);
      }
      await getCheckout({
        idCheckout: checkoutId,
        isSameDay: checkout.checkedSameday,
        isPickup: checkout.checkedPickup,
        entregaNitro: checkout.checkedNitrous,
        calculateTax: checkout.resume.calculateTax,
      });

  };

  useHotkeys('shift+s', refreshPayments, [refreshPayments]);

  const handleCreditRequestResume = () => getCreditRequest(checkoutId);
  const handleCreditRequestResumeClose = () => {
    dispatch({
      type: types.CREDIT_REQUEST_RESUME_SUCCESS,
      payload: { creditRequestResume: null, loadingCreditRequest: false },
    });
  }

  const isCorporate = checkout?.customer?.typePerson === "J";

  useEffect(() => {
    getStatusInfoCreditRequest(checkoutId);
  }, [checkout.checkoutId]);

  return (
    <>
      <CardBar
        title="Formas de Pagamento"
        leading={<Box className={cardBarTitle}/>}
      >
        <CreditRequestModal creditRequestResume={creditRequestResume} onClose={handleCreditRequestResumeClose}/>

        <div style={ isMobile ? {position: 'absolute', right: 0, paddingTop: 19, zIndex: 1} : {}}>
          {showLoadImg && (
            <span style={{marginRight: 17}}>
              <div style={{textAlign: 'center', float: 'left'}}>
                <img src={loadImg} alt="carregando..."   style={{textAlign: 'center',  marginRight: 5, maxWidth: 23, marginTop: 5}} />
              </div>
              {!isMobile && (<span style={{fontSize:10, color: '#000'}}>Aguarde...</span>)}
            </span>
          )}

          {isCorporate && (
            <Tooltip
              title='Painel de Solicitação de crédito'
            >
              <Button
                className={buttonActionPayment}
                color="primary"
                variant="outlined"
                onClick={handleCreditRequestResume}
                disabled={!chosedFreight || loading || !!checkout.orderId || (checkout.resume.remainder <= 0 || checkout.resume.remainderInstallment <= 0)}
              >
                <AttachMoney style={!isMobile ? {marginRight: 5, width: '0.6em'} : {}} /> {!isMobile && (<>Solicitação de Venda</>)}
              </Button>
            </Tooltip>
          )}

          <Tooltip
            title={chosedFreight
              ? 'Registrar TEF'
              : 'Selecione uma forma de entrega'}
          >
            <Button
              className={buttonActionPayment}
              color="primary"
              variant="outlined"
              onClick={openManualPayment}
              disabled={!chosedFreight || loading || !!checkout.orderId || (checkout.resume.remainder <= 0 || checkout.resume.remainderInstallment <= 0)}
            >
              <CreditCard style={!isMobile ? {marginRight: 5, width: '0.6em'} : {}} /> {!isMobile && (<>Registrar TEF</>)}
            </Button>
          </Tooltip>

          <Tooltip
            title={chosedFreight
              ? ((!checkout?.customer?.name || !checkout?.customer?.phone || !checkout?.customer?.email) ? 'Faltam informações no cadastro do cliente' : 'Link Remoto')
              : 'Selecione uma forma de entrega'}
          >
            <Button
              className={buttonActionPayment}
              color="primary"
              variant="outlined"
              onClick={openDialogQrCode}
              disabled={!chosedFreight || loading || !!checkout.orderId || (checkout.resume.remainder <= 0 || checkout.resume.remainderInstallment <= 0)}
            >
              <Link  style={!isMobile ? {marginRight: 5, width: '0.6em'} : {}} />{!isMobile && (<>Link Remoto</>)}
            </Button>
          </Tooltip>

          <Tooltip title="Recarregar pagamento: shift + S">
            <Button
              className={buttonActionPayment}
              color="primary"
              variant="outlined"
              onClick={refreshPayments}
            >
              <Refresh style={!isMobile ? {marginRight: 5, width: '0.6em'} : {}} />{!isMobile && (<>Atualizar Pagamento</>)}
            </Button>
          </Tooltip>
        </div>
      </CardBar>

      {mappedPayments.length || paymentMethod ? (
        <Box color="#666">
        </Box>
      ) : (
        <PaymentRadio
          disabled={disabled || loading || !chosedFreight || !!checkout.orderId}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          hasPayments={!!mappedPayments.length}
          drawer={drawer}
          discountCart={checkout.discountCart}
          checkout={checkout}
          chosedFreight={chosedFreight}
          hasMarketPlace={checkout.resume.hasMarketPlace}
          hasDeniedPayments={!!deniedPayments.length}
        />
      )}

      {PaymentType.AVANÇADO === paymentMethod && <Advanced checkout={checkout} customerId={checkout.customer.customerId} />}
      {PaymentType.CAIXA === paymentMethod && <Cashier checkout={checkout} />}

      <CreditCardQrCode opened={dialogCreditCardQrCodeOpened} close={closeDialogQrCode} />
      <ManualPayment opened={dialogManualPaymentOpened} close={closeManualPayment} />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    checkout,
    loading,
    invalidDialog,
    chosedFreight,
  } = state.main.checkout.geral;

  const {
    paymentMethod,
    loading: loadingPayment,
    dialogCreditCardQrCodeOpened,
    dialogManualPaymentOpened,
    dialogRemoteLinkOpened,
    creditRequestResume,
  } = state.main.checkout.payment;

  const { payments = [], deniedPayments = [] } = checkout;

  return {
    drawer: state.main.app.drawer,
    checkoutId: checkout.checkoutId,
    dialogCreditCardQrCodeOpened,
    dialogManualPaymentOpened,
    dialogRemoteLinkOpened,
    checkout,
    chosedFreight,
    loading: loading || loadingPayment,
    paymentMethod,
    payments,
    checkPix: state.main.checkout.advanced.checkPix,
    deniedPayments,
    disabled: !!invalidDialog,
    initialState: {
      members: [],
    },
    creditRequestResume
  };
};

Payment.defaultProps = {
  paymentMethod: null,
};

Payment.propTypes = {
  paymentMethod: PropTypes.string,
  setPaymentMethod: PropTypes.func.isRequired,
  removePayment: PropTypes.func.isRequired,
  drawer: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  chosedFreight: PropTypes.bool.isRequired,
  payments: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  deniedPayments: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  checkoutId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  openDialogCreditCardQrCode: PropTypes.func.isRequired,
  closeResendLink: PropTypes.func.isRequired,
  openDialogManualPayment: PropTypes.func.isRequired,
  closeDialogCreditCardQrCode: PropTypes.func.isRequired,
  closeDialogManualPayment: PropTypes.func.isRequired,
  dialogCreditCardQrCodeOpened: PropTypes.bool.isRequired,
  dialogManualPaymentOpened: PropTypes.bool.isRequired,
  getCheckout: PropTypes.func.isRequired,
  checkDiscountOffer: PropTypes.func.isRequired,
  checkout: PropTypes.instanceOf(Object).isRequired,
  getCheckPix: PropTypes.func.isRequired,
  getStatusInfoCreditRequest:PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  setPaymentMethod: setPaymentMethodAction,
  removePayment: removePaymentAction,
  generateLink: generateLinkAction,
  getCheckout: getCheckoutAction,
  openDialogCreditCardQrCode: openDialogCreditCardQrCodeAction,
  closeResendLink: closeResendLinkAction,
  openDialogManualPayment: openDialogManualPaymentAction,
  closeDialogCreditCardQrCode: closeDialogCreditCardQrCodeAction,
  closeDialogManualPayment: closeDialogManualPaymentAction,
  checkDiscountOffer: checkDiscountOfferAction,
  getCreditRequest: getCreditRequestAction,
  getCheckPix: getCheckPixAction,
  getStatusInfoCreditRequest: getStatusInfoCreditRequestAction,
})(Payment);
