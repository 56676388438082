import React, { useState } from 'react';

import {
  Box,
  Grid,
} from '@material-ui/core';

import * as PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import Divider from '../../../authClient/_components/divider/Divider';
import CardPaper from '../../_components/cardPaper/CardPaper';
import CardInfo from '../../_components/cardInfo/CardInfo';
import TitleButton from '../../_components/titleButton/TitleButton';
import ModalInfo from './_components/modalInfo/ModalInfo';

import useStyles from './styles';
import { toPhone, toCPF } from '../../../../shared/utils/parsers';
import { getClient, setClient } from '../../../../shared/services/authClient';
import { Types } from '../../../authClient/login';

const CustomerInfoPage = ({
  customerInfo,
  onCloseModal,
  client,
}) => {
  const dispatch = useDispatch();
  const {
    cardGrid,
    dividerContent,
  } = useStyles();

  const [modal, setModal] = useState(false);

  const handleClient = async () => {
    const cookiesClient = await getClient();
    if (client && cookiesClient) {
      if (client.codCliente !== cookiesClient.codCliente) {
        await setClient(client);

        dispatch({
          type: Types.SET_CLIENT_MATRIX_ID,
          payload: {
            clientMatrixId: client.codClienteMatriz || client.codCliente,
          },
        });

        dispatch({
          type: Types.SET_CLIENT_SUCCESS,
          payload: { client },
        });
      }
    }
  };

  const handleModalEdit = () => {
    handleClient();
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
    onCloseModal();
    handleClient();
  };

  const isCorporate = customerInfo.tipoPessoa === 'J';

  const getCPF = () => (isCorporate ? (customerInfo.cpfComprador || '') : customerInfo.cpf);

  return (
    <Grid className={cardGrid}>
      <ModalInfo open={modal} onClose={handleCloseModal} customerInfo={customerInfo} />
      <Box>
        <TitleButton
          title="Dados Cadastrais"
          button="Editar"
          widthBtn={120}
          onClick={handleModalEdit}
        />
        <CardPaper>
          {isCorporate ? (
            <>
              <CardInfo label="Nome comprador" value={customerInfo.nomeComprador} />
              <Divider classes={dividerContent} />
            </>
          ) : (
            <>
              <CardInfo
                label="Nome"
                value={customerInfo.nome}
                info={customerInfo.produtorRural && {
                  text: 'Produtor Rural',
                  tooltip: 'O cliente é produtor rural',
                  icon: 'produtor',
                }}
              />
              <Divider classes={dividerContent} />
            </>
          )}

          {!isCorporate && (
            <>
              <CardInfo label="Nome Social" value={customerInfo.nomeSocial} />
              <Divider classes={dividerContent} />
            </>
          )}

          <CardInfo label="Telefone" value={toPhone(customerInfo.telefone)} />
          <Divider classes={dividerContent} />

          <CardInfo label="Telefone Secundário" value={toPhone(customerInfo.telefoneSecundario)} />
          <Divider classes={dividerContent} />

          <CardInfo label="CPF" value={getCPF() ? toCPF(getCPF()) : 'Este cliente é comprador? Vincule o CPF'} />
          <Divider classes={dividerContent} />

          <CardInfo label="Informações Adicionais" value={customerInfo.obsCliente} />
        </CardPaper>
      </Box>

      <Box>
        <TitleButton title="Segurança" />
        <CardPaper>
          <CardInfo label="E-mail" value={customerInfo.email} />
        </CardPaper>
      </Box>
    </Grid>
  );
};

CustomerInfoPage.defaultProps = {
  onCloseModal: () => {},
  customerInfo: {
    codCliente: '',
    cpf: '',
    email: '',
    obsCliente: '',
    nomeComprador: '',
    nome: '',
    telefone: '',
    telefoneSecundario: '',
    tipoPessoa: '',
  },
};

CustomerInfoPage.propTypes = {
  customerInfo: PropTypes.object.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
};

export default CustomerInfoPage;
