import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, colors } from '@material-ui/core';
import useStyles from './styles';

const ButtonActions = ({
  children,
  color,
  fullWidth,
  ...props
}) => {
  const {
    buttonActions,
  } = useStyles();

  return (
    <Button
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      disableElevation
      classes={{ root: buttonActions }}
      color={color}
      fullWidth={fullWidth}
    >
      {children}
    </Button>
  );
};

ButtonActions.defaultProps = {
  color: colors.primary,
  fullWidth: true,
};

ButtonActions.propTypes = {
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default ButtonActions;
