import React, { useRef, useState } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Typography from '@material-ui/core/Typography';
import { Fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toMoney } from '../../../../../../shared/utils/parsers';
import useStyles from './styles';

const OrderProducts = ({ products }) => {
  const classes = useStyles();

  const {
    root,
    slider,
    sliderWrapper,
    navButton,
    productTitle,
    productPrice,
    productGuarantee,
    productGuaranteeImage,
  } = classes;

  const sliderRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const [isHovered, setIsHovered] = useState(false);

  const handleScrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= 110;
    }
  };

  const handleScrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += 110;
    }
  };

  const onMouseDown = (e) => {
    const sliderMouseDown = sliderRef.current;
    setIsDragging(true);
    sliderMouseDown.style.cursor = 'grabbing';

    setStartX(e.pageX - sliderMouseDown.offsetLeft);
    setScrollLeft(sliderMouseDown.scrollLeft);
  };

  const onMouseLeave = () => {
    if (!isDragging) return;
    setIsDragging(false);
    if (sliderRef.current) {
      sliderRef.current.style.cursor = 'grab';
    }
  };

  const onMouseUp = () => {
    setIsDragging(false);
    if (sliderRef.current) {
      sliderRef.current.style.cursor = 'grab';
    }
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();

    const sliderMouseMove = sliderRef.current;
    const x = e.pageX - sliderMouseMove.offsetLeft;
    const walk = x - startX;
    sliderMouseMove.scrollLeft = scrollLeft - walk;
  };

  return (
    <div
      className={root}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={sliderWrapper}>
        <Fade in={isHovered}>
          <IconButton
            className={navButton}
            onClick={handleScrollLeft}
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Fade>

        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className={slider}
          ref={sliderRef}
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
        >
          <Box display="flex" justifyContent="center" style={{ gap: '12px' }}>
            {products.map((product) => {
              const {
                foto,
                nomeProduto,
                quantidade,
                preco,
                garantia,
              } = product;

              const {
                idGarantia,
                periodo,
                valorGarantia,
              } = garantia;

              return (
                <Box display="flex" key={product.id} width={220}>
                  <Box>
                    <img
                      style={{ width: '50px', height: '50px', marginRight: '4px' }}
                      src={`https://img.lojadomecanico.com.br/IMAGENS/${foto}`}
                      alt={`produto-${nomeProduto}`}
                    />
                  </Box>
                  <Box>
                    <Typography className={productTitle}>{nomeProduto}</Typography>
                    <Typography className={productPrice}>
                      {quantidade}
                      {' '}
                      Und. -
                      {' '}
                      {toMoney(preco)}
                    </Typography>
                    {idGarantia && (
                      <Box alignItems="center" display="flex" style={{ gap: '4px', marginTop: '4px' }}>
                        <img
                          className={productGuaranteeImage}
                          src="https://img.lojadomecanico.com.br/imagens_publicidade/icon-garantia-mobile.png"
                          alt="garantiaLogo"

                        />
                        <Typography className={productGuarantee}>
                          {`${periodo} meses - ${toMoney(valorGarantia)}`}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </div>

        <Fade in={isHovered}>
          <IconButton
            className={navButton}
            onClick={handleScrollRight}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Fade>
      </div>
    </div>
  );
};

OrderProducts.propTypes = {
  products: PropTypes.instanceOf(Array),
};

OrderProducts.defaultProps = {
  products: null,
};

export default OrderProducts;
