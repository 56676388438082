
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography
} from "@material-ui/core"

import {
  HighlightOffRounded
} from '@material-ui/icons';

import {
  getAddInfoAction,
  updateAddInfoAction
} from '../../actions';

import {
  connect
} from 'react-redux';

import useStyles from "./styles";

const AddInfoDialog = ({
  open,
  addInfo,
  onClose,
  loading,
  updateAddInfo,
  getAddInfo
}) => {
  const {
    labelField,
    divider,
    dialogTitleContent,
    dialogTitleTypography,
    dialogInfoAditionalContainer,
    dialogInfoAditionalField,
    dialogInfoActionsButton,
  } = useStyles();

  const [infoField, setInfoField] = useState(addInfo || '');
  const [errorButton, setButtonError] = useState(true);

  const handleUpdateAddInfo = async () => {
    await updateAddInfo(infoField).then((r) => {
      getAddInfo();
      onClose();
    })
  }

  useEffect(() => {
    setButtonError(infoField?.length > 100)
  }, [infoField]);

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>
        <Box className={dialogTitleContent}>
          <Typography className={dialogTitleTypography}>{addInfo ? 'Alterar' : 'Adicionar'} Informações Adicionais</Typography>
          <IconButton size="small" onClick={onClose}><HighlightOffRounded /></IconButton>
        </Box>
      </DialogTitle>

      <Divider classes={divider} />

      <DialogContent className={dialogInfoAditionalContainer}>
        <label
          className={labelField}
        >
          Informações Adicionais para Entrega
        </label>
        <TextField
          className={dialogInfoAditionalField}
          value={infoField}
          onChange={(e) => setInfoField(e.target.value)}
          multiline
          variant='outlined'
          inputProps={{ maxLength: 100 }}
          helperText={
            <Box display="flex" justifyContent={'flex-end'} whiteSpace="nowrap">
              {/* {infoField.length < 5 && (
                <Box overflow="hidden" textOverflow="ellipsis">
                  Mínimo 5 caracteres
                </Box>
              )} */}
              <Box color={infoField.length >= `100` ? 'error.main' : undefined}>
                {infoField.length} / {`100`}
              </Box>
            </Box>
          }
          placeholder="Descrição da fachada, pontos de referência, informações de segurança etc."
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="text"
          className={dialogInfoActionsButton}
          disabled={loading}
        >
          Voltar
        </Button>

        <Button
          disableElevation
          onClick={handleUpdateAddInfo}
          color="primary"
          variant="contained"
          className={dialogInfoActionsButton}
          disabled={errorButton || loading}
        >
          {loading ? (
            <>
              <CircularProgress color='disabled' size={20} style={{marginRight: 2}}/>
              Salvando...
            </>
          ) : 'Salvar Informações'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}


AddInfoDialog.defaultProps = {
  open: false,
  addInfo: ''
};

AddInfoDialog.propTypes = {
  open: PropTypes.bool,
  addInfo: PropTypes.string
};

const mapState = (state) => {
  const { snack } = state.main.app;
  const { loading } = state.main.checkout.geral;

  return ({
    loading,
    snack,
  });
};

export default connect(mapState, {
  updateAddInfo: updateAddInfoAction,
  getAddInfo: getAddInfoAction
})(AddInfoDialog);
