export default {
  GET_CHECKOUT_DETAIL_REQUEST: '@recovery/GET_CHECKOUT_DETAIL_REQUEST',
  GET_CHECKOUT_DETAIL_SUCCESS: '@recovery/GET_CHECKOUT_DETAIL_SUCCESS',
  GET_CHECKOUT_DETAIL_FAILURE: '@recovery/GET_CHECKOUT_DETAIL_FAILURE',
  GET_AGENDAMENTOS_SUCCESS: '@recovery/GET_AGENDAMENTOS_SUCCESS',
  GET_AGENDAMENTOS_FAILURE: '@recovery/GET_AGENDAMENTOS_FAILURE',
  PUT_RECOVERY_DENIED_SUCCESS: '@recovery/PUT_RECOVERY_DENIED_SUCCESS',
  PUT_RECOVERY_DENIED_REQUEST: '@recovery/PUT.RECOVERY_DENIED_REQUEST',
  PUT_RECOVERY_DENIED_FAILURE: '@recovery/PUT_RECOVERY_DENIED_FAILURE',
  AGENDAMENTO_REQUEST: '@recovery/AGENDAMENTO_REQUEST',
  AGENDAMENTO_SUCCESS: '@recovery/AGENDAMENTO_SUCCESS',
  AGENDAMENTO_FAILURE: '@recovery/AGENDAMENTO_FAILURE',
  POST_RECUPERAR_REQUEST: '@recovery/POST_RECUPERAR_REQUEST',
  POST_RECUPERAR_SUCCESS: '@recovery/POST_RECUPERAR_SUCCESS',
  POST_RECUPERAR_FAILURE: '@recovery/POST_RECUPERAR_FAILURE',
  GET_CHECKOUT_FAILURE: '@recovery/GET_CHECKOUT_FAILURE',
  GET_CHECKOUT_SUCCESS: '@recovery/GET_CHECKOUT_SUCCESS',
  GET_CHECKOUT_REQUEST: '@recovery/GET_CHECKOUT_REQUEST',
  GET_CHECKOUT_BY_DOC_FAILURE: '@recovery/GET_CHECKOUT_BY_DOC_FAILURE',
  GET_CHECKOUT_BY_DOC_SUCCESS: '@recovery/GET_CHECKOUT_BY_DOC_SUCCESS',
  GET_CHECKOUT_BY_DOC_REQUEST: '@recovery/GET_CHECKOUT_BY_DOC_REQUEST',
  GET_RELATORIO_COORDENADOR_REQUEST: '@recovery/GET_RELATORIO_COORDENADOR_REQUEST',
  GET_RELATORIO_COORDENADOR_SUCCESS: '@recovery/GET_RELATORIO_COORDENADOR_SUCCESS',
  GET_RELATORIO_COORDENADOR_FAILURE: '@recovery/GET_RELATORIO_COORDENADOR_FAILURE',
  GET_DETAIL_RELATORIO_COORDENADOR_REQUEST: '@recovery/GET_DETAIL_RELATORIO_COORDENADOR_REQUEST',
  GET_DETAIL_RELATORIO_COORDENADOR_SUCCESS: '@recovery/GET_DETAIL_RELATORIO_COORDENADOR_SUCCESS',
  GET_DETAIL_RELATORIO_COORDENADOR_FAILURE: '@recovery/GET_DETAIL_RELATORIO_COORDENADOR_FAILURE',
  TAKE_CHECKOUT_REQUEST: '@recovery/TAKE_CHECKOUT_REQUEST',
  TAKE_CHECKOUT_SUCCESS: '@recovery/TAKE_CHECKOUT_SUCCESS',
  TAKE_CHECKOUT_FAILURE: '@recovery/TAKE_CHECKOUT_FAILURE',
  CHANGE_PAGE: '@recovery/CHANGE_PAGE',
  SET_META: '@recovery/SET_META',
  CLEAR_META: '@recovery/CLEAR_META',
  OPEN_FILTER: '@recovery/OPEN_FILTER',
  CLOSE_FILTER: '@recovery/CLOSE_FILTER',
  GET_VENDEDORES_REQUEST: '@recovery/GET_VENDEDORES_REQUEST',
  GET_VENDEDORES_SUCCESS: '@recovery/GET_VENDEDORES_SUCCESS',
  GET_VENDEDORES_FAILURE: '@recovery/GET_VENDEDORES_FAILURE',
  PUT_VENDEDORES_REQUEST: '@recovery/PUT_VENDEDORES_REQUEST',
  PUT_VENDEDORES_SUCCESS: '@recovery/PUT_VENDEDORES_SUCCESS',
  PUT_VENDEDORES_FAILURE: '@recovery/PUT_VENDEDORES_FAILURE',

  GET_PEDIDOS_REQUEST: '@recovery/GET_PEDIDOS_REQUEST',
  GET_PEDIDOS_SUCCESS: '@recovery/GET_PEDIDOS_SUCCESS',
  GET_PEDIDOS_FAILURE: '@recovery/GET_PEDIDOS_FAILURE',
  POST_RECUPERAR_PEDIDO_SUCCESS: '@recovery/POST_RECUPERAR_PEDIDO_SUCCESS',
  POST_RECUPERAR_PEDIDO_FAILURE: '@recovery/POST_RECUPERAR_PEDIDO_FAILURE',
  GET_LIST_OPTION_REQUEST: '@recovery/GET_LIST_OPTION_REQUEST',
  GET_LIST_OPTION_SUCCESS: '@recovery/GET_LIST_OPTION_SUCCESS',
  GET_LIST_OPTION_FAILURE: '@recovery/GET_LIST_OPTION_FAILURE',
  POST_DECLINIO_PEDIDO_REQUEST: '@recovery/POST_DECLINIO_PEDIDO_REQUEST',
  POST_DECLINIO_PEDIDO_SUCCESS: '@recovery/POST_DECLINIO_PEDIDO_SUCCESS',
  POST_DECLINIO_PEDIDO_FAILURE: '@recovery/POST_DECLINIO_PEDIDO_FAILURE',
};
