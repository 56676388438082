import { setCookie, getCookie } from '../utils/cookieApi';
import apiService from '../services/apiService';

const APP_KEY = 'PDV-LOJA-DO-MECANICO';

export const getUser = () => {
  const user = getCookie(`${APP_KEY}-USER`);
  return user ? JSON.parse(user) : null;
};

export const setUser = (user) => {

  delete user.filiais;
  delete user.categoriaVendedor;
  delete user.equipe;
  delete user.tipoVendedor;
  delete user.codigoRH;
  delete user.qtdBoletosNPagos;
  delete user.vendedorLoja3;
  delete user.intVendedor;
  delete user.localUltAlteracao;
  delete user.usuarioUltimaAlteracao;
  delete user.usuarioCadastro;
  delete user.dataUltAcesso;
  delete user.dataUltimaAlteracao;

  for (var i = 0; i < 30; i++ ) {
      if (user.permissoes[i] !== undefined) {
          delete user.permissoes[i].nome;
      }
  } 
  
  setCookie(
    `${APP_KEY}-USER`,
    JSON.stringify(user),
    7,
  );

};

export const setUserFiliais = (filial, user) => {
  clearUser();
  delete user.filiais;
  delete user.categoriaVendedor;
  delete user.equipe;
  delete user.tipoVendedor;
  delete user.codigoRH;
  delete user.qtdBoletosNPagos;
  delete user.intVendedor;
  delete user.localUltAlteracao;
  delete user.usuarioUltimaAlteracao;
  delete user.usuarioCadastro;
  delete user.dataUltAcesso;
  delete user.dataUltimaAlteracao;

  for (var i = 0; i < 30; i++ ) {
      if (user.permissoes[i] !== undefined) {
          delete user.permissoes[i].nome;
      }
  } 

  user.filiais = [filial];
                    
  setCookie(
    `${APP_KEY}-USER`,
    JSON.stringify(user),
    7,
  );

};

export const clearUser = () => {
  setCookie(
    `${APP_KEY}-USER`,
    '',
    -1,
  );
};
