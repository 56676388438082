import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints, transitions }) => ({
  wrapper: {
    maxHeight: 0,
    maxWidth: '1200px',
    boxShadow: 'none',
    borderRadius: '16px',
    marginBottom: '14px',
    backgroundColor: '#FFFFFF',
    overflow: 'hidden',
    transition: transitions.create('max-height', {
      duration: transitions.duration.standard,
      easing: transitions.easing.easeInOut,
    }),
  },
  wrapperOpened: {
    maxHeight: 180,
    [breakpoints.down('xs')]: {
      maxHeight: 520,
    },
  },
  searchButton: {
    float: 'right',
  },
  buttonCleanFilter: {
    '& .MuiButton-label': {
      display: 'flex',
      gap: '5px',
    },

    '& svg path': {
      fill: '#F4282D',
    },

    '& .MuiTypography-body1': {
      display: 'flex',
      alignItems: 'center',
      color: '#F4282D',
      fontSize: '12px',
      fontWeight: '600',
      textTransform: 'capitalize',
      height: '32px',
    },
  },
  actionsFilter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24,
  },
  labelField: {
    fontSize: 14,
    fontWeight: 600,
    color: '#7B7F82',
    marginBottom: 6,
  },
}));

export default useStyles;
