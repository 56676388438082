import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Divider, Grid } from '@material-ui/core';
import { InstallmentsDialog } from '../index';
import { getUnidadeDeNegocio } from '../../../services/app';

import useStyles from './styles';

const ContentText = ({
  priceProduct,
  data,
  className,
  notDiscount,
  isFisicStore,
  isDetails,
  listCard,
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const {
    centsPer,
    discount,
    discountIcon,
    installments: installmentsStyled,
    priceGrid,
    priceOf,
    priceOfBox,
    pricePer,
    pricePerBox,
    searchInstallmentsBtn,
  } = classes;

  const {
    available,
    pma,
    full,
    installmentPaymentQuantity,
    installmentPaymentValue,
    promotionalDiscountPercentage,
    term,
    cash,
    ppl,
  } = data;

  const showCashPrice = getUnidadeDeNegocio() == 1 && !ppl;

  const formatToMonetary = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const termInteger = Math.trunc(ppl ? (ppl.price.term || ppl.price) : term);
  const termCents = ((ppl ? (ppl.price.term || ppl.price) : term) - termInteger)
    .toFixed(2)
    .split('.')[1] || '00';

  const cashInteger = Math.trunc(ppl ? (ppl.price.cash || ppl.price) : cash);
  const cashCents = ((ppl ? (ppl.price.cash || ppl.price) : cash) - cashInteger)
    .toFixed(2)
    .split('.')[1] || '00';

  const PriceDisplay = ({ label, integer, cents }) => (
    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gridGap={8}>
      {showCashPrice && (
        <b style={{ fontSize: 10, fontWeight: 500 }}>
          {label}
        </b>
      )}

      <Box className={pricePerBox}>
        <span className={`${pricePer} ${listCard && 'pricePer'}`}>
          {`R$ ${integer.toLocaleString('pt-br')}`}
        </span>
        <span className={`${centsPer} ${listCard && 'centsPer'}`}>{`,${cents}`}</span>
      </Box>
    </Box>
  );

  if (priceProduct) {
    return (
      <Grid className={`${priceGrid} ${className} ${!available && 'disabled'}`}>
        <Box className={priceOfBox}>
          <span className={priceOf}>
            {formatToMonetary.format(term > full ? term : full)}
          </span>
          {!notDiscount && (full > term) && (
            <span className={discount}>
              <div className={discountIcon} />
              {`${(promotionalDiscountPercentage || 10)}%`}
            </span>
          )}
        </Box>
        {isDetails ? (
          <Box display="flex" flexDirection="row" gridGap={8} whiteSpace="nowrap">
            <PriceDisplay label="Preço à prazo" integer={termInteger} cents={termCents} />
            {showCashPrice && (
              <>
                <Divider orientation="vertical" flexItem />
                <PriceDisplay label="Preço à vista" integer={cashInteger} cents={cashCents} />
              </>
            )}
          </Box>
        ) : (
          <Box className={pricePerBox}>
            <span className={`${pricePer} ${listCard && 'pricePer'}`}>
              {`R$ ${termInteger.toLocaleString('pt-br')}`}
            </span>
            <span className={`${centsPer} ${listCard && 'centsPer'}`}>{`,${termCents}`}</span>
          </Box>
        )}
        {
          !isFisicStore && !isDetails ? (
            <span className={installmentsStyled}>
              <b>
                {`${!pma && !ppl
                  ? (formatToMonetary.format(term))
                  : ''} em ${installmentPaymentQuantity}x ${formatToMonetary.format(installmentPaymentValue)}`}
              </b>
            </span>
          ) : (
            <>
              <button
                type="button"
                className={searchInstallmentsBtn}
                onClick={() => setOpen(true)}
              >
                <span>
                  CONSULTAR PARCELAMENTO
                </span>
              </button>
              <InstallmentsDialog
                handleOpen={{ open, setOpen }}
                price={ppl ? (ppl.price.term || ppl.price) : term}
              />
            </>
          )
        }

      </Grid>
    );
  }

  return <></>;
};

ContentText.defaultProps = {
  priceProduct: false,
  notDiscount: false,
  isFisicStore: false,
  isDetails: false,
  listCard: false,
  className: '',
};

ContentText.propTypes = {
  priceProduct: PropTypes.bool,
  data: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
  notDiscount: PropTypes.bool,
  isFisicStore: PropTypes.bool,
  isDetails: PropTypes.bool,
  listCard: PropTypes.bool,
};

export default ContentText;
