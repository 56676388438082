import React from 'react';
import { Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useStyles from './styles';
import { push } from '../../../../history';
import { getClient } from '../../../services/authClient';
import { resetSearchAction } from '../../../../main/checkout/actions';

const Cart = ({ client, isCheckout, resetSearch }) => {
  const {
    cartBox,
    cartIcon,
    itemsQty,
  } = useStyles();

  const postCheckout = async () => {
    await resetSearch(true);
    if (!isCheckout) {
      push(`/checkout/${client ? client.checkoutId : 0}`);
    }
  };

  return (
    <Box className={cartBox}>
      <Button
        disabled={client
          ? !client.checkoutId
          : true}
        onClick={postCheckout}
      >
        {!!(client && client.qtyProducts) && (
          <span className={itemsQty}>
            {client ? client.qtyProducts : 0}
          </span>
        )}
        <span className={cartIcon} />
        <span>Carrinho</span>
      </Button>
    </Box>
  );
};

Cart.defaultProps = {
  client: null,
  isCheckout: false,
};

Cart.propTypes = {
  client: PropTypes.instanceOf(Object),
  isCheckout: PropTypes.bool,
  resetSearch: PropTypes.func.isRequired,
};

const mapState = (state) => {
  let { client } = state.authClient;

  client = getClient() || client;

  return ({
    client,
  });
};

export default connect(mapState, {
  resetSearch: resetSearchAction,
})(Cart);
