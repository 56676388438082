import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints }) => ({
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70vh',

    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hitsBox: {
    justifyContent: 'flex-start',
    listStyle: 'none',
    gap: '30px 35px',
    padding: '0 40px 25px',
    margin: '290px 0 0 0',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 209px)',

    '&.d-none': {
      display: 'none',
    },

    '&.list-card': {
      display: 'flex',
      flexDirection: 'column',
    },

    '&.hidden': {
      justifyContent: 'center',
      marginTop: 215,
    },

    [breakpoints.down('xs')]: {
      marginTop: 301,
    },

    [breakpoints.between('sm', 'sm')]: {
      marginTop: 198,
    },

    [breakpoints.between('md', 'md')]: {

      '&.hidden': { marginTop: 170 },
      marginTop: 210,
      justifyContent: 'center',
    },

    [breakpoints.down(1400)]: {
      '&.list-card': {
        padding: '0 20px 25px',
      },
    },

    [breakpoints.between(1700, 2000)]: {
      marginTop: '25vh',
    },

    [breakpoints.between(2000, 2300)]: {
      marginTop: 280,
    },

    [breakpoints.up(2300)]: {
      marginTop: 230,
    },
  },
  drawerToFilterDiv: {
    position: 'relative',
  },
}));

export default useStyles;
