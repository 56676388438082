/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Divider,
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { connect } from 'react-redux';

import { getCheckoutAction } from '../../checkout/actions';
import logo from '../../../assets/logo.svg';
import UserAvatar from '../userAvatar';
import useStyles from './styles';

const MainAppBar = ({
  drawer, user,
}) => {
  const classes = useStyles();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'));

  return (
    <AppBar
      className={classes.appBar}
      color="default"
      position="fixed"
    >
      <Toolbar disableGutters className={drawer ? classes.toolBarOrange : classes.toolBarWhite}>
        <Button
          className={`${classes.button} ${!drawer && 'button-header'}`}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          tabIndex="-1"
          onClick={() => window.location.reload()}
        >
          <Box position="relative">
            {!isMobile ? (<img src={logo} alt="Loja do Mecanico" style={{ marginLeft: 85, height: 23 }} />) : (<></>)}
            <Typography variant="caption" className={classes.version}>
              {process.env.REACT_APP_VERSION}
            </Typography>
          </Box>
        </Button>

        <Box className="right-container">
          <Box>
            <Typography align="right" variant="subtitle2" style={{ fontWeight: 600 }}>
              Olá,
              {' '}
              {user.nomeVendedor}
            </Typography>
            {user.emailVendedor !== null
              ? (
                <Typography variant="caption" style={ !isMobile ? {fontSize: '0.75rem'} : {fontSize: '0.65rem'} }>
                  {user.emailVendedor}
                </Typography>
              )
              : (
                <Typography variant="caption">
                  vendedor@gurgelmix.com.br
                </Typography>
              )}
          </Box>
          <UserAvatar />
        </Box>
      </Toolbar>
      {!isMobile ? (<Divider />) : (<></>)}

    </AppBar>
  );
};

MainAppBar.defaultProps = {
  drawer: true,
};

MainAppBar.propTypes = {
  drawer: PropTypes.bool,
  user: PropTypes.instanceOf(Object).isRequired,
};
const mapState = (state) => {
  const { snack } = state.main.app;
  const { checkout } = state.main.checkout.geral;
  return ({
    ...state.main.app,
    user: state.auth.login.user,
    checkout,
    snack,
  });
};
export default connect(mapState, {
  getCheckout: getCheckoutAction,
})(MainAppBar);
