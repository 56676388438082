/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import {
  change, Field, Form, formValueSelector, reduxForm,
} from 'redux-form';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import {
  SelectRender,
} from '../../../../../shared/fields';
import { required } from '../../../../../shared/fields/validate';
import PAYMENT_OPTIONS from '../../../../../shared/utils/paymentOptions';
import {
  getInstallmentsAction, openDialogPaymentAction, updatePaymentTypeAction, getPixQRCodeAction,
  openDialogPixAction, postPixSmsAction, postPixWhatsAction, postPixEmailAction,
} from './actions';
import {
  generateLinkAction, savePaymentAction, savePaymentBilletDiscountAction, savePaymentPixAction,
} from '../actions';
import makePayment from '../../../../../shared/utils/makePayment';
import { toMoney } from '../../../../../shared/utils/parsers';
import TradePaymentPix from './TradePaymentPix';
import useStyles from './styles';

const formName = 'checkout/advanced';

const AdvancedPaymentForm = ({
  updatePaymentType, typePayment, savePayment, handleSubmit, checkoutId,
  dispatch, total, cardInstallments, sellerName,
  installments, snack, discountCart,
  customer, paymentTermId, savePaymentBilletDiscount, savePaymentPix,
  hasDiscount, payments, checkout, selectedItem
}) => {
  const classes = useStyles();
  const [paymentPix, setPaymentPix] = useState('');
  const [inputValue, setInputValue] = useState('');
  const pPix = payments.find((a) => a.type === 'PX');
  const typePaymentPix = PAYMENT_OPTIONS.find((a) => a.code === 'PX');
  useEffect(() => {
    if (typePayment === 'CC' && cardInstallments) {
      const value = installments.find((installment) => (
        installment.quantidadeParcelas === cardInstallments
      ));
      const installmentValue = total / value.quantidadeParcelas;
      dispatch(change(formName, 'installmentsValue', installmentValue));
    }
  }, [typePayment, cardInstallments, total, dispatch, installments]);
  const handleChangeType = (e) => {
    const isDiscountMethod = ['B', 'DN', 'DC', 'PX'].includes(e.target.value);
    updatePaymentType(e.target.value);
    dispatch(change(formName, 'total', 0));
  };

  useEffect(() => {
    dispatch(change(formName, 'total', 0));
  }, [paymentTermId, dispatch, hasDiscount]);

  const onSubmit = (info) => {
    try {
      if (typePayment === 'PX' && inputValue > 0) {
        const json = makePayment(checkoutId, info, sellerName, customer);
        if (json.hasDiscount) return savePaymentBilletDiscount(json);
        return savePaymentPix(json, true);
      }

      const json = makePayment(checkoutId, info, sellerName, customer);
      if (json.hasDiscount) return savePaymentBilletDiscount(json);
      return savePayment(json, true);
    } catch (error) {
      return snack.enqueueSnackbar(error.message || error, { variant: 'warning' });
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1} className={classes.advancedPaymentGrid}>

          <Grid item xs={12} sm={12}>
            <Field
              disabled={pPix.type === 'PX' && true}
              name="type"
              type="text"
              label="Forma de Pagamento"
              labelselect="nome"
              valueselect="id"
              component={SelectRender}
              options={PAYMENT_OPTIONS}
              validate={[required]}
              model={{
                id: 'code',
                name: 'name',
                disabled: 'disabled',
              }}
              onChange={handleChangeType}
              classes={{ root: classes.selectStyled }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              disabled={pPix.type === 'PX' && true}
              name="total"
              label="Valor do pix"
              value={toMoney(selectedItem.total)}
              onChange={(text) => setInputValue(text)}
              InputProps={{
                endAdornment: <Button className={classes.buttonGerar} type="submit" disabled={pPix.type === 'PX' && true}>Gerar Pagamento</Button>,
              }}
              variant="outlined"
              classes={{ root: classes.pixTextField }}
            />
          </Grid>

          {typePaymentPix.code === 'PX' && (
          <TradePaymentPix
            setPaymentPix={setPaymentPix}
            paymentPix={paymentPix}
            valueInput={inputValue}
            pix={pPix !== undefined ? pPix : 0}
            checkout={checkout}
            selectedItem={selectedItem}
          />
          )}
        </Grid>
      </Form>
    </>
  );
};

AdvancedPaymentForm.propTypes = {
  updatePaymentType: PropTypes.func.isRequired,
  typePayment: PropTypes.string,
  sellerName: PropTypes.string.isRequired,
  checkoutId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  total: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  cardInstallments: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  savePayment: PropTypes.func.isRequired,
  savePaymentPix: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  snack: PropTypes.instanceOf(Object).isRequired,
  discountCart: PropTypes.number.isRequired,
  customer: PropTypes.instanceOf(Object).isRequired,
  installments: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  savePaymentBilletDiscount: PropTypes.func.isRequired,
  paymentTermId: PropTypes.number,
  hasDiscount: PropTypes.string,
  payments: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  checkout: PropTypes.instanceOf(Object).isRequired,
};

AdvancedPaymentForm.defaultProps = {
  typePayment: '',
  total: null,
  cardInstallments: null,
  paymentTermId: null,
  hasDiscount: null,
};

const seletor = formValueSelector(formName);
export default connect((state) => {
  const { config } = state.main.app;
  const { geral, payment } = state.main.checkout;
  const { conditions, loading, awaitPix } = payment;
  const {
    customer, checkoutId, resume, discountCart, payments,
  } = geral.checkout;
  const total = seletor(state, 'total');
  const paymentTermId = seletor(state, 'paymentTermId');

  return {
    ...state.main.checkout.advanced,
    hasDiscount: seletor(state, 'hasDiscount'),
    snack: state.main.app.snack,
    sellerName: state.auth.login.user.nomeVendedor,
    checkoutId,
    conditions,
    payments,
    pixQrCod: state.main.checkout.advanced.pixQrCod,
    pixEmail: state.main.checkout.advanced.pixEmail,
    pixSms: state.main.checkout.advanced.pixSms,
    pixWhats: state.main.checkout.advanced.pixWhats,
    remainderInstallment: resume.remainderInstallment,
    paymentTermId,
    remainder: resume.remainder,
    loading,
    awaitPix,
    pixEnabled: !!config.bitPix,
    installments: state.main.checkout.advanced.installments.map((installment) => ({
      ...installment,
      label: `${installment.quantidadeParcelas} - ${toMoney(installment.valorParcela)}`,
    })),
    total,
    cardInstallments: seletor(state, 'installments'),
    discountCart,
    hasPayments: !!payments.length,
    customer,
    initialValues: {
      phone: customer.phone,
      cpfCnpj: customer.document,
      paymentTermId: 11,
    },
  };
}, {
  openDialogPayment: openDialogPaymentAction,
  openDialogPix: openDialogPixAction,
  updatePaymentType: updatePaymentTypeAction,
  savePayment: savePaymentAction,
  savePaymentPix: savePaymentPixAction,
  getInstallments: getInstallmentsAction,
  getPixQRCode: getPixQRCodeAction,
  postPixSms: postPixSmsAction,
  postPixWhats: postPixWhatsAction,
  postPixEmail: postPixEmailAction,
  generateLink: generateLinkAction,
  savePaymentBilletDiscount: savePaymentBilletDiscountAction,
})(reduxForm({
  form: 'checkout/advanced',
})(AdvancedPaymentForm));
