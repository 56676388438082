/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Divider, Typography, Tooltip, Badge, Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {
  common,
} from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import ItemResumeOrderInfo from './itemResumeOrderInfo';
import { toMoney } from '../../../utils/parsers';
import TotalResumeOrderInfo from './totalResumeOrderInfo';
import DialogCoupon from './dialogCoupon';
import {
  deleteCouponAction,
  openDialogCouponAction,
  openDialogDiscountAction,
  couponValeListAction,
} from '../../../../main/checkout/actions';
import { FlatExpansionPanelSummary, FlatExpansionPanel } from '../../flatExpansionPanel';
import DialogDiscount from './dialogDiscount';
import useStyles from './styles';
import apiService from '../../../services/apiService';

const ResumeOrderInfo = ({
  productsCount, subTotalPrazo, shipping, voucher, maxDiscount, total,
  discountStatus, openDialogCoupon, openDialogDiscount, readOnly, isNotMarketPlaceOnly,
  hasMarketPlace, couponCount, chosedFreight, hasOrder,
  payments, totalGarantiaEstendida, customerId, couponValeList, couponValeListCheck,
}) => {
  const classes = useStyles({
    discountStatus,
    readOnly,
    hasOrder,
  });
  const [open, setOpen] = useState(true);
  const handleToggle = () => setOpen(!open);
  const hasCoupon = payments?.filter((item) => { return item.type === 'VA' || item.type === 'CP' })?.length > 0

  useHotkeys('shift+r', handleToggle, [handleToggle]);
  useHotkeys(
    'shift+x',
    async () => ((!readOnly) && (!hasOrder) && (!hasMarketPlace)
      && (chosedFreight)) && handleOpenDialogCoupon(),
    [openDialogCoupon, readOnly, hasMarketPlace, hasOrder],
  );
  useHotkeys(
    'shift+w',
    () => !readOnly && !hasOrder && isNotMarketPlaceOnly && !hasCoupon && openDialogDiscount(),
    [openDialogDiscount, readOnly, isNotMarketPlaceOnly, hasOrder, hasCoupon],
  );

  const [loadingCouponList, setLoadingCouponList] = useState(true);
  const [couponList, setCouponList] = useState([]);

  const totalCoupons = useMemo(() => {
    const totalCp = (couponList || [])
      .filter((coupon) => coupon.tipo === 'CP')
      .reduce((accumul, coupon) => coupon.valorVale + accumul, 0);
    const totalVl = (couponList || [])
      .filter((coupon) => coupon.tipo === 'VA')
      .reduce((accumul, coupon) => coupon.valorVale + accumul, 0);

    return totalCp > totalVl ? totalCp : totalVl;
  }, [couponList]);

  const handleOpenDialogCoupon = async () => {
    if (customerId) {
      openDialogCoupon();
      if(couponList.length > 0 && couponValeList) {
        setLoadingCouponList(false);
      } else {
        const { data: { results } } = await apiService.get(`/clientes/${customerId}/vales`);
        setCouponList(results);
        setLoadingCouponList(false);
        couponValeListCheck();
      }

    }
  }

  return (
    <>
      <FlatExpansionPanel expanded={open} onChange={handleToggle} style={{ backgroundColor: 'transparent', position: 'inherit' }}>
        <Tooltip title="shift + R" placement="left">
          <span>
            <FlatExpansionPanelSummary style={{minHeight: 40, height: 40}}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="resume-content"
              id="resume-header"
            >
              <Typography component="h1" style={{ fontWeight: 600, fontSize: 14 }}>
                Resumo da Compra
              </Typography>
            </FlatExpansionPanelSummary>
          </span>
        </Tooltip>
        <Box display="flex" flexDirection="column" flex="1" pb={1}>
          <ItemResumeOrderInfo
            primary={`Produtos(${productsCount})`}
            secondary={toMoney(subTotalPrazo)}
            colorSecondary={common.black}
          />
          <ItemResumeOrderInfo
            primary="Entrega"
            secondary={toMoney(shipping)}
            colorSecondary={common.black}
          />
          <ItemResumeOrderInfo
            primary="Garantia Estendida"
            secondary={toMoney(totalGarantiaEstendida)}
            colorSecondary={common.black}
          />
          <ItemResumeOrderInfo
            primary={(
              <Tooltip
                disableFocusListener={readOnly}
                disableHoverListener={readOnly}
                disableTouchListener={readOnly}
                title={hasMarketPlace
                  ? 'Pedidos com itens Market Place não podem consumir vale compra'
                  : !chosedFreight
                    ? 'Selecione uma forma de entrega'
                    : 'Gerenciar Cupom: shift + X'}
              >
                <span>
                  <Button
                    className={classes.labelButton}
                    disableElevation
                    disableRipple
                    disabled={hasMarketPlace
                      || hasOrder}
                    onClick={() => { handleOpenDialogCoupon() }}
                  >
                    <Badge
                      badgeContent={couponCount}
                      color="primary"
                      max={9}
                      invisible={!couponCount}
                      classes={{ badge: classes.badge }}
                    >
                      Vale Compra
                    </Badge>

                    <Box pl="4px" />

                    {!readOnly && !hasMarketPlace && chosedFreight && (
                      <Box color="rgba(0, 0, 0, 0.54)">
                        <EditIcon />
                      </Box>
                    )}
                  </Button>
                </span>
              </Tooltip>
            )}
            secondary={(
              <Tooltip
                open={readOnly ? false : undefined}
                title={`Total Em Vales: ${toMoney(totalCoupons)}`}
                placement="left"
              >
                <span>
                  {toMoney(voucher)}
                </span>
              </Tooltip>
            )}
          />
          {!readOnly && (
            <Tooltip
              title={
                hasCoupon
                ? 'Para inclusão/exclusão ou edição de desconto, deverá ser removido os pagamentos do tipo vale e cupom.'
                : (
                  !isNotMarketPlaceOnly
                  ? 'O pedido só contem itens Market Place'
                  : 'Editar Desconto Total: shift + W'
                  )
                }
            >
              <span>
                <ItemResumeOrderInfo
                  primary={(
                    <Button
                      className={classes.labelButton}
                      disableElevation
                      disableRipple
                      onClick={openDialogDiscount}
                      disabled={readOnly || !isNotMarketPlaceOnly || hasOrder || hasCoupon}
                    >
                      Desconto
                      <Box pl="4px" />
                      {isNotMarketPlaceOnly && (
                        <Box color="rgba(0, 0, 0, 0.54)">
                          <EditIcon />
                        </Box>
                      )}
                    </Button>
                  )}
                  secondary={(
                    <Tooltip
                      open={readOnly ? false : undefined}
                      title={`Desconto Máximo: ${toMoney(maxDiscount)}`}
                      placement="left"
                    >
                      <span>
                        {toMoney(maxDiscount)}
                      </span>
                    </Tooltip>
                  )}
                />
              </span>
            </Tooltip>
          )}
          {!hasOrder && (
            <>
              <DialogCoupon couponList={couponList} customerId={customerId} loading={loadingCouponList} />
              <DialogDiscount subTotalPrazo={subTotalPrazo} readOnly={readOnly} isNotMarketPlaceOnly={isNotMarketPlaceOnly} hasCoupon={hasCoupon} />
            </>
          )}
        </Box>
      </FlatExpansionPanel>
      {readOnly && (
        <>
          <Divider />
          <TotalResumeOrderInfo
            primary="TOTAL"
            cash={`${toMoney(total)}`}
            primaryWeight
          />
        </>
      )}
    </>
  );
};

ResumeOrderInfo.defaultProps = {
  discountStatus: null,
  readOnly: false,
  hasOrder: false,
  isNotMarketPlaceOnly: false,
  hasMarketPlace: false,
  couponCount: 0,
  chosedFreight: false,
  payments: undefined,
  customerId: undefined,
};

ResumeOrderInfo.propTypes = {
  productsCount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  subTotalPrazo: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  shipping: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  voucher: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  maxDiscount: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  total: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  discountStatus: PropTypes.string,
  openDialogCoupon: PropTypes.func.isRequired,
  openDialogDiscount: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  hasOrder: PropTypes.bool,
  isNotMarketPlaceOnly: PropTypes.bool,
  hasMarketPlace: PropTypes.bool,
  couponCount: PropTypes.number,
  couponValeList: PropTypes.bool.isRequired,
  chosedFreight: PropTypes.bool,
  payments: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  customerId: PropTypes.number,
};

const mapState = (state) => state.main.checkout.geral;

export default connect(mapState, {
  openDialogCoupon: openDialogCouponAction,
  openDialogDiscount: openDialogDiscountAction,
  deleteCoupon: deleteCouponAction,
  couponValeListCheck: couponValeListAction,
})(ResumeOrderInfo);
