import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  productTitle: {
    width: '100%',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflowWrap: 'break-word',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    fontSize: 14,
    fontWeight: 500,
    color: '#242424'
  },
  productPrice: {
    fontSize: 14,
    fontWeight: 600,
    color: '#909599',
    marginTop: 4,
  },
  productGuarantee: {
    fontSize: 12,
    color: '#999',
    marginTop: 4,
  },
  productGuaranteeImage: {
    width: '22px',
    float: 'left',
    position: 'relative'
  },
  root: {
    position: 'relative',
    width: '100%',
    margin: '24px auto 0 auto',
  },
  sliderWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  slider: {
    display: 'flex',
    overflowX: 'hidden',
    cursor: 'grab',
    userSelect: 'none',
    width: '100%',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  item: {
    flex: '0 0 auto',
    width: 200,
    height: 100,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navButton: {
    transition: 'background-color 0.2s ease-in-out',
  },
}));
export default useStyles;
