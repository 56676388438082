import {
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { CloseIcon, CopyIcon, PplIcon } from '../../../../assets/algolia';

const useStyles = makeStyles(({ breakpoints }) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '25px 20px',
    minHeight: 115,
  },
  closeIcon: {
    background: `url(${CloseIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    cursor: 'pointer',
    width: 24,
    height: 24,
    border: 0,
  },
  imageMain: {
    width: 402,
    height: 402,
  },
  imageStockAndFreightBox: {
    justifyContent: 'space-between',
    display: 'flex',
    gap: 16,
    marginBottom: 50,
  },
  imageGrid: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  image: {
    width: 80,
    height: 80,
    border: '1px solid #E3E3E7',
    borderRadius: 4,
    cursor: 'pointer',
  },
  imageAnchor: {
    width: 80,
    height: 80,
    border: 0,
    background: 'none',
  },
  dialogActionsBox: {
    display: 'flex',
    alignItems: 'center',
    gap: 30,
  },
  descriptionProductGrid: {
    '& h3': {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 600,
      color: '#FE5000',
    },

    '& span': {
      whiteSpace: 'pre-line',
    },
  },
  [breakpoints.down('sm')]: {
    dialogActionsBox: {
      gap: '4px 10px',
      flexWrap: 'wrap',
      margin: '0 !important',
      justifyContent: 'center',
    },
    header: {
      padding: '20px 10px',
    },
    imageStockAndFreightBox: {
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: 20,
      '& #stockTotal': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    imageGrid: {
      flexFlow: 'wrap',
      justifyContent: 'center',
    },
    image: {
      width: 50,
      height: 50,
    },
    imageAnchor: {
      width: 50,
      height: 50,
    },
    imageMain: {
      width: 270,
      height: 270,
    },
  },
  detailsAndCategoriesBox: {
    maxWidth: 650,
  },
  pplIcon: {
    position: 'absolute',
    background: `url(${PplIcon})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: 203,
    height: 84,
    marginTop: -74,
    marginLeft: -99,

    [breakpoints.down('sm')]: {
      marginTop: -63,
    },

    [breakpoints.down('md')]: {
      width: 135,
      height: 43,
      marginLeft: -15,
      backgroundSize: '100%',
    },

    [breakpoints.between('md', 'md')]: {
      marginTop: -68,
    },
  },
  addProductGrid: {
    display: 'flex',
    gap: 30,

    '&.disabled': {
      filter: 'grayscale(1)',
      opacity: '0.6',
    },
  },
  popper: {
    zIndex: 0,
    top: '8px !important',
  },
  tooltip: {
    backgroundColor: '#008000',
    fontSize: 12,
  },
  btnProductErpId: {
    display: 'flex',
    alignItems: 'baseline',
    textDecoration: 'none',
    cursor: 'pointer',
    border: 0,
    backgroundColor: 'white',
    padding: 0,
  },
  productErpId: {
    fontSize: 12,
    fontWeight: 600,
    color: '#242424',
    paddingLeft: 3,
    marginRight: 3,
  },
  copyIcon: {
    backgroundImage: `url(${CopyIcon})`,
    backgroundRepeat: 'no-repeat',
    width: 12,
    height: 12,
  },
  boxAi: {
    position: 'relative',
    [breakpoints.down('sm')]: {
      top: '-34px !important',
    },
  },
}));

export const DialogStyled = withStyles(({ breakpoints }) => ({
  paper: {
    height: 670,
    maxWidth: 1100,
    position: 'initial',
    overflow: 'hidden',

    '& h2': {
      fontSize: 16,
      lineHeight: '24px',
      color: '#242424',
      fontWeight: 600,
      maxWidth: 852,
    },

    [breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,

      '& h2': {
        fontSize: 14,
        lineHeight: '20px',
        paddingRight: 6,
      },
    },

    [breakpoints.up('md')]: {
      width: 1100,
    },
  },
}))(Dialog);

export const DialogActionsStyled = withStyles(({ breakpoints }) => ({
  root: {
    justifyContent: 'space-between',
    padding: '8px 24px',

    [breakpoints.down('sm')]: {
      padding: '8px 10px',
      justifyContent: 'center',
    },

    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}))(DialogActions);

export const DialogTitleStyled = withStyles(() => ({
  root: {
    flex: 'auto',
    padding: 0,
  },
}))(DialogTitle);

export default useStyles;
