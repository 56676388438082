import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Button,
  Dialog,
  Divider,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import {
  change,
  Form,
  formValueSelector,
  reduxForm,
  reset,
  submit,
  touch
} from 'redux-form';

import {
  connect,
  useDispatch
} from 'react-redux';

import {
  CheckboxForm,
  InputCEPRender,
  InputCnpjRender,
  InputCpfRender,
  InputCurrencyRender,
  InputFieldForm,
  InputPhoneRender
} from '../../../../../shared/fields';

import {
  max10Mb,
  required,
  requiredAllTypes
} from '../../../../../shared/fields/validate';

import SelectForm from '../../../../../shared/fields/v2/selectForm/SelectForm';
import InputFormFile from '../../../../../shared/fields/v2/inputFormFile/InputFormFile';

import { getConditions } from '../../../../../shared/repositories/checkoutRepository';
import {allowedFilesExtension} from '../../../../../shared/utils/creditRequests';
import { toMoney } from '../../../../../shared/utils/parsers';
import { requestCreditRequestAction } from '../actions';

import useStyles from './styles';
import DialogFileError from "../../../../../shared/components/dialogFileError/DialogFileError";

export const cpfComprador = (value = '') => {
  return (value ? undefined : 'CPF do comprador é obrigatório');
}

export const nomeComprador = (value = '') => {
  return (value ? undefined : 'Nome do comprador é obrigatório');
}

const CreditRequestModal = ({
  loadingCreditRequest,
  limiteSolicitado,
  semComprador,
  idCondicaoPagamento,
  creditRequestResume,
  requestCreditRequest,
  handleSubmit,
  onClose,
  checkoutId,
  informacaoAdicional,
  snack,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [conditions, setConditions] = useState([]);
  const [buttonSendDisabled, setButtonSendDisabled] = useState(false);
  const [showDialogFileError, setShowDialogFileError] = useState(false);
  const [files, setFiles] = useState([]);

  const {
    checkoutInfo,
    checkoutInfoTotal,
    checkboxNumber,
    title,
    closeTitle,
    dialogTitle,
    dialogTitleTypography,
    divider,
    characterCounter,
  } = classes;

  const openDialogFileError = () => setShowDialogFileError(true);
  const handleCloseDialogFileError = () => setShowDialogFileError(false);

  const handleClose = () => {
    dispatch(reset('creditRequest/new'));
    setConditions([]);
    onClose();
  }

  const handleRemoteSubmit = () => dispatch(submit('creditRequest/new'));
  const handleRequest = async (form) => {
    requestCreditRequest(form, files)
  };

  const handleFiles = (file, name) => {
    const newFile = files.filter((f) => f.name !== name);
    setFiles([...newFile, { file, name }]);
  };

  useEffect(() => {
    getConditions().then((response) => {
      setConditions(response);
    });

    if (creditRequestResume) {
      dispatch(change('creditRequest/new', 'checkoutId', checkoutId));
      dispatch(change('creditRequest/new', 'idVendedor', creditRequestResume.idVendedor));
      dispatch(change('creditRequest/new', 'idCliente', creditRequestResume.idCliente));
      dispatch(change('creditRequest/new', 'nomeClienteComprador', creditRequestResume.nomeComprador));
      dispatch(change('creditRequest/new', 'documentoComprador', creditRequestResume.documentoComprador));
      dispatch(change('creditRequest/new', 'documento', creditRequestResume.documento));
      dispatch(change('creditRequest/new', 'razaoSocial', creditRequestResume.razaoSocial));
      dispatch(change('creditRequest/new', 'telefone', creditRequestResume.telefone));
      dispatch(change('creditRequest/new', 'cep', creditRequestResume.cep));
      dispatch(change('creditRequest/new', 'logradouro', creditRequestResume.logradouro));
      dispatch(change('creditRequest/new', 'numero', creditRequestResume.numero));
      dispatch(change('creditRequest/new', 'semNumero', creditRequestResume.numero === 'S/N'));
      dispatch(change('creditRequest/new', 'complemento', creditRequestResume.complemento));
      dispatch(change('creditRequest/new', 'bairro', creditRequestResume.bairro));
      dispatch(change('creditRequest/new', 'cidade', creditRequestResume.cidade));
      dispatch(change('creditRequest/new', 'estado', creditRequestResume.estado));

      dispatch(touch('creditRequest/new', 'nomeClienteComprador'));
      dispatch(touch('creditRequest/new', 'documentoComprador'));
    }
  }, [creditRequestResume]);

  useEffect(() => {
    if (idCondicaoPagamento) {
      const condition = conditions.find((c) => c.creditId === idCondicaoPagamento);
      dispatch(change('creditRequest/new', 'condicaoPagamento', condition?.nome));
    }
  }, [idCondicaoPagamento]);

  useEffect(() => {
    if (!limiteSolicitado) return;
    const valueLimiteSolicitado = +limiteSolicitado > creditRequestResume.valorTotal ? creditRequestResume.valorTotal : +limiteSolicitado;

    dispatch(change('creditRequest/new', 'limiteSolicitado', valueLimiteSolicitado || ''));
  }, [limiteSolicitado]);

  useEffect(() => {
    if (semComprador) {
      dispatch(change('creditRequest/new', 'nomeClienteComprador', ''));
      dispatch(change('creditRequest/new', 'documentoComprador', ''));
      setButtonSendDisabled(false);
    } else {
      if (creditRequestResume) {
        dispatch(change('creditRequest/new', 'nomeClienteComprador', creditRequestResume.nomeComprador));
        dispatch(change('creditRequest/new', 'documentoComprador', creditRequestResume.documentoComprador));

        if (!creditRequestResume.documentoComprador || !creditRequestResume.nomeComprador) {
          setButtonSendDisabled(true);
        }
      }
    }
  }, [semComprador, creditRequestResume]);

  useEffect(() => {
    if (informacaoAdicional && informacaoAdicional.length === 1000) {
      snack.enqueueSnackbar("O limite máximo de 1000 caracteres foi atingido. O texto foi ajustado automaticamente.", { variant: 'warning' });
    }
  }, [informacaoAdicional]);

  if (!creditRequestResume) {
    return <> </>;
  }

  return (
    <Dialog
      open={creditRequestResume}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
    >
      <DialogTitle className={dialogTitle}>
        <Typography className={dialogTitleTypography}>Nova Solicitação de Venda:</Typography>
        <IconButton className={closeTitle} onClick={handleClose}></IconButton>
      </DialogTitle>

      <DialogContent>
        <Box className={checkoutInfo}>
          <Typography>Checkout <span>#{checkoutId}</span></Typography>
        </Box>

        <Box className={checkoutInfoTotal}>
          <Typography className="title">Valor total:</Typography>
          <Typography className="total">{toMoney(creditRequestResume.valorTotal)}</Typography>
          <Divider style={{width: '35%', marginTop: '12px'}} className={divider}/>
        </Box>

        <Form onSubmit={handleSubmit(handleRequest)}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputFieldForm
                name="limiteSolicitado"
                required
                validate={[requiredAllTypes]}
                component={InputCurrencyRender}
                label="Valor Solicitado"
              />
            </Grid>

            <Grid item xs={6}>
              <SelectForm
                name="idCondicaoPagamento"
                label="Condição"
                required
                validate={[required]}
                model={{ id: 'creditId', name: 'nome' }}
                options={conditions || []}
                disabled={!conditions.length}
                loading={!conditions.length}
              />
            </Grid>
          </Grid>

          <Divider className={divider} />
          <Typography className={title}>Dados da Empresa:</Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputFieldForm
                name="documento"
                required
                validate={[required]}
                component={InputCnpjRender}
                label="CNPJ"
                readOnly
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <InputFieldForm
                name="razaoSocial"
                required
                validate={[required]}
                label="Razão Social"
                readOnly
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <InputFieldForm
                name="telefone"
                required
                validate={[required]}
                component={InputPhoneRender}
                label="Telefone"
                readOnly
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <InputFieldForm
                name="cep"
                required
                validate={[required]}
                component={InputCEPRender}
                label="CEP"
                readOnly
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <InputFieldForm
                name="logradouro"
                required
                validate={[required]}
                label="Rua / Avenida"
                readOnly
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputFieldForm
                    name="numero"
                    required
                    validate={[required]}
                    label="Número"
                    readOnly
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <CheckboxForm
                    name="semNumero"
                    className={checkboxNumber}
                    required
                    label="Sem Número"
                    color="primary"
                    readOnly
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <InputFieldForm
                name="complemento"
                label="Complemento"
                readOnly
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <InputFieldForm
                name="bairro"
                required
                validate={[required]}
                label="Bairro"
                readOnly
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <InputFieldForm
                name="cidade"
                required
                validate={[required]}
                label="Cidade"
                readOnly
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <InputFieldForm
                name="estado"
                required
                validate={[required]}
                label="Estado"
                readOnly
                disabled
              />
            </Grid>
          </Grid>

          <Divider className={divider}/>
          <Typography className={title}>Dados Complementares:</Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputFieldForm
                name="nomeClienteComprador"
                label="Comprador"
                validate={!semComprador ? [nomeComprador] : []}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <InputFieldForm
                name="documentoComprador"
                component={InputCpfRender}
                validate={!semComprador ? [cpfComprador] : []}
                disabled
                label="CPF do comprador"
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxForm
                name="semComprador"
                label="Deseja abrir a solicitação sem comprador?"
                color="primary"
                active
              />
            </Grid>
            <Grid item xs={6}>
              <InputFormFile
                name="fileOrdemDeCompra"
                required
                accept={allowedFilesExtension}
                onChange={(targetFile) => handleFiles(targetFile, 'fileOrdemDeCompra')}
                validate={[requiredAllTypes, max10Mb]}
                maxSize={max10Mb}
                label="Compra/OC"
                errorInfoButton={{
                  handle: openDialogFileError,
                  tooltip: 'Lista de arquivos permitidos',
                }}
                disabled={loadingCreditRequest}
              />
            </Grid>
            <Grid item xs={6}>
              <InputFormFile
                name="fileFichaCadastral"
                required
                accept={allowedFilesExtension}
                onChange={(targetFile) => handleFiles(targetFile, 'fileFichaCadastral')}
                validate={[requiredAllTypes,  max10Mb]}
                maxSize={max10Mb}
                label="Ficha Cadastral"
                errorInfoButton={{
                  handle: openDialogFileError,
                  tooltip: 'Lista de arquivos permitidos',
                }}
                disabled={loadingCreditRequest}
              />
            </Grid>
            <Grid item xs={6}>
              <InputFormFile
                name="fileDRE"
                required={limiteSolicitado > 20000}
                accept={allowedFilesExtension}
                validate={(limiteSolicitado > 20000 && [requiredAllTypes]) || [max10Mb]}
                maxSize={max10Mb}
                onChange={(targetFile) => handleFiles(targetFile, 'fileDRE')}
                label="DRE"
                readOnly
                errorInfoButton={{
                  handle: openDialogFileError,
                  tooltip: 'Lista de arquivos permitidos',
                }}
                disabled={loadingCreditRequest}
              />
            </Grid>
            <Grid item xs={6}>
              <InputFormFile
                name="fileBalanco"
                required={limiteSolicitado > 20000}
                accept={allowedFilesExtension}
                validate={(limiteSolicitado > 20000 && [requiredAllTypes]) || [max10Mb]}
                maxSize={max10Mb}
                onChange={(targetFile) => handleFiles(targetFile, 'fileBalanco')}
                label="Balanço"
                readOnly
                errorInfoButton={{
                  handle: openDialogFileError,
                  tooltip: 'Lista de arquivos permitidos',
                }}
                disabled={loadingCreditRequest}
              />
            </Grid>

            <Grid item xs={12}>
              <InputFieldForm
                name="informacaoAdicional"
                multiline
                label="Informações Adicionais"
                maxLength={1000}
              />
              <Box className={characterCounter}>({informacaoAdicional.length}/1000)</Box>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>

      <Divider className={divider}/>

      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
        >Cancelar</Button>
        <Tooltip title={buttonSendDisabled ? 'Comprador sem nome ou CPF cadastrado' : ''}>
          <span>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={loadingCreditRequest || buttonSendDisabled}
              onClick={handleRemoteSubmit}
            >Enviar Solicitação</Button>
          </span>
        </Tooltip>
      </DialogActions>

      <DialogFileError open={showDialogFileError} onClose={handleCloseDialogFileError} />
    </Dialog>
  );
}

const mapState = (state) => {
  const { snack } = state.main.app;

  const selector = formValueSelector('creditRequest/new');
  const limiteSolicitado = selector(state, 'limiteSolicitado');
  const semComprador = selector(state, 'semComprador');
  const idCondicaoPagamento = selector(state, 'idCondicaoPagamento');
  const informacaoAdicional = selector(state, 'informacaoAdicional');

  const { checkout } = state.main.checkout.geral;

  return {
    ...state.main.checkout.payment,
    checkoutId: checkout.checkoutId,
    snack,
    limiteSolicitado,
    idCondicaoPagamento,
    semComprador,
    informacaoAdicional,
  };
};

CreditRequestModal.defaultProps = {
  creditRequestResume: null,
};

CreditRequestModal.propTypes = {
  open: PropTypes.bool,
  creditRequestResume: PropTypes.instanceOf(Object),
  handleSubmit: PropTypes.func,
};

export default connect(mapState, {
  requestCreditRequest: requestCreditRequestAction
})(reduxForm({

  onSubmitFail: (errors, dispatch, submitError, props) => {
    const { snack } = props;

    snack.enqueueSnackbar("Verifique os campos obrigatórios.", { variant: 'info' });
    const firstElementError = Object.keys(errors).map(fieldName => `[name="${fieldName}"], label[for="${fieldName}"]`).join(',');

    if (firstElementError) document.querySelector(firstElementError).focus();
  },
  form: 'creditRequest/new',
  initialValues: {
    checkoutId: '',
    idVendedor: '',
    idCliente: '',
    idClienteComprador: '',
    telefoneCliente: '',
    documentoCliente: '',
    informacaoAdicional: '',
    limiteSolicitado: '',
    idCondicaoPagamento: '',
    condicaoPagamento: '',
    semComprador: false,
    fileOrdemDeCompra: '',
    fileFichaCadastral: '',
    fileDRE: '',
    fileBalanco: '',
  },
})(CreditRequestModal));
