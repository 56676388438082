/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
import React, {
  useEffect, useState, useCallback,
} from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  Slide,
  Popover,
  Modal,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Select from 'react-select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import { pt } from 'date-fns/locale';
import DetailsCoordenador from './DetailsCoordenador';
import CardBarNew from '../../../../shared/components/cardBarNew';
import ResponsiveTable from '../../../../shared/components/responsiveTable';
import apiService from '../../../../shared/services/apiService';
import {
  Canal, Valor, colourStyles, Status, Tipo
} from './utilsRecovery';
import useStyles from '../styles';
import {
  getRelatorioCoordenadorAction,
  getDetailsRelatorioCoordenadorAction,
  getVendedoresAction,
  setCheckoutMetaAction,
  clearMetaAction,
  takeCheckoutAction, openFilterAction, closeFilterAction,
} from '../actions';
import { toMoney } from '../../../../shared/utils/parsers';

const Coordenador = ({
  loading, relatorio, getRelatorio, setCheckoutMeta, getDetailsRelatorio,
  totalpages, getVendedores, vendedores, rpp, user,
}) => {
  const classes = useStyles();
  const [dialog, setDialog] = useState(false);
  const [checked, setChecked] = useState(false);
  const [filterVendedor, setFilterVendedor] = useState(null);
  const [nomeVendedor, setNomeVendedor] = useState('');
  const [filterDataInicio, setFilterDataInicio] = useState(moment(new Date()).format('MM/DD/YYYY'));
  const [filterDataFim, setFilterDataFim] = useState(moment(new Date()).format('MM/DD/YYYY'));
  const [dateInicio, setDateInicio] = useState('');
  const [dateFim, setDateFim] = useState('');
  const [filterValor, setFilterValor] = useState('');
  const [filterValorFim, setFilterValorFim] = useState('');
  const [filterCanal, setFilterCanal] = useState('');
  const [filterTipo, setFilterTipo] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [dateOpen, setDateOpen] = useState(null);
  const [page, setPage] = useState(1);
  const [arrayVendedor, setArrayVendedor] = useState([]);
  const [categoriaVendedor, setCategoriaVendedor] = useState([]);
  const [canalVendas, setCanalVendas] = useState([]);
  const [tipo, setTipo] = useState([]);
//let arrayVendedor = [];
  // arrayVendedor.push({ value: null, label: 'Todos' });
  /* vendedores.forEach((item) => {
    const { idVendedor: value, nome: label } = item;
    arrayVendedor.push({ value, label });
  });*/
  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const colorStatus = useCallback((item) => {
    switch (item) {
      case 'RECUPERADO':
        return { backgroundColor: '#80EFA2', color: '#007023' };
      case 'CANCELADO':
        return { backgroundColor: '#FE9999', color: '#7E0000' };
      case 'NA FILA':
        return { backgroundColor: '#FFF6C2', color: '#403B1E' };
      case 'AGENDADO':
        return { backgroundColor: '#a6dced', color: '#0963ac' };
      default:
        return {};
    }
  }, []);



  useEffect(() => {
    setFilterDataInicio(moment(date[0].startDate).format('MM/DD/YYYY'));
    setFilterDataFim(moment(date[0].endDate).format('MM/DD/YYYY'));
    setDateInicio(moment(date[0].startDate).format('DD/MM/YYYY'));
    setDateFim(moment(date[0].endDate).format('DD/MM/YYYY'));
  }, [date]);

  const getDadosSelects = async () => {
    try {
      const data = await apiService.get(`/vendedores/recuperacao/desempenho/${user.idVendedor}`);
      //const data = await apiService.get(`https://mocki.io/v1/83de09f8-e27c-479e-b350-b6760b3d8576`);
      if (data.status) {
          arrayVendedor.push({ value: '', label: 'Todos' });
          data.data.results.vendedores.forEach((item) => {
            const { idVendedor: value, nome: label } = item;
            arrayVendedor.push({ value, label });
          });
          categoriaVendedor.push({ value: '', label: 'Todos' });
          data.data.results.categoriasVendedor.forEach((item) => {
            const { id: value, nomeCategoria: label } = item;
            categoriaVendedor.push({ value, label });
          });

          canalVendas.push({ value: '', label: 'Todos' });
          data.data.results.canaisVenda.forEach((item) => {
            const { idCanalVendas: value, descricao: label } = item;
            canalVendas.push({ value, label });
          });
      }
    } catch (error) {
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
  };



  useEffect(() => {
    setChecked(true);
  }, []);

  const handleRequest = async () => {
    await getRelatorio(rpp, filterDataInicio, filterDataFim,
      filterVendedor, filterStatus, filterCanal, filterValor, page,
      filterTipo);
  };

  useEffect(() => {
    handleRequest();
  }, [page]);

  const handleToggle = (checkoutId) => {
    setDialog(!dialog);
    if (!dialog) {
      getDetailsRelatorio(checkoutId);
    }
  };

  useEffect(() => {
    getVendedores();
    getDadosSelects();
  }, []);

  return (
    <div className={classes.divRow}>
      <div style={{ width: '100%' }}>
        <CardBarNew title="Home" />
        {loading && (<LinearProgress />)}
        <Paper elevation={1} className={classes.paperFilter}>
          <Typography>Filtrar por:</Typography>
          <Grid item spacing={1} container xs={12}>
            <Grid item xs={3}>
              <div>
                <div
                  className={classes.inputFakeDate}
                  onClick={(e) => setDateOpen(e.currentTarget)}
                >
                  <div>
                    <span>
                      {dateInicio}
                      {' '}
                    </span>
                    <span>- </span>
                    <span>{dateFim}</span>
                  </div>
                  <ArrowDropDownIcon style={{ marginLeft: 30 }} />
                </div>
                <Popover
                  open={Boolean(dateOpen)}
                  anchorEl={dateOpen}
                  onClose={() => setDateOpen(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <DateRange
                    months={1}
                    minDate={addDays(date[0].startDate, -45)}
                    maxDate={addDays(date[0].startDate, 45)}
                    onChange={(item) => setDate([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={date}
                    rangeColors={['#FF5225']}
                    showMonthAndYearPickers={false}
                    locale={pt}
                  />
                </Popover>
              </div>

            </Grid>
            <Grid item xs={3}>
              <div style={{ height: 30 }}>
                <Select
                  styles={colourStyles}
                  options={arrayVendedor}
                  placeholder="Vendedor"
                  onChange={(e) => setFilterVendedor(e.value)}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <Select
                styles={colourStyles}
                placeholder="Categoria do Vendedor"
                options={categoriaVendedor}
                onChange={(e) => { setFilterValor(e.value)}}
              />
            </Grid>
          </Grid>
          <Grid item spacing={1} container xs={12}>
            <Grid item xs={3}>
              <Select
                styles={colourStyles}
                placeholder="Canal de Vendas"
                options={canalVendas}
                onChange={(e) => setFilterCanal(e.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                styles={colourStyles}
                placeholder="Tipo"
                options={Tipo}
                onChange={(e) => setFilterTipo(e.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                styles={colourStyles}
                placeholder="Status"
                options={Status}
                onChange={(e) => setFilterStatus(e.value)}
              />
            </Grid>
            <Grid>
              <Button
                disableElevation
                variant="contained"
                className={classes.buttonFiltro}
                onClick={handleRequest}
              >
                Aplicar Filtro
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <section style={{ marginLeft: 7, marginTop: 15, width: '98%' }}>
          <Paper elevation={1}>
            <ResponsiveTable pointer>
              <TableHead>
                <TableRow>
                  <TableCell component="th" align="left" className={classes.textsSilver}>
                    Orçamento
                  </TableCell>
                  <TableCell component="th" className={classes.textsSilver}>
                    Vendedor
                  </TableCell>
                  <TableCell component="th" className={classes.textsSilver}>
                    Valor
                  </TableCell>

                  <TableCell component="th" className={classes.textsSilver}>
                    Data Atualização
                  </TableCell>
                  <TableCell component="th" className={classes.textsSilver}>
                    Canal
                  </TableCell>
                  <TableCell component="th" className={classes.textsSilver}>
                    Status
                  </TableCell>
                  {/* <TableCell component="th" className={classes.textsSilver}>
                    Ação
                  </TableCell> */}
                </TableRow>
              </TableHead>

              <TableBody>
                {relatorio.orcamentos && relatorio.orcamentos.map((item) => (
                  <TableRow
                    key={item.checkoutId}
                    onClick={({ currentTarget }) => setCheckoutMeta({
                      el: currentTarget,
                      checkoutId: item.checkoutId,
                    })}
                  >
                    {/* <TableCell datatype="Grupo" align="left" className={classes.textBodyCoordenadorGrupo}>
                      {item.grupo}
                    </TableCell> */}

                    <TableCell datatype="Grupo" align="left" className={classes.textBodyCoordenadorGrupo}>
                      {item.checkoutId}
                    </TableCell>

                    <TableCell datatype="Vendedor" style={{ textTransform: 'capitalize' }} className={classes.textBodyCoordenador}>
                      {item.vendedor}
                    </TableCell>

                    <TableCell datatype="Valor" className={classes.textBodyCoordenador}>
                      {toMoney(item.valor)}
                    </TableCell>

                    <TableCell datatype="Data Atualização" className={classes.textBodyCoordenador}>
                      {item.data}
                    </TableCell>

                    <TableCell datatype="Canal" className={classes.textBodyCoordenador}>
                      {item.canal}
                    </TableCell>

                    <TableCell datatype="Status" className={classes.textBodyCoordenador}>
                      <Box className={classes.labelStatusNovo} style={colorStatus(item.status)}>
                        {item.status}
                      </Box>
                    </TableCell>

                   {/* <TableCell datatype="Ação">
                      <Button
                        disableElevation
                        disabled={false}
                        onClick={() => {
                          handleToggle(item.checkoutId);
                          setNomeVendedor(item.vendedor);
                        }}
                        className={classes.buttonDetails}
                        variant="contained"
                        startIcon={<VisibilityIcon style={{ color: '#000', fontSize: 14 }} />}
                      >
                        Detalhes
                      </Button>

                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </ResponsiveTable>
          </Paper>
          <div className={classes.pagination}>
            <Pagination
              count={totalpages}

              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        </section>

        {dialog
        && (
        <Modal
          open={dialog}
          onClose={handleToggle}
          className={classes.modal}
        >
          <DetailsCoordenador dialog={dialog} setDialog={setDialog} nomeVendedor={nomeVendedor} />
        </Modal>
        )}

      </div>
      <Slide direction="left" in={checked} mountOnEnter timeout={1500}>
        <div
          className={relatorio.carrinhosAbandonados === 0
            ? classes.divRight : classes.divRightSelect}
        >
          <Typography className={classes.textdivRight}>Carrinhos Recuperados</Typography>
          <Paper className={classes.paperRight}>

            <div className={classes.greenPaperRight}>
              <span
                className={classes.valueTopGreenPaper}
                style={toMoney(relatorio.ganhoRecuperado) > '9.999.999.00' ? { fontSize: 24 } : { fontSize: 30 }}
              >
                {toMoney(relatorio.ganhoRecuperado)}

              </span>
              <span className={classes.valueGreenPaper}>Ganho Recuperado</span>
            </div>

            <div
              className={classes.divTextsPaperRight}
              style={{ borderBottomRightRadius: 8, borderBottomLeftRadius: 8 }}
            >
              <span className={classes.textPaper}>Ticket Médio Geral</span>

              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {toMoney(relatorio.mediaValorPedidos)}
              </span>
            </div>

            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Valor Total</span>
              <span
                className={classes.textPaper}
                style={{ color: '#CA0800' }}
              >
                {toMoney(relatorio.valorRecuperadosPagos)}

              </span>
            </div>

            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Quantidade Geral</span>
              <span
                className={classes.textPaper}
                style={{ color: '#00B34C' }}
              >
                {relatorio.qtdeRegistros}

              </span>
            </div>

            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Taxa de Conversão</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {`${relatorio.taxaConversao === undefined ? '0' : relatorio.taxaConversao}%`}

              </span>
            </div>


            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Declinados (Qtde)</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {`${relatorio.qtdeDeclinados === undefined ? '0' : relatorio.qtdeDeclinados}`}

              </span>
            </div>

            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Declinados (Valor)</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {toMoney(`${relatorio.valorDeclinados === undefined ? '0' : relatorio.valorDeclinados}`)}

              </span>
            </div>

            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Declinados (%)</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {`${relatorio.porcentagemPedidosDeclinados === undefined ? '0' : relatorio.porcentagemPedidosDeclinados}%`}

              </span>
            </div>

            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Rec. Pagos (Qtde)</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {`${relatorio.qtdeRecuperadosPagos === undefined ? '0' : relatorio.qtdeRecuperadosPagos}`}

              </span>
            </div>

            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Rec. Pagos (Valor)</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {toMoney(`${relatorio.valorRecuperadosPagos === undefined ? '0' : relatorio.valorRecuperadosPagos}`)}

              </span>
            </div>

            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Rec. Pagos (%)</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {`${relatorio.porcentagemRecuperadosPagos === undefined ? '0' : relatorio.porcentagemRecuperadosPagos}%`}

              </span>
            </div>

            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Rec. Aguard. Pag. (Qtde)</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {`${relatorio.qtdeAguardandoPagamento === undefined ? '0' : relatorio.qtdeAguardandoPagamento}`}

              </span>
            </div>

            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Rec. Aguard. Pag.  (Valor)</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {toMoney(`${relatorio.valorAguardandoPgto === undefined ? '0' : relatorio.valorAguardandoPgto}`)}

              </span>
            </div>

            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Rec. Aguard. Pag.  (%)</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {`${relatorio.porcentagemAguardandoPagamento === undefined ? '0' : relatorio.porcentagemAguardandoPagamento}%`}

              </span>
            </div>

            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Qtde na Fila</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {`${relatorio.qtdeNaFila === undefined ? '0' : relatorio.qtdeNaFila}`}

              </span>
            </div>


            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Valor na Fila</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {toMoney(`${relatorio.valorNaFila === undefined ? '0' : relatorio.valorNaFila}`)}

              </span>
            </div>

            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Porc. na Fila (%)</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {`${relatorio.porcentagemNaFila === undefined ? '0' : relatorio.porcentagemNaFila}%`}

              </span>
            </div>










            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Qtde não finaliz.</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {`${relatorio.qtdeNaoFinalizado === undefined ? '0' : relatorio.qtdeNaoFinalizado}`}

              </span>
            </div>


            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Valor não finaliz.</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {toMoney(`${relatorio.valorNaoFinalizado === undefined ? '0' : relatorio.valorNaoFinalizado}`)}

              </span>
            </div>

            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Porc. não finaliz. (%)</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {`${relatorio.porcentagemNaoFinalizado === undefined ? '0' : relatorio.porcentagemNaoFinalizado}%`}

              </span>
            </div>




            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Qtde cancelados</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {`${relatorio.qdteCancelados === undefined ? '0' : relatorio.qdteCancelados}`}

              </span>
            </div>


            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Valor cancelados</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {toMoney(`${relatorio.valorCancelados === undefined ? '0' : relatorio.valorCancelados}`)}

              </span>
            </div>

            <div className={classes.divTextsPaperRight}>
              <span className={classes.textPaper}>Porc. cancelados (%)</span>
              <span
                className={classes.textPaper}
                style={{ color: '#015DCA' }}
              >
                {`${relatorio.porcentagemCancelados === undefined ? '0' : relatorio.porcentagemCancelados}%`}

              </span>
            </div>


          </Paper>
        </div>
      </Slide>

    </div>
  );
};

Coordenador.propTypes = {
  loading: PropTypes.bool.isRequired,
  relatorio: PropTypes.instanceOf(Object).isRequired,
  getRelatorio: PropTypes.func.isRequired,
  rpp: PropTypes.number.isRequired,
  totalpages: PropTypes.number.isRequired,
  setCheckoutMeta: PropTypes.func.isRequired,
  getDetailsRelatorio: PropTypes.func.isRequired,
  getVendedores: PropTypes.func.isRequired,
  vendedores: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

const mapState = (state) => {
  const { snack } = state.main.app;
  return ({
    ...state.main.app,
    ...state.main.recovery.list,
    user: state.auth.login.user,
  });
};
export default connect(mapState, {
  getRelatorio: getRelatorioCoordenadorAction,
  getDetailsRelatorio: getDetailsRelatorioCoordenadorAction,
  getVendedores: getVendedoresAction,
  setCheckoutMeta: setCheckoutMetaAction,
  clearMeta: clearMetaAction,
  takeCheckout: takeCheckoutAction,
  openFilter: openFilterAction,
  closeFilter: closeFilterAction,
})(Coordenador);
