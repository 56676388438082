import { makeStyles } from '@material-ui/core';
import { FolhasIcon } from '../../../../assets';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    overflowX: 'hidden',
    overflowY: 'auto',
    '& > div': {
      overflowY: 'unset',
      overflowX: 'unset',
    },
    '&:hover': {
      '&::-webkit-scrollbar': {
        width: 8,
      },
    },
    '&::-webkit-scrollbar-track, & ::-webkit-scrollbar-track': {
      backgroundColor: palette.background.level4,
      borderRadius: 12,
    },
    '&::-webkit-scrollbar, & ::-webkit-scrollbar': {
      width: 6,
      height: 6,
      background: palette.background.level4,
      borderRadius: '0 6px 6px 0',
    },
    '&::-webkit-scrollbar-thumb, & ::-webkit-scrollbar-thumb': {
      background: '#b3b1b1',
      borderRadius: 12,
    },
  },
  productText: {
    fontSize: 12,
    color: '#707070',
  },
  pricesContainer: {
    display: 'flex',
    width: '50%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
  },
  groupPrice: {
    minWidth: 114,
    [breakpoints.down('xs')]: {
      width: '100%',
      padding: '8px 0',
    },
  },
  oldPrice: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  currentPrice: {
    fontSize: 14,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textAlign: 'center',
  },
  chip: {
    padding: '0 8px',
    borderRadius: 10,
  },
  secondary: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  errorChip: {
    backgroundColor: palette.error.main,
    color: 'white',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  upload: {
    display: 'block',
    height: '30px',
    width: '248px',
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    opacity: '0',
    cursor: 'pointer',
  },
  fileUpload: {
    textAlign: 'center',
    border: '1px dashed #2296F3',
    position: 'relative',
    cursor: 'pointer',
    width: '248px',
    height: '30px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EAF5FD',
  },
  buttonRemoveFile: {
    background: 'transparent',
    border: 'none',
    height: '15px',
    width: '15px',
    position: 'absolute',
    marginLeft: '224px',
    marginTop: '-23px',
  },
  boxContainer : {
    background: '#F6F6F7',
    borderRadius: '8px',
    border: '1px solid #CFCFD4',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  boxContainerSelected : {
    background: '#EBFAEF',
    border: '1px solid #21AD34',
  },
  typographyFreightPrimary: {
    fontWeight: 'bold',
    fontSize: '1rem'
  },
  typographyFreightSecondary: {
    fontWeight: '500',
    fontSize: '14px'
  },
  customInfo: {
    background: '#21AD34',
    height: 32,
    gap: 4,
    display: 'inline-flex',
    borderRadius: 4,
    padding: '4px 16px',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: '18px',
    color: '#FFFFFF !important',
    fontWeight: 600,
    justifyContent: 'center',
    marginTop: 6,
    '& .produtor': {
      background: `url(${FolhasIcon})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: 18,
      height: 18,
    }
  },
  labelField: {
    fontSize: 14,
    fontWeight: 600,
    color: '#7B7F82',
    marginBottom: 6,
  },
  dialogInfoAditionalContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogInfoAditionalField: {
    '& .MuiOutlinedInput-inputMultiline': {
      height: '100px!important'
    },
  },
  dialogTitleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .MuiIconButton-sizeSmall': {
      color: '#242424',
    },
  },
  dialogTitleTypography: {
    fontSize: 20,
    fontWeight: 600,
  },
  dialogPaper: {
    borderRadius: 16,
  },
  divider: {
    paddingBottom: 16,
  },
  dialogInfoActionsButton: {
    height: 48,
    textTransform: 'none',
  },
  buttonInfoAditional: {
    '&&': {
      padding: 0,
      margin: 0
    },
    textTransform: 'none',
    textDecoration: 'underline',
    fontWeight: 600,
    fontSize: 14,
    padding: 0,
    margin: 0
  },
  buttonInfo: {
    '&&': {
      padding: 0,
      margin: 0
    },
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 14,
    padding: 0,
    margin: 0
  },
  customerInfoBoldText: {
    fontSize: 14,
    display: 'inline',
    fontWeight: 600
  },
  scrollInfo: {
    overflow: 'auto',
    maxHeight: '60px',
    display: 'flex',
    lineBreak: 'anywhere'
  }
}));

export default useStyles;
