import React, { useMemo } from 'react';
import {
  Box, Chip, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ResponsiveTable from '../../../../shared/components/responsiveTable';
import { toMoney } from '../../../../shared/utils/parsers';
import useStyles from './styles';

const ConfirmPayment = ({
  checkout: { deniedPayments, payments },
}) => {
  const classes = useStyles();
  const { sumTotal } = useMemo(() => payments.reduce((accuml, payment) => ({
    sumTotal: payment.total + accuml.sumTotal,
    sumDiscount: payment.discount + accuml.sumDiscount,
  }), { sumTotal: 0, sumDiscount: 0 }), [payments]);

  return (
    <ResponsiveTable striped>
      <TableHead>
        <TableRow
          classes={{
            root: classes.oddRow,
          }}
        >
          <TableCell component="th">
            Forma de Pagamento
          </TableCell>
          <TableCell component="th" align="right">
            Total
          </TableCell>
          <TableCell component="th" align="right">
            Quantidade de Parcelas
          </TableCell>
          <TableCell component="th" align="right">
            Valor da Parcela
          </TableCell>

        </TableRow>
      </TableHead>

      <TableBody>
        {deniedPayments.map((item, index) => (
          <TableRow
            key={`row-${index + 1}`}
          >
            <TableCell datatype="Forma de Pagamento">
              <Chip
                label={`${item.type} ${item.braspagReturn || 'Reprovado'}`}
                className={classes.errorChip}
              />
            </TableCell>

            <TableCell datatype="Total" align="right" className={classes.nowrap}>
              <Box whiteSpace="nowrap">
                {toMoney(item.total)}
              </Box>
            </TableCell>

            <TableCell datatype="Quantidade de Parcelas" align="right">
              {item.installmentQuantity}
            </TableCell>

            <TableCell datatype="Quantidade de Parcelas" align="right">
              <Box whiteSpace="nowrap">
                {toMoney(item.installmentValue)}
              </Box>
            </TableCell>
          </TableRow>
        ))}

        {payments.map((item, index) => (
          <TableRow
            key={`row-${index + 1}`}
          >
            <TableCell datatype="Forma de Pagamento">
              <Chip label={item.type} />
            </TableCell>

            <TableCell datatype="Total" align="right">
              <Box whiteSpace="nowrap">
                {toMoney(item.total)}
              </Box>
            </TableCell>

            <TableCell datatype="Quantidade de Parcelas" align="right">
              {item.installmentQuantity}
            </TableCell>

            <TableCell datatype="Quantidade de Parcelas" align="right">
              <Box whiteSpace="nowrap">
                {toMoney(item.installmentValue)}
              </Box>
            </TableCell>
          </TableRow>
        ))}

        {payments.length > 1 && (
        <TableRow>
          <TableCell component="th">
            <Box fontWeight={500}>
              Total
            </Box>
          </TableCell>
          <TableCell component="th" align="right">
            <Box whiteSpace="nowrap" fontWeight={500}>
              {toMoney(sumTotal)}
            </Box>
          </TableCell>
          <TableCell component="th" align="right" />
          <TableCell component="th" align="right" />
        </TableRow>
        )}
      </TableBody>
    </ResponsiveTable>
  );
};

ConfirmPayment.propTypes = {
  checkout: PropTypes.instanceOf(Object).isRequired,
};

export default ConfirmPayment;
