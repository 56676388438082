import React, { useState } from 'react';
import {
  Box,
  Grid,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';
import {
  reset,
  reduxForm,
  change,
} from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import SearchIcon from '@material-ui/icons/Search';
import { ReactComponent as TrashIcon } from '../../../../../../assets/algolia/trash-icon.svg';
import {
  InputFieldForm,
} from '../../../../../../shared/fields';
import SelectForm from '../../../../../../shared/fields/v2/selectForm/SelectForm';
import useStyles from './style';

const FilterOrders = ({
  open,
  loading,
  onSubmit,
  handleFilter,
  handleSubmit,
}) => {
  const classes = useStyles();

  const {
    wrapper,
    wrapperOpened,
    filter,
    searchButton,
    actionsFilter,
    buttonCleanFilter,
  } = classes;
  const dispatch = useDispatch();

  const handleRequest = () => {
    handleFilter();
  };

  const [diasSelected, setDiasSelected] = useState(90);

  const handleChangeDias = (event) => {
    const { value } = event.target;
    setDiasSelected(value);
  };

  const handleClearRequest = () => {
    setDiasSelected(90);
    dispatch(reset('orders/filter'));
    dispatch(change('orders/filter', 'dias', 90));
    handleRequest();
  };

  return (
    <div
      className={`${wrapper} ${open ? wrapperOpened : null}`}
    >
      <form onSubmit={handleSubmit(() => onSubmit({ page: 1 }))}>
        <Box p={2}>
          <Grid className={filter} container spacing={2}>
            <Grid item xs={3}>
              <InputFieldForm
                name="codPedido"
                minLength={6}
                maxLength={100}
                label="N° Pedido"
              />
            </Grid>

            <Grid item xs={5}>
              <SelectForm
                onChange={handleChangeDias}
                name="dias"
                valueSelected={diasSelected}
                label="Período"
                options={[
                  {
                    id: 90,
                    label: 'Últimos 3 meses',
                  },
                  {
                    id: 180,
                    label: 'Últimos 6 meses',
                  },
                  {
                    id: 365,
                    label: 'Últimos 12 meses',
                  },
                ]}
              />
            </Grid>

            <Grid className={actionsFilter} item xs={4}>
              <IconButton
                type="submit"
                className={searchButton}
                onClick={() => handleRequest()}
                disabled={loading}
              >
                <SearchIcon />
              </IconButton>
              <Button
                fullWidth
                onClick={() => handleClearRequest()}
                className={buttonCleanFilter}
                disabled={loading}
              >
                <TrashIcon />
                <Typography>Limpar Filtro</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );
};

FilterOrders.defaultProps = {
  loading: false,
};

FilterOrders.propTypes = {
  page: PropTypes.string,
  open: PropTypes.bool.isRequired,
  isCheckout: PropTypes.bool,
  loading: PropTypes.bool,
  handleFilter: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setFilterDataInicio: PropTypes.func,
  setFilterDataFim: PropTypes.func,
};

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {})(reduxForm({
  form: 'orders/filter',
  destroyOnUnmount: false,
  enableReinitialize: true,
  initialValues: {
    dias: 90,
  },
})(FilterOrders));
