import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, Typography, Button, DialogActions,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { hideInvalidDialogAction } from '../../actions';
import { push } from '../../../../history';

const InvalidDialog = ({
  invalidDialog, hideInvalidDialog, client,
}) => {
  const redirectClientSpace = () => {
    if (client.tipoPessoa === 'J') {
      push('/client-space/addresses/new');
    } else {
      push('/client-space/registration-data');
    }
  };

  const redirectRegisterAddress = () => {
    push('/client-space/addresses/new');
  };

  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth
      onClose={hideInvalidDialog}
    >
      <DialogTitle id="title">
        Aviso
      </DialogTitle>

      <DialogContent>
        <Typography variant="body1">
          Encontramos algumas inconsistencias no cadastro do cliente.
        </Typography>
      </DialogContent>

      {invalidDialog.address && (
        <>
          <DialogContent>
            <Typography variant="subtitle2">
              Endereço Inválido:
            </Typography>

            <Typography variant="body2">
              É necessário cadastrar um endereço válido para o cliente. Não será possível
              calcular o frete, nem inserir pagamentos sem essas informações.
            </Typography>
          </DialogContent>

          {
            !invalidDialog.document && (
              <DialogActions>
                <Button onClick={redirectRegisterAddress}>Inserir Endereço</Button>
              </DialogActions>
            )
          }
        </>
      )}

      {invalidDialog.document && (
        <>
          <DialogContent>
            <Typography variant="subtitle2">
              Sem documento:
            </Typography>

            <Typography variant="body2">
              É necessário informar um CPF/CNPJ para prosseguir. Você pode cadastrar um
              CPF na seção: Espaço do Cliente, esse documento será vinculado ao cliente caso
              ele não possua um ainda. Não será possível inserir pagamentos sem documento.
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button onClick={redirectClientSpace}>Inserir Documento</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

InvalidDialog.propTypes = {
  invalidDialog: PropTypes.instanceOf(Object).isRequired,
  hideInvalidDialog: PropTypes.func.isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
};

const mapState = (state) => {
  const { client } = state.authClient;

  return ({
    ...state.main.checkout.geral,
    client,
  });
};

export default connect(mapState, {
  hideInvalidDialog: hideInvalidDialogAction,
})(InvalidDialog);
