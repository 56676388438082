import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Box, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { closeDialogPaymentAction } from './actions';
import AdvancedPaymentForm from './AdvancedPaymentForm';
import { getConditionsAction } from '../actions';
import ItemAdvancedPaymentDialog from './ItemAdvancedPaymentDialog';
import { toMoney } from '../../../../../shared/utils/parsers';
import useStyles from './styles';

const DialogAdvancedPayment = ({
  closeDialogPayment, dialogOpened, getConditions, conditions, resume, checkout,
}) => {
  const classes = useStyles();
  useEffect(() => {
    if (!conditions && dialogOpened) {
      getConditions();
    }
  }, [getConditions, conditions]);

  return (

    <Dialog
      open={dialogOpened}
      onClose={closeDialogPayment}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box position="relative">
        <Box display="flex" flexDirection="row-reverse" justifyContent="space-between" alignItems="center" padding="0px 24px">
          <IconButton onClick={closeDialogPayment} className={classes.closeBtn}>
            <Close />
          </IconButton>

          <DialogTitle classes={{ root: classes.dialogTitle }} id="alert-dialog-title">Forma de Pagamento</DialogTitle>
        </Box>

        <div>
          <ItemAdvancedPaymentDialog
            value={`${toMoney(resume.subTotalVista)}`}
            label="Total"
            valueInstallment={`${toMoney(resume.subTotalPrazo)}`}
            valueRemainder={`${toMoney(resume.remainder)}`}
            valueInstallmentRemainder={`${toMoney(resume.remainderInstallment)}`}
            frete={`${toMoney(resume.freight)}`}
            valuetotal={`${toMoney(resume.total)}`}
            valuetotalinstallment={`${toMoney(resume.totalInstallment)}`}
            discountCart={`${toMoney(resume.maxDiscount)}`}
          />
        </div>

        <DialogContent>
          <AdvancedPaymentForm checkout={checkout} closeDialogPayment={closeDialogPayment} />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

DialogAdvancedPayment.propTypes = {
  closeDialogPayment: PropTypes.func.isRequired,
  getConditions: PropTypes.func.isRequired,
  dialogOpened: PropTypes.bool.isRequired,
  conditions: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  resume: PropTypes.instanceOf(Object).isRequired,
  checkout: PropTypes.instanceOf(Object).isRequired,
};

DialogAdvancedPayment.defaultProps = {
  conditions: null,
};

const mapState = (state) => {
  const {
    checkoutId, resume, payments, deniedPayments, orderId,
  } = state.main.checkout.geral.checkout;
  const { chosedFreight } = state.main.checkout.geral;

  return {
    ...state.main.checkout.advanced,
    payments,
    deniedPayments,
    resume,
    checkoutId,
    orderId,
    chosedFreight,
    conditions: state.main.checkout.payment.conditions,
  };
};

export default connect(mapState, {
  closeDialogPayment: closeDialogPaymentAction,
  getConditions: getConditionsAction,
})(DialogAdvancedPayment);
