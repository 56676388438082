// eslint-disable-next-line import/no-cycle
import { getFormValues } from 'redux-form';
import axios from 'axios';
import Types from './types';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import apiService from '../../../../shared/services/apiService';
import resolveError from '../../../../shared/utils/resolveError';
import { goBack, push } from '../../../../history';
import { getClient, setClient } from '../../../../shared/services/authClient';
const normalizer = (string) => (string?.trim() === "" ? "" : string?.trim().replace(/  +/g, ''));
const sanitize = (string) => (string?.replace(/[^\p{L}\p{N}\n ]/gu, ''));

// --- Individual Person ---
const getAddressesClientPFAction = (codCliente) => async (dispatch, getState) => {
  dispatch({ type: Types.GET_ADDRESSES_CLIENT_REQUEST });

  try {
    const client = getClient();
    const {
      data: {
        content,
      },
    } = await apiService.get(`/clientes/listar-enderecos-cliente/${codCliente}`);

    if (!content.length) {
      push('/client-space/addresses/new');
    }

    const address = content.find((item) => item.bitPrincipal);
    if (address) {
      setClient({
        ...client,
        logradouro: address.logradouro,
        cidade: address.cidade,
        bairro: address.bairro,
        numero: address.numero,
        uf: address.uf,
        confirmAddress: true,
      });
    }

    dispatch({
      type: Types.GET_ADDRESSES_CLIENT_SUCCESS,
      payload: { addresses: content },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.GET_ADDRESSES_CLIENT_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const putMainAddressPFAction = (idEndereco, address, client) => async (dispatch, getState) => {
  dispatch({ type: Types.PUT_MAIN_ADDRESS_REQUEST });

  try {
    await apiService.put(`/clientes/endereco-principal-cliente/${idEndereco}`);

    setClient({
      ...client,
      valeTotal: client?.valeTotal || address?.valeTotal,
      logradouro: address.logradouro,
      cidade: address.cidade,
      bairro: address.bairro,
      numero: address.numero,
      uf: address.uf,
      confirmAddress: true,
    });

    dispatch({ type: Types.PUT_MAIN_ADDRESS_SUCCESS });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.PUT_MAIN_ADDRESS_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const handleInactiveAddressPFAction = (idEndereco) => async (dispatch, getState) => {
  dispatch({ type: Types.PUT_INACTIVE_ADDRESS_REQUEST });

  try {
    await apiService.put(`/clientes/ativar-inativar-endereco/${idEndereco}`);
    dispatch({ type: Types.PUT_INACTIVE_ADDRESS_SUCCESS });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.PUT_INACTIVE_ADDRESS_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const getAddressClientPFAction = (idEndereco) => async (dispatch, getState) => {
  dispatch({ type: Types.GET_ADDRESS_CLIENT_REQUEST });

  try {
    const {
      data: {
        content,
      },
    } = await apiService.get(`/clientes/endereco-cliente/${idEndereco}`);

    dispatch({
      type: Types.GET_ADDRESS_CLIENT_SUCCESS,
      payload: { address: content },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.GET_ADDRESS_CLIENT_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const putAddressClientPFAction = () => async (dispatch, getState) => {
  dispatch({ type: Types.PUT_ADDRESS_CLIENT_REQUEST });

  try {
    const { snack } = getState().main.app;
    const form = getFormValues('client/address/edit')(getState());
    const idEndereco = form.id;

    const {
      data: {
        message,
      },
    } = await apiService.put(`/clientes/endereco-cliente/${idEndereco}`, {
      ...form,
      responsavel: sanitize(normalizer(form.responsavel)),
      complemento: normalizer(form.complemento),
      razaoSocial: sanitize(normalizer(form.razaoSocial)),
      numero: normalizer(form.numero),
      logradouro: sanitize(normalizer(form.logradouro)),
      bairro: sanitize(normalizer(form.bairro)),
      informacoesAdicionais: normalizer(form.informacoesAdicionais)
    });

    if (message) {
      snack.enqueueSnackbar(message, { variant: 'success' });
      dispatch({ type: Types.PUT_ADDRESS_CLIENT_SUCCESS });

      goBack();
    }
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.PUT_ADDRESS_CLIENT_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const registerNewAddressClientPFAction = () => async (dispatch, getState) => {
  dispatch({ type: Types.POST_ADDRESS_CLIENT_REQUEST });

  try {
    const { snack } = getState().main.app;
    const client = getClient();
    const form = getFormValues('client/address/new')(getState());

    const {
      data: {
        message,
        content,
      },
    } = await apiService.post('/clientes/novo-endereco/', {
      ...form, codCliente: client.codCliente,
    });

    setClient({
      ...client,
      logradouro: content.logradouro,
      cidade: content.cidade,
      bairro: content.bairro,
      numero: content.numero,
      uf: content.estado,
      confirmAddress: true,
      qtyProducts: 0,
    });

    if (message) {
      snack.enqueueSnackbar(message, { variant: 'success' });
      dispatch({ type: Types.POST_ADDRESS_CLIENT_SUCCESS });

      goBack();
    }
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.POST_ADDRESS_CLIENT_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

// --- Corporate Person ---
const getAddressesClientPJAction = (codMatriz, client) => async (dispatch, getState) => {
  dispatch({ type: Types.GET_ADDRESSES_CLIENT_REQUEST });

  try {
    const {
      data: {
        content,
      },
    } = await apiService.get(`/clientes/filiais/listar-filiais/${codMatriz}`);

    if (!content.length) {
      push('/client-space/addresses/new');
    }

    const address = content.find((item) => item.bitPrincipal);

    if (address) {
      setClient({
        ...client,
        codClienteMatriz: client.codClienteMatriz || client.codCliente,
        bitPrincipal: address.bitPrincipal,
        bitInativo: address.bitInativo,
        idEndereco: address.idFilial,
        nomeRazaoSocial: address.razaoSocial,
        tipoPessoa: 'J',
        codCliente: address.codCliente,
        cpfCnpj: address.cnpj,
        logradouro: address.logradouro,
        cidade: address.cidade,
        bairro: address.bairro,
        numero: address.numero,
        uf: address.estado,
        confirmAddress: true,
      });
    }

    dispatch({
      type: Types.GET_ADDRESSES_CLIENT_SUCCESS,
      payload: { addresses: content },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.GET_ADDRESSES_CLIENT_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const putMainAddressPJAction = (codCliente, address, client) => async (dispatch, getState) => {
  dispatch({ type: Types.PUT_MAIN_ADDRESS_REQUEST });

  try {
    await apiService.put(`/clientes/filiais/filial-principal-cliente/${codCliente}`);

    setClient({
      ...client,
      valeTotal: client?.valeTotal || address?.valeTotal,
      bitPrincipal: address.bitPrincipal,
      bitInativo: address.bitInativo,
      idEndereco: address.idFilial,
      nomeRazaoSocial: address.razaoSocial,
      tipoPessoa: 'J',
      codCliente: address.codCliente,
      cpfCnpj: address.cnpj,
      logradouro: address.logradouro,
      cidade: address.cidade,
      bairro: address.bairro,
      numero: normalizer(address.numero),
      uf: address.estado,
      confirmAddress: true,
    });

    if (client && client.checkoutId) {
      await apiService.put(`/carrinho/${client.checkoutId}/cliente/${client.codCliente}`);
    }

    dispatch({ type: Types.PUT_MAIN_ADDRESS_SUCCESS });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.PUT_MAIN_ADDRESS_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const handleInactiveAddressPJAction = (codCliente) => async (dispatch, getState) => {
  dispatch({ type: Types.PUT_INACTIVE_ADDRESS_REQUEST });

  try {
    await apiService.put(`/clientes/filiais/ativar-inativar-filial/${codCliente}`);
    dispatch({ type: Types.PUT_INACTIVE_ADDRESS_SUCCESS });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.PUT_INACTIVE_ADDRESS_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const getAddressClientPJAction = (codCliente) => async (dispatch, getState) => {
  dispatch({ type: Types.GET_ADDRESS_CLIENT_REQUEST });

  try {
    const {
      data: {
        content,
      },
    } = await apiService.get(`/clientes/filiais/filial-cliente/${codCliente}`);

    dispatch({
      type: Types.GET_ADDRESS_CLIENT_SUCCESS,
      payload: { address: content },
    });
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.GET_ADDRESS_CLIENT_FAILURE });
    snack.enqueueSnackbar(e.message || e, { variant: 'error' });
  }
};

const putAddressClientPJAction = () => async (dispatch, getState) => {
  dispatch({ type: Types.PUT_ADDRESS_CLIENT_REQUEST });

  try {
    const { snack } = getState().main.app;
    const form = getFormValues('client/address/edit')(getState());
    const { codCliente } = form;

    const {
      data: {
        ip,
      },
    } = await axios.get('https://api.ipify.org/?format=json');

    const {
      data: {
        message,
      },
    } = await apiService.put(`/clientes/filiais/filial-cliente/${codCliente}`, {
      ...form,
      ipCliente: ip,
      complemento: normalizer(form.complemento),
      razaoSocial: sanitize(normalizer(form.razaoSocial)),
      logradouro: sanitize(normalizer(form.logradouro)),
      bairro: sanitize(normalizer(form.bairro)),
      numero: normalizer(form.numero),
      informacoesAdicionais: normalizer(form.informacoesAdicionais)
    });

    if (message) {
      snack.enqueueSnackbar(message, { variant: 'success' });
      dispatch({ type: Types.PUT_ADDRESS_CLIENT_SUCCESS });

      goBack();
    }
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.PUT_ADDRESS_CLIENT_FAILURE });
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
  }
};

const registerNewAddressClientPJAction = () => async (dispatch, getState) => {
  dispatch({ type: Types.POST_ADDRESS_CLIENT_REQUEST });

  try {
    const { snack } = getState().main.app;
    const client = getClient();
    const form = getFormValues('client/address/new')(getState());

    const {
      data: {
        message,
        content,
      },
    } = await apiService.post('/clientes/filiais/nova-filial-cliente', {
      ...form,
      codMatriz: client.codClienteMatriz || client.codCliente,
      complemento: normalizer(form.complemento),
      logradouro: sanitize(normalizer(form.logradouro)),
      bairro: sanitize(normalizer(form.bairro)),
      numero: normalizer(form.numero) || 'S/N',
      informacoesAdicionais: normalizer(form.informacoesAdicionais),
      razaoSocial: sanitize(normalizer(form.razaoSocial)),
      nomeComprador: sanitize(normalizer(form.nomeComprador)),
    });

    setClient({
      ...client,
      logradouro: content.logradouro,
      cidade: content.cidade,
      bairro: content.bairro,
      numero: content.numero,
      uf: content.estado,
      confirmAddress: true,
      qtyProducts: 0,
    });

    if (message) {
      snack.enqueueSnackbar(message, { variant: 'success' });
      dispatch({ type: Types.POST_ADDRESS_CLIENT_SUCCESS });

      goBack();
    }
  } catch (e) {
    const { snack } = getState().main.app;
    dispatch({ type: Types.POST_ADDRESS_CLIENT_FAILURE });
    snack.enqueueSnackbar(resolveError(e), { variant: 'error' });
  }
};

export {
  // eslint-disable-next-line import/prefer-default-export
  // IndividualPerson
  getAddressesClientPFAction,
  putMainAddressPFAction,
  handleInactiveAddressPFAction,
  getAddressClientPFAction,
  putAddressClientPFAction,
  registerNewAddressClientPFAction,
  // Corporate Person
  getAddressesClientPJAction,
  putMainAddressPJAction,
  handleInactiveAddressPJAction,
  getAddressClientPJAction,
  putAddressClientPJAction,
  registerNewAddressClientPJAction,
};
