import {
  Box,
  Chip,
  Icon,
  Typography
} from '@material-ui/core';

import React, { useState } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  FlatExpansionPanelSummary,
  FlatExpansionPanel
} from '../../flatExpansionPanel';

import { Skeleton } from '@material-ui/lab';

import PropTypes from 'prop-types';
import useStyles from './style';

import {
  getStatusMainColor,
  getStatusSecondaryColor
} from "../../../utils/statusColor";

const CreditRequestInfo = ({
  loading,
  creditRequestStatus,
}) => {
  const classes = useStyles();
  const {
    creditRequestTitle,
    expansionPanel,
    creditRequestStatusText,
    launchIcon
  } = classes;

  const [open, setOpen] = useState(true);
  if (!creditRequestStatus) return null;
  const { id, status } = creditRequestStatus;

  const handleToggle = () => setOpen(!open);
  const handleUrl = url => window.open(`${window.location.origin}/credit/requests?solicitacaoId=${id}`, '_blank');

  if (loading) return <Skeleton variant="rect" height={40} />

  return (
    <Box pl={2} pr={2}>
      <FlatExpansionPanel expanded={open} onChange={handleToggle} style={{ marginTop: '0px', backgroundColor: 'transparent' }}>
        <span>
          <FlatExpansionPanelSummary
            className={expansionPanel}
            style={{minHeight: 40, height: 40}}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="resume-content"
          >
            <Typography className={creditRequestTitle}>
              Acompanhar Solicitação de Venda
            </Typography>
          </FlatExpansionPanelSummary>
        </span>

        <Box display="flex" alignItems="center" gridGap="20%" mb={1}>
          <Typography className={creditRequestStatusText}>
            Nº Solicitação:<a onClick={handleUrl}>{id} <Icon classes={{ root: launchIcon }}>launch</Icon></a>
          </Typography>
          <Chip label={status} color="primary" style={
            {
              backgroundColor: getStatusMainColor(status),
              border: '2px solid',
              borderColor: getStatusSecondaryColor(status),
              color: getStatusSecondaryColor(status),
              fontWeight: 600,
              height: 24
            }
          }/>
        </Box>
      </FlatExpansionPanel>
    </Box>
  );
};

CreditRequestInfo.defaultProps = {
  creditRequestStatus: null,
  loading: false,
};

CreditRequestInfo.propTypes = {
  loading: PropTypes.bool,
  creditRequestStatus: PropTypes.instanceOf(Object),
};

export default CreditRequestInfo;
