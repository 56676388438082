import React from 'react';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { toCEP, toCNPJ, toCPF } from '../../../../../shared/utils/parsers';
import { setClientAction } from '../../actions';
import { ClientMenu } from '../../../../checkout/_components';

const LinkedAccounts = ({
  accounts,
  isLoading,
  setClient,
}) => {
  const {
    accountBtn,
    avatar,
    arrowIcon,
    infoBox,
    linkedAccountBox,
    mainBox,
    mainInfoBox,
  } = useStyles();

  return (
    <Box className={mainBox}>
      {accounts.map((client) => (
        <Box className={linkedAccountBox}>
          <button
            type="button"
            className={accountBtn}
            onClick={() => setClient({ client: { ...client, qtyProducts: 0 } })}
            disabled={isLoading}
          >
            <Box className={mainInfoBox}>
              <span className={avatar}>{(client.nomeRazaoSocial ? client.nomeRazaoSocial[0] : '-').toUpperCase()}</span>
              <Box className={infoBox}>
                <span>{(client.nomeRazaoSocial || 'Cliente sem nome').toUpperCase()}</span>
                <span>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {!client.cpfCnpj ? client.cpfCnpj : (client.cpfCnpj.length === 11
                    ? toCPF(client.cpfCnpj) : toCNPJ(client.cpfCnpj))}
                </span>
                <span>{client.email}</span>

                <span>{`${client.logradouro}, ${client.numero}`}</span>
                {client.complemento && (
                  <span>
                    {client.complemento}
                  </span>
                )}
                <span>{`${client.bairro}, 
                  ${client.cidade} - 
                  ${client.uf} -
                  ${toCEP(client.cep)}
                `}</span>

                { client.bitPrincipal && (
                  <span className="main">
                    Endereço Principal
                  </span>
                )}
              </Box>
            </Box>
            <span className={arrowIcon} />
          </button>
        </Box>
      ))}
    </Box>
  );
};

LinkedAccounts.propTypes = {
  accounts: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
  setClient: PropTypes.func.isRequired,
};

const mapState = (state) => {
  const { isLoading } = state.authClient;
  return {
    isLoading,
  };
};

export default connect(mapState, {
  setClient: setClientAction,
})(LinkedAccounts);
