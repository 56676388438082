import React from 'react';
import * as PropTypes from 'prop-types';
import { ArrowForwardIos } from '@material-ui/icons';
import { Button, Box, Typography } from '@material-ui/core';

import useStyles from './styles';

const ButtonAccount = ({
  title,
  subtitle,
  onClick,
  icon,
  ...props
}) => {
  const {
    buttonText,
    container,
    buttonAccount,
    iconHeader,
  } = useStyles();

  return (
    <Button
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      classes={{ root: buttonAccount }}
      onClick={onClick}
      fullWidth
    >
      <Box className={container}>
        <span className={`${iconHeader} ${icon}`} />
        <Box alignItems="start" className={buttonText}>
          <Typography variant="subtitle1">
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="subtitle2">
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
      <ArrowForwardIos color="primary" fontSize="small" />
    </Button>
  );
};

ButtonAccount.defaultProps = {
  onClick: () => {},
  subtitle: '',
};

ButtonAccount.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
};

export default ButtonAccount;
