import React, { useEffect, useState } from 'react';

import { connect, useDispatch } from 'react-redux';

import {
  Box,
  Grid,
  Divider,
  Accordion,
  Typography,
  LinearProgress,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@material-ui/core';

import {
  Alert,
  Pagination,
} from '@material-ui/lab';

import {
  DateRange,
  ExpandMore,
  List,
} from '@material-ui/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toMoney } from '../../../../shared/utils/parsers';
import CircularLoading from '../../../../shared/components/loading/CircularLoading';

import {
  clearDetailedDeliveryAction,
  clearOrderByIdAction,
  getDetailedDeliveryAction,
  getNfeDownloadAction,
  getOrdersAction,
  getOrdersByIdAction,
  newCheckoutAction,
  sendPdfEmailAction,
} from './actions';

import CustomizedSteppers from './_components/stepper/Stepper';
import OrderInfo from './_components/info/OrderInfo';

import useStyles from './styles';
import OrderActions from './_components/actions/OrderActions';
import OrderProducts from './_components/products/OrderProducts';
import OrdersSkeleton from './_components/skeleton/OrderProducts';
import TitleFilter from '../../_components/titleFilter/TitleFilter';
import FilterOrders from './_components/filterOrders/FilterOrders';
import CardPaper from '../../_components/cardPaper/CardPaper';
import ModalErrorInformation from './_components/modalErrorInformation/ModalErrorInformation';

import { OrderDetailsModal } from '../../../../shared/components/orderDetails';
import { getClient, setClient } from '../../../../shared/services/authClient';
import { Types } from '../../../authClient/login';

const OrdersPage = ({
  meta,
  client,
  orders,
  orderById,
  getOrders,
  getOrdersById,
  getDetailedDelivery,
  clearDetailedDelivery,
  getNfe,
  detailedTracking,
  clearOrderById,
  sendPdfEmail,
  newCheckout,
  isLoading,
  isLoadingById,
  isLoadingSendEmail,
  isLoadingNewCheckout,
  isLoadingNfe,
}) => {
  const dispatch = useDispatch();

  const {
    button,
    cardGrid,
    accordionFilial,
    accordionFilialDetails,
    accordionFilialSummary,
    accordionTitle,
    accordionDate,
    accordionArrowIcon,
    infoOrder,
    infoOrderinconsistency,
    infoShipping,
    infoShippingTitle,
    infoShippingSubtitle,
  } = useStyles();

  const [filterOpened, setfilterOpened] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [inconsistencyModal, setInconsistencyModal] = useState(false);
  const [orderSelected, setOrderSelected] = useState(null);

  const loadingModal = isLoading || isLoadingSendEmail || isLoadingNewCheckout || isLoadingNfe;

  const handleTextModal = () => {
    if (isLoadingSendEmail) return 'Enviando email com o PDF do pedido';
    if (isLoadingNewCheckout) return 'Criando novo checkout';
    if (isLoadingNfe) return 'Carregando NFe';

    return 'Carregando';
  };

  const handleToggleFilter = () => setfilterOpened(!filterOpened);

  const handleClient = () => {
    const cookiesClient = getClient();
    if (client && cookiesClient) {
      if (client.codCliente !== cookiesClient.codCliente) {
        setClient(client);

        dispatch({
          type: Types.SET_CLIENT_MATRIX_ID,
          payload: {
            clientMatrixId: client.codClienteMatriz || client.codCliente,
          },
        });

        dispatch({
          type: Types.SET_CLIENT_SUCCESS,
          payload: { client },
        });
      }
    }
  };

  const handleGetOrders = (rpp, page) => {
    const rppR = rpp || meta.rpp;

    handleClient();

    getOrders(
      client.codCliente,
      rppR,
      page,
    );
  };
  const handleFilter = () => {
    handleGetOrders(meta.rpp, 1);
  };

  const handleChange = (codPedido) => (event, isExpanded) => {
    handleClient();
    if (isLoadingById) return;

    setExpanded(isExpanded ? codPedido : false);

    if (isExpanded) {
      const findedOrder = orders.find((order) => order.codPedido === codPedido);
      const { codCliente } = findedOrder;
      setOrderSelected(findedOrder);
      getOrdersById(codPedido, codCliente);
    } else {
      clearOrderById();
      setOrderSelected(null);
    }
  };

  const handlePaginationChange = (_, page) => handleGetOrders(meta.rpp, page, null, null);

  const handleEmail = () => {
    handleClient();
    const { codCliente, entregas } = orderById;
    const { codPedido } = entregas[0];

    sendPdfEmail(codPedido, codCliente);
  };

  const handleGetDetailedDelivery = (idEntrega) => getDetailedDelivery(idEntrega);
  const handleGetNfe = (idEntrega) => getNfe(idEntrega);
  const handleGetTransportadora = (url) => window.open(url, '_blank');

  const handleNewCheckout = (codPedido) => {
    if (!codPedido) return;

    newCheckout(codPedido);
  };

  useEffect(() => {
    handleGetOrders(meta.rpp, meta.page);
  }, []);

  const openInconsistencyPanel = () => setInconsistencyModal(
    orderById.motivoCancelamento
    || orderById.msgPainelDeInconsistencias
    || false,
  );

  const handleCloseInconsistencyPanel = () => setInconsistencyModal(false);
  const handleCloseDetailedModal = () => clearDetailedDelivery();

  return (
    <Grid className={cardGrid}>
      <CircularLoading
        open={loadingModal}
        message={handleTextModal()}
      />

      {detailedTracking && (
      <OrderDetailsModal
        detailed={detailedTracking}
        open={detailedTracking.length > 0}
        onClose={handleCloseDetailedModal}
      />
      )}
      <ModalErrorInformation
        isCancelled={orderById && orderById.motivoCancelamento && true}
        message={inconsistencyModal}
        onClose={handleCloseInconsistencyPanel}
      />

      <Box>
        <TitleFilter
          title="Pedidos do Cliente"
          filterOpened={filterOpened}
          handleToggleFilter={handleToggleFilter}
          onChangeRowsPerPage={({ target }) => { handleGetOrders(target.value, 1, null, null); }}
          onChangePage={(_, page) => { handleGetOrders(meta.rpp, page + 1, null, null); }}
          meta={meta}
        />

        <FilterOrders
          open={filterOpened}
          handleFilter={handleFilter}
          onSubmit={() => {}}
          loading={false}
        />

        {orders && orders.length ? orders.map((order) => {
          const {
            codPedido,
            quantidadeProdutos,
            dataPedido,
            total,
            canalOrigem,
            nomeVendedor,
          } = order;

          const canalOrigemMapping = canalOrigem === 'INT' ? 'SITE' : canalOrigem;
          return (
            <Accordion
              expanded={expanded === codPedido}
              onChange={handleChange(codPedido)}
              key={codPedido}
              className={accordionFilial}
            >
              <AccordionSummary
                className={accordionFilialSummary}
                expandIcon={<ExpandMore className={accordionArrowIcon} />}
              >
                <Grid container>
                  <Grid item xs={5}>
                    <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                      <Typography className={accordionTitle}>
                        N° Pedido
                        <span>{codPedido}</span>
                        {`com ${quantidadeProdutos} produtos`}
                      </Typography>
                      <Typography className={`${accordionTitle} ${accordionDate}`}>
                        <DateRange fontSize="small" />
                        {dataPedido}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box display="flex" alignItems="center" height="100%">
                      <Divider orientation="vertical" flexItem style={{ marginRight: 8 }} />
                      <Box minWidth="50%">
                        <Typography className={infoOrder}>
                          Canal
                          <span>{canalOrigemMapping}</span>
                        </Typography>
                        <Typography className={infoOrder}>{canalOrigem === 'PDV' && (<span>{nomeVendedor}</span>)}</Typography>
                      </Box>
                      <Divider orientation="vertical" flexItem style={{ marginRight: 8, marginLeft: 8 }} />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display="flex" alignItems="center" height="100%">
                      <Typography className={accordionTitle}>
                        Valor Total:
                        <span>{toMoney(total)}</span>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails className={accordionFilialDetails}>
                <Divider style={{ marginBottom: 24 }} />
                {isLoadingById && !orderById ? (
                  <>
                    <LinearProgress />
                    <br />
                    <OrdersSkeleton />
                  </>
                ) : (
                  <>
                    {orderById
                      && ((orderById.msgPainelDeInconsistencias && orderById.msgPainelDeInconsistencias.trim() !== '')
                      || orderById.motivoCancelamento)
                      && (
                      <Alert variant="filled" width="100%" severity="error" className={infoOrderinconsistency}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
                          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            {orderById.motivoCancelamento ? 'Pedido Cancelado' : 'Painel de Inconsistência'}
                          </Typography>
                          <Button
                            style={{ color: 'white', borderColor: 'white' }}
                            variant="outlined"
                            onClick={openInconsistencyPanel}
                          >
                            Ver informações
                          </Button>
                        </Box>
                      </Alert>
                      )}
                    {orderById && orderById.entregas && orderById.entregas.map((entrega, index) => {
                      const {
                        idEntrega,
                        quantidadeItens,
                        itens,
                        dataEntregaPrometida,
                        tracking,
                        objeto,
                      } = entrega;

                      return (
                        <>
                          <Box className={infoShipping}>
                            <Box>
                              <Typography className={infoShippingTitle}>
                                <span>
                                  Entrega
                                  {index + 1}
                                </span>
                                (#
                                {idEntrega || 'N/D'}
                                {' '}
                                com
                                {' '}
                                {quantidadeItens}
                                {' '}
                                produtos)
                              </Typography>
                              <Typography className={infoShippingSubtitle}>
                                Vendido e entregue por
                                <span>LojaDoMecanico</span>
                              </Typography>
                            </Box>
                            <Box>
                              {dataEntregaPrometida
                                ? (
                                  <Typography className={infoShippingTitle}>
                                    Chegará até
                                    <span>{moment(dataEntregaPrometida).format('DD [de] MMMM')}</span>
                                  </Typography>
                                )
                                : (
                                  <Typography
                                    className={infoShippingTitle}
                                  >
                                    Sem previsão de entrega
                                  </Typography>
                                )}
                            </Box>
                          </Box>

                          <OrderProducts products={itens} />
                          <CustomizedSteppers
                            orderDate={dataPedido}
                            tracking={tracking}
                            handleGetNfe={handleGetNfe}
                            handleGetTransportadora={handleGetTransportadora}
                            objeto={objeto}
                          />

                          {objeto.idEntrega && (
                            <Button
                              className={button}
                              onClick={() => handleGetDetailedDelivery(objeto.idEntrega)}
                              disableElevation
                              color="primary"
                              variant="contained"
                              style={{ height: 34 }}
                            >
                              <List fontSize="small" />
                              Entrega Detalhada
                            </Button>
                          )}
                          <Divider style={{ marginTop: 12, marginBottom: 12 }} />
                        </>
                      );
                    })}
                    {orderById && (
                      <>
                        <OrderInfo
                          enderecoEntrega={orderById.enderecoEntrega}
                          pagamentos={orderById.pagamentos}
                          juros={orderById.juros}
                          totalPagamento={orderById.totalPagamento || 0}
                        />
                        <OrderActions
                          orderSelected={orderSelected}
                          handleEmail={handleEmail}
                          handleNewCheckout={() => handleNewCheckout(codPedido)}
                        />
                      </>
                    )}
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          );
        }) : !isLoading && (
        <CardPaper>
          <Box display="flex" justifyContent="center" alignItems="center" height="80px">
            <Typography fontSize={12} fontWeight="bold" color="primary">Nenhum pedido encontrado</Typography>
          </Box>
        </CardPaper>
        )}

        <Box width="100%" display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Pagination color="primary" count={meta.totalpages} page={meta.page} onChange={handlePaginationChange} />
        </Box>
      </Box>
    </Grid>
  );
};

OrdersPage.defaultProps = {
  meta: null,
  client: null,
  orderById: null,
  orders: null,
  detailedTracking: null,
  getOrders: () => {},
  getOrdersById: () => {},
  getDetailedDelivery: () => {},
  clearDetailedDelivery: () => {},
  getNfe: () => {},
  clearOrderById: () => {},
  sendPdfEmail: () => {},
  newCheckout: () => {},
  isLoading: false,
  isLoadingById: false,
  isLoadingSendEmail: false,
  isLoadingNewCheckout: false,
  isLoadingNfe: false,
};

OrdersPage.propTypes = {
  meta: PropTypes.instanceOf(Object),
  client: PropTypes.instanceOf(Object),
  orderById: PropTypes.instanceOf(Object),
  orders: PropTypes.instanceOf(Array),
  detailedTracking: PropTypes.instanceOf(Array),
  getOrders: PropTypes.func,
  getOrdersById: PropTypes.func,
  getDetailedDelivery: PropTypes.func,
  clearDetailedDelivery: PropTypes.func,
  getNfe: PropTypes.func,
  clearOrderById: PropTypes.func,
  sendPdfEmail: PropTypes.func,
  newCheckout: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingById: PropTypes.bool,
  isLoadingSendEmail: PropTypes.bool,
  isLoadingNewCheckout: PropTypes.bool,
  isLoadingNfe: PropTypes.bool,
};

const mapState = (state) => {
  const { client } = state.authClient;

  const {
    meta,
    orders,
    orderById,
    detailedTracking,
    isLoading,
    isLoadingById,
    isLoadingSendEmail,
    isLoadingNewCheckout,
    isLoadingNfe,
  } = state.main.orders;

  return {
    meta,
    client,
    orders,
    orderById,
    detailedTracking,
    isLoading,
    isLoadingById,
    isLoadingSendEmail,
    isLoadingNewCheckout,
    isLoadingNfe,
  };
};

export default connect(mapState, {
  getOrders: getOrdersAction,
  getOrdersById: getOrdersByIdAction,
  sendPdfEmail: sendPdfEmailAction,
  getNfe: getNfeDownloadAction,
  newCheckout: newCheckoutAction,
  clearOrderById: clearOrderByIdAction,
  getDetailedDelivery: getDetailedDeliveryAction,
  clearDetailedDelivery: clearDetailedDeliveryAction,
})(OrdersPage);
