/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
import React, { useMemo, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import {
  TableBody, TableCell, TableHead, TableRow, Button, Tooltip, Chip, useMediaQuery,
  useTheme,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Delete, InfoOutlined, OpenInNewRounded } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import moment from 'moment';
import useStyles from './styles';
import {
  openDialogPaymentAction,
  openDialogPixAction,
  getCheckPixAction,
  removePixAction,
  openDialogPaymentStatusAction
} from './actions';
import DialogAdvancedPayment from './DialogAdvanced';
import DialogPix from './DialogAdvancedPix';
import DialogPaymentStatus from './DialogPaymentStatus';
import { toMoney } from '../../../../../shared/utils/parsers';
import { getUnidadeDeNegocio} from '../../../../../shared/services/app';
import ResponsiveTable from '../../../../../shared/components/responsiveTable';
import { removePaymentAction, openDialogCreditCardQrCodeAction, closeDialogCreditCardQrCodeAction,
  openResendLinkAction, closeResendLinkAction } from '../actions';
import { getCheckoutAction, openDialogDiscountAction, openDialogDiscountObsAction, openDialogDiscountRequestAction, } from '../../../actions';
import { checkDiscountOfferAction } from '../../../actions';
import { getConditionsAction } from '../actions';
import ItemAdvancedPaymentResume from './ItemAdvancedPaymentResume';
import openIcon from '../../../../../assets/externalLink.svg';
import sync from '../../../../../assets/syncAlt.svg';
import CreditCardQrCode from '../CreditCardQrCode';
import CircularLoading from '../../../../../shared/components/loading/CircularLoading';

const Advanced = ({
  openDialogPayment,
  removePayment,
  payments,
  resume,
  checkoutId,
  deniedPayments,
  getConditions,
  dialogCreditCardQrCodeOpened,
  openDialogCreditCardQrCode,
  closeDialogCreditCardQrCode,
  chosedFreight,
  orderId,
  openDialogPix,
  checkout,
  getCheckout,
  checkPix,
  getCheckPix,
  removePix,
  loadingPix,
  openDialogPaymentStatus,
  openResendLink,
  customerId,
  loading,
  checkDiscountOffer,
  resendLink,
  closeResendLink,
}) => {
  const classes = useStyles();
  const paymentPix = payments.find((a) => a.type === 'PX');
  const date = moment(paymentPix && paymentPix.orquestradorPixDTO?.records?.dataValidade).format('HH:mm');
  const { sumTotal } = useMemo(() => payments.reduce((accuml, payment) => ({
    sumTotal: payment.total + accuml.sumTotal,
    sumDiscount: payment.discount + accuml.sumDiscount,
  }), { sumTotal: 0, sumDiscount: 0 }), [payments]);

  // eslint-disable-next-line no-nested-ternary
  const tooltipMessage = !chosedFreight
    ? 'Escolha uma forma de entrega!'
    : resume.hasMarketPlace
      ? 'Pedidos com itens Market Place não podem ter pagamento avançado'
      : '';

  const disabled = resume.hasMarketPlace
    || resume.remainder <= 0
    || resume.remainderInstallment <= 0
    || orderId
    || !chosedFreight
    || loading;
  useEffect(() => {
    if (checkPix) {
      getCheckout({
        idCheckout: checkoutId,
        isSameDay: checkout.checkedSameday,
        isPickup: checkout.checkedPickup,
        calculateTax: checkout.resume.calculateTax,
      });
    }
  }, [checkPix]);

  const handleGetCheckout = async () => {
    await getCheckout({
      idCheckout: checkoutId,
      isSameDay: checkout.checkedSameday,
      isPickup: checkout.checkedPickup,
      entregaNitro: checkout.checkedNitrous,
      calculateTax: checkout.resume.calculateTax,
    });
  }

  const requestDiscount  = async () => {
    let percentPermission = 10.2;
    if (getUnidadeDeNegocio() > 1) {
      percentPermission = 0;
    }
    let produtoComDesconto = false;
    await getConditions();
    if (checkout?.itemsFilials !== undefined) {
      for (let i = 0; i < checkout.itemsFilials.length; i++) {
        if (checkout.itemsFilials[i].items !== undefined) {
          for (let r = 0; r < checkout.itemsFilials[i].items.length; r++) {
            let poncent = (checkout.itemsFilials[i].items[r].discount*100)/(checkout.itemsFilials[i].items[r].totalOnTime);
            if (poncent > percentPermission) {
              produtoComDesconto = true;
            }
          }
        }
      }
    }

    if (checkout.discountCart <= percentPermission && !produtoComDesconto) {
      openDialogPayment();
    } else {
      if (await checkDiscountOffer()) {
        openDialogPayment();
      }
    }
  };

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'));

  const checkoutIdPayment = useMemo(
    () => payments.filter((payment) => !['PIX'].includes(payment.type))
  )

  const closeDialogQrCode = () => {
    closeResendLink();
    closeDialogCreditCardQrCode();
  }

  const [selectedPaymentItem, setSelectedPaymentItem] = useState([]);
  const openDialogCreditCardQrCodeResendLink = (item) => {
    setSelectedPaymentItem(item);
    openDialogCreditCardQrCode();
    openResendLink();
  }

  useEffect(() => {
    if (resendLink == true && !dialogCreditCardQrCodeOpened) {
      setSelectedPaymentItem(payments[payments.length-1]);
      openDialogCreditCardQrCode();
    }
  }, [resendLink]);

  const refreshPayments = async() => {
    for (var i = 0; i < checkoutIdPayment?.length; i++) {
      await getCheckPix(checkoutId, customerId, checkoutIdPayment[i]?.paymentId);
    }
    await getCheckout({
      idCheckout: checkoutId,
      isSameDay: checkout.checkedSameday,
      isPickup: checkout.checkedPickup,
      entregaNitro: checkout.checkedNitrous,
      calculateTax: checkout.resume.calculateTax,
    });
  };

  const removePaymentPix = async (paymentId) => {
    await getCheckPix(checkoutId, customerId, paymentId);

    handleGetCheckout().then(() => {
      const paymentPix = checkoutIdPayment.find((payment) => payment.paymentId === paymentId);

      if (paymentPix.paymentStatus === 1) {
        removePix(paymentId).then(() => {
          handleGetCheckout();
        });
      }
    });
  }

  const [selectedPixItem, setSelectedPixItem] = useState([]);
  const [selectedPsItem, setSelectedPsItem] = useState([]);
  const handleOpenDialogPix = (item) => {
    setSelectedPixItem(item);
    openDialogPix();
  }

  const handleOpenDialogPaymentStatus = (item) => {
    setSelectedPsItem(item);
    openDialogPaymentStatus();
  }

  return (
    <form style={ isMobile ? {paddingTop: 40} : {} }>
      <Box className={classes.divPayment}>
        <Grid container>
          <Grid item xs={12}>
            <ItemAdvancedPaymentResume
              value={`${toMoney(resume.subTotalVista)}`}
              label="Total"
              valueInstallment={`${toMoney(resume.subTotalPrazo)}`}
              valueRemainder={`${toMoney(resume.remainder)}`}
              totalGarantiaEstendida={`${toMoney(resume.totalGarantiaEstendida)}`}
              valueInstallmentRemainder={`${toMoney(resume.remainderInstallment)}`}
              frete={`${toMoney(resume.freight)}`}
              valuetotal={`${toMoney(resume.total)}`}
              valuetotalinstallment={`${toMoney(resume.totalInstallment)}`}
              discountCart={`${toMoney(resume.maxDiscount)}`}
            />
          </Grid>
        </Grid>
      </Box>
      <CircularLoading open={loadingPix} message="Carregando Pix" />
      <ResponsiveTable striped>
        <TableHead>
          <TableRow
            classes={{
              root: classes.oddRow,
            }}
          >
            <TableCell component="th">
              Forma de Pagamento
            </TableCell>
            <TableCell component="th" align="right">
              Total
            </TableCell>
            <TableCell component="th" align="right">
              Quantidade de Parcelas
            </TableCell>
            <TableCell component="th" align="right">
              Valor da Parcela
            </TableCell>
            <TableCell component="th" />
            <TableCell component="th" />
          </TableRow>
        </TableHead>

        <TableBody>
          {deniedPayments.map((item, index) => (
            <TableRow  style={ isMobile ? {width: 350} : {width: ''}}
              key={`row-${index + 1}`}
            >
              <TableCell datatype="Forma de Pagamento">
                {item.type === 'PX' ? (
                  <>
                    <Chip
                      label={`${item.type}`}

                    />
                      <Chip style={{marginLeft: 10}}
                      label={`${item.type} ${item.braspagReturn || 'Reprovado'}`}
                        className={classes.chipError}
                      />
                  </>
                )
                  : (
                    <>
                      <Chip
                        label={`${item.type}`}

                      />

                      {item.braspagReturn ? (
                        <Chip style={{marginLeft: 10}}
                          label={item.braspagReturn}
                          className={classes.chipError}
                        />
                      ) : (
                        <Chip style={{marginLeft: 10}}
                          label={'Não aprovado'}
                          icon={<InfoOutlined style={{ color: '#FFFFFF' }} fontSize="small" />}
                          onClick={() => { handleOpenDialogPaymentStatus(item) }}
                          className={classes.chipError}
                        />
                      )}
                    </>
                  )}

              </TableCell>

              <TableCell datatype="Total" align="right" className={classes.nowrap}>
                <Box whiteSpace="nowrap">
                  {toMoney(item.total)}
                </Box>
              </TableCell>

              <TableCell datatype="Quantidade de Parcelas" align="right">
                {item.installmentQuantity}
              </TableCell>

              <TableCell datatype="Valor da Parcelas" align="right">
                <Box whiteSpace="nowrap">
                  {toMoney(item.installmentValue)}
                </Box>
              </TableCell>

              <TableCell component="th" align="right" />

              <TableCell component="th" align="right" />
            </TableRow>
          ))}

          {payments.map((item, index) => {
            const isPixWarn = item.paymentStatus === 1 && item.type === 'PX';
            const isPixSucess = item.paymentStatus === 2 && item.type === 'PX';
            const isLinkRemote = item.paymentStatus === 1 && item.type == 'CC';
            return (
              <TableRow   style={ isMobile ? {width: 350} : {width: ''}}
                key={`row-${index + 1}`}
              >
                <TableCell datatype="Forma de Pagamento">
                  {item.type === 'PX'
                    ? (
                      item.paymentStatus === 1 ? (
                        <div className={classes.divTypePayment}>
                          <Tooltip title="Atualizar pagamentos">
                            <IconButton onClick={refreshPayments}>
                              <img src={sync} alt="" style={{ color: "#2296F3", fontSize: 18 }} />
                            </IconButton>
                          </Tooltip>
                          <Chip
                            label={`${item.type} - Aguardando `}
                            className={clsx({
                              [classes.chipWarn]: isPixWarn,
                            })}
                          />
                          {!isMobile
                          ? (
                            <Chip
                              label={`Pix válido até ${date}`}
                              className={classes.chipSilver}
                            />
                          ) : (
                            <div>Pix válido até ${date}</div>
                          )}
                          <Tooltip title="Abrir modal pix">
                            <IconButton onClick={() => { handleOpenDialogPix(item) }}>
                              <img src={openIcon} alt="" style={{ color: '#2979f5', fontSize: 18 }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )
                        : item.paymentStatus === 2
                      && (
                        <Chip
                          label={`${item.type} Aprovado`}
                          className={clsx({
                            [classes.chipSucess]: isPixSucess,
                          })}
                        />
                      )
                    )
                    : (
                      <>
                        <Chip
                          label={`${item.type}${isPixWarn ? ' - Aguardando' : ''}${item.type == 'VA' ? ' - '+item.voucherIdBseller  : ''}${item.type == 'CP' ? ' - '+item.voucherIdGurgel  : ''}`}
                          className={clsx({
                            [classes.chipWarn]: isPixWarn,
                          })}
                        />

                        {isLinkRemote && (
                          <>
                            <Tooltip title="Atualizar pagamentos"  style={{marginLeft: 10}}>
                              <IconButton onClick={refreshPayments}>
                                <img src={sync} alt="" style={{ color: '#FF5225', fontSize: 18 }} />
                              </IconButton>
                            </Tooltip>
                            <Chip style={{marginLeft: 10}}
                              label={'Pagamento pendente'}
                              className={classes.chipWarnSusp}
                            />
                            <span style={ isMobile ? {position: 'absolute', marginTop: 44, right: 6, color: '#2979F5', fontSize: '11px', marginLeft: '10px', cursor: 'Pointer'} : {color: '#2979F5', fontSize: '11px', marginLeft: '10px', cursor: 'Pointer'}} onClick={() => { openDialogCreditCardQrCodeResendLink(item) }} ><OpenInNewRounded style={{color: '#2979F5', fontSize: '11px'}}/> Reenviar Link</span>
                          </>
                        )}
                      </>

                    )}
                </TableCell>

                <TableCell datatype="Total" align="right">
                  <Box whiteSpace="nowrap">
                    {toMoney(item.total)}
                  </Box>
                </TableCell>

                <TableCell datatype="Quantidade de Parcelas" align="right">
                  {item.installmentQuantity}
                </TableCell>

                <TableCell datatype="Valor da Parcelas" align="right">
                  <Box whiteSpace="nowrap">
                    {toMoney(item.installmentValue)}
                  </Box>
                </TableCell>

                <TableCell align="right">
                </TableCell>

                <TableCell align="right">
                  {!orderId && (!(['CC'].includes(item.type) && item.paymentStatus !== 1) || isPixWarn) && item.type !== 'PX' && item.type !== 'DB' && (
                    <IconButton
                      size="small"
                      disabled={orderId}
                      onClick={() => {
                        removePayment(checkoutId, item.paymentId, item.type);
                      }}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            );
          })}

        </TableBody>
        <TableBody>
          <TableRow>
            <TableCell datatype="">
              <b>
                Total Pago
              </b>
            </TableCell>

            <TableCell datatype="" align="right">
              <Box whiteSpace="nowrap">
                {toMoney(sumTotal)}
              </Box>
            </TableCell>

            <TableCell datatype="" align="right" />

            <TableCell component="th" align="right" />

            <TableCell component="th" align="right" />

            <TableCell component="th" align="right" />
          </TableRow>
        </TableBody>
      </ResponsiveTable>

      {!orderId && (
        <Tooltip placement="top" title={tooltipMessage}>
          <span stylle={{ textAlign: 'center' }}>
            <Button
              className={classes.addMore}
              type="button"
              onClick={() => {
                requestDiscount();
              }}
              disableFocusRipple
              disabled={disabled || loading}
            >
              ADICIONAR PAGAMENTO
            </Button>
          </span>

        </Tooltip>
      )}

      <DialogAdvancedPayment checkout={checkout} />
      <DialogPix checkout={checkout} selectedItem={selectedPixItem} />
      <DialogPaymentStatus checkout={checkout} selectedItem={selectedPsItem}/>
      <CreditCardQrCode opened={dialogCreditCardQrCodeOpened} close={closeDialogQrCode} paymentItem={selectedPaymentItem} />
    </form>
  );
};

Advanced.defaultProps = {
  orderId: null,
};

Advanced.propTypes = {
  chosedFreight: PropTypes.bool.isRequired,
  openDialogPayment: PropTypes.func.isRequired,
  openDialogPix: PropTypes.func.isRequired,
  openDialogPaymentStatus: PropTypes.func.isRequired,
  removePayment: PropTypes.func.isRequired,
  checkoutId: PropTypes.number.isRequired,
  orderId: PropTypes.number,
  payments: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  deniedPayments: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  resume: PropTypes.instanceOf(Object).isRequired,
  checkout: PropTypes.instanceOf(Object).isRequired,
  customerId: PropTypes.number.isRequired,
  checkPix: PropTypes.bool.isRequired,
  dialogCreditCardQrCodeOpened: PropTypes.bool.isRequired,
  openDialogDiscount: PropTypes.func.isRequired,
  openDialogCreditCardQrCode: PropTypes.func.isRequired,
  openResendLink: PropTypes.func.isRequired,
  resendLink: PropTypes.bool.isRequired,
  closeDialogCreditCardQrCode: PropTypes.func.isRequired,
  getCheckout: PropTypes.func.isRequired,
  checkDiscountOffer: PropTypes.func.isRequired,
  justificativa: PropTypes.bool.isRequired,
  aguardandoSolicitacao: PropTypes.bool.isRequired,
  getCheckPix: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  getConditions: PropTypes.func.isRequired,
};

const mapState = (state) => {
  const {
    checkoutId, resume, payments, deniedPayments, orderId,
  } = state.main.checkout.geral.checkout;
  const { chosedFreight, loading } = state.main.checkout.geral;
  const { loading: loadingPayment, dialogCreditCardQrCodeOpened, resendLink } = state.main.checkout.payment;
  return {
    ...state.main.checkout.advanced,
    ...state.main.checkout.geral,
    payments,
    deniedPayments,
    dialogCreditCardQrCodeOpened,
    resume,
    checkoutId,
    resendLink,
    orderId,
    chosedFreight,
    loading: loadingPayment || loading,
  };
};

export default connect(mapState, {
  openDialogPayment: openDialogPaymentAction,
  openDialogPix: openDialogPixAction,
  openDialogPaymentStatus: openDialogPaymentStatusAction,
  getCheckout: getCheckoutAction,
  checkDiscountOffer: checkDiscountOfferAction,
  removePayment: removePaymentAction,
  openDialogCreditCardQrCode: openDialogCreditCardQrCodeAction,
  openResendLink: openResendLinkAction,
  closeResendLink: closeResendLinkAction,
  closeDialogCreditCardQrCode: closeDialogCreditCardQrCodeAction,
  getCheckPix: getCheckPixAction,
  removePix: removePixAction,
  openDialogDiscount: openDialogDiscountAction,
  openDialogDiscountObs: openDialogDiscountObsAction,
  openDialogDiscountRequest: openDialogDiscountRequestAction,
  getConditions: getConditionsAction,
})(Advanced);
