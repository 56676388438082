import React, { useEffect, useState } from 'react';

import {
  Box,
  Grid,
} from '@material-ui/core';

import * as PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useStats } from 'react-instantsearch';
import CardPaper from '../../_components/cardPaper/CardPaper';
import TitleButton from '../../_components/titleButton/TitleButton';

import useStyles from './styles';
import { AddressEditForm, AddressesList, AddressNewForm } from '../../_components';
import { goBack, push } from '../../../../history';
import { getAddressesClientPFAction, getAddressesClientPJAction } from './actions';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import CircularLoading from '../../../../shared/components/loading/CircularLoading';
import { getClient, setClient } from '../../../../shared/services/authClient';
import { Types } from '../../../authClient/login';

const Addresses = ({
  client,
  isLoading,
  getAddressesClientPF,
  getAddressesClientPJ,
  clientMatrixId,
}) => {
  const { query } = useStats();
  const dispatch = useDispatch();
  const {
    addressId,
  } = useParams();
  const param = window.location.pathname.split('/');
  const isNewAddressPage = param[3] === 'new';
  const isListAddressesPage = !param[3] && !addressId;
  const [component, setComponent] = useState(<></>);
  const isCorporate = client && client.tipoPessoa === 'J';

  const {
    cardGrid,
  } = useStyles();

  useEffect(() => {
    if (!client) push('/client-login');

    if (!isNewAddressPage) {
      if (isCorporate) {
        getAddressesClientPJ(
          clientMatrixId || client.codClienteMatriz || client.codCliente,
          client,
        );
      } else if (isListAddressesPage) {
        getAddressesClientPF(client.codCliente);
      }
    }
  }, []);

  const handleClient = () => {
    const cookiesClient = getClient();
    if (client && cookiesClient) {
      if (client.codCliente !== cookiesClient.codCliente) {
        setClient(client);

        dispatch({
          type: Types.SET_CLIENT_MATRIX_ID,
          payload: {
            clientMatrixId: client.codClienteMatriz || client.codCliente,
          },
        });

        dispatch({
          type: Types.SET_CLIENT_SUCCESS,
          payload: { client },
        });
      }
    }
  };

  const addAddress = () => {
    handleClient();
    push(`${param[2]}/new`);
  };

  const handleGoBack = () => {
    handleClient();
    goBack();
  };

  useEffect(() => {
    if (query) handleClient();
  }, [query]);

  useEffect(() => {
    let page = <></>;
    if (isListAddressesPage) {
      page = (
        <Box>
          <TitleButton
            title={`${isCorporate ? 'Empresas' : 'Endereços'} do Cliente`}
            button="Adicionar Endereço"
            widthBtn={210}
            onClick={addAddress}
          />
          <CardPaper>
            <AddressesList />
          </CardPaper>
        </Box>
      );
    } else if (isNewAddressPage) {
      page = (
        <Box>
          <TitleButton
            title="Novo Endereço"
            button="Voltar"
            widthBtn={210}
            onClick={handleGoBack}
            icon="back-icon"
          />
          <CardPaper>
            <AddressNewForm />
          </CardPaper>
        </Box>
      );
    } else {
      page = (
        <Box>
          <TitleButton
            title="Alterar Endereço"
            button="Voltar"
            widthBtn={210}
            onClick={handleGoBack}
            icon="back-icon"
          />
          <CardPaper>
            <AddressEditForm />
          </CardPaper>
        </Box>
      );
    }

    setComponent(page);
  }, []);

  return (
    <Grid className={cardGrid}>
      <CircularLoading
        open={isLoading}
        message="Atualizando..."
      />
      {component}
    </Grid>
  );
};

Addresses.defaultProps = {
  isLoading: false,
  clientMatrixId: 0,
};

Addresses.propTypes = {
  client: PropTypes.instanceOf(Object).isRequired,
  getAddressesClientPF: PropTypes.func.isRequired,
  getAddressesClientPJ: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  clientMatrixId: PropTypes.number,
};

const mapState = (state) => {
  const { clientMatrixId } = state.authClient;
  const client = getClient() || state.authClient.client;
  const {
    isLoading,
  } = state.main.clientAddresses;

  return {
    client,
    isLoading,
    clientMatrixId,
  };
};

export default connect(mapState, {
  getAddressesClientPF: getAddressesClientPFAction,
  getAddressesClientPJ: getAddressesClientPJAction,
})(Addresses);
