import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: 4,
    marginRight: 4,
    width: 220,
    border: '1px solid',
    height: 48,
    borderRadius: 8,
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'none',
    '& .MuiSvgIcon-root': {
      marginRight: 4
    }
  }
}));
export default useStyles;
