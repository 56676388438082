import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {connect, useDispatch} from 'react-redux';
import useStyles from './styles';
import { Snackbar } from '../../../../../shared/components/algolia';
import apiService from '../../../../../shared/services/apiService';
import {getCheckoutAction, newCheckoutAction, resetSearchAction} from '../../../actions';
import {getClient, setClient} from "../../../../../shared/services/authClient";
import {Types as TypesClient} from "../../../../authClient/login";
import types from "../../../types";
import {ProductCard, ProductListCard} from "../../index";

const Product = ({
  className,
  product,
  userFilialId,
  snack,
  getCheckout,
  checkout,
  filialUserLinked,
  setIndexFilialUser,
  pplInfo,
  isCheckout,
  newCheckout,
  user,
  client,
  resetSearch,
  loading,
  isFisicStore,
  listCard,
}) => {
  const deliveryType = [];
  const dispatch = useDispatch();

  const [localFilial, setLocalFilial] = useState(false);
  const [outStock] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [productAvailable, setProductAvailable] = useState(false);
  const [openVoltageDialog, setOpenVoltageDialog] = useState(false);

  const [voltage, setVoltage] = useState('');
  const filialText = localFilial ? 'nesta' : 'desta';
  const stockText = outStock ? 'Sem estoque' : 'Estoque';
  const [qtyLocalFilial, setQtyLocalFilial] = useState(0);
  const [qtyRequested, setQtyRequested] = useState('1');
  const [voltageOptions, setVoltageOptions] = useState([]);
  const [guaranteesOptions, setGuaranteesOptions] = useState([]);
  const [guaranteeIdSelected, setGuaranteeIdSelected] = useState(0);
  const [voltageIdSelected, setVoltageIdSelected] = useState(0);
  const [priceEstablishment, setPriceEstablishment] = useState({});

  const [alert, setAlert] = useState({
    open: false,
    message: 'Produto adicionado ao carrinho com sucesso.',
    severity: 'success',
  });

  const classes = useStyles();
  const { snackbar} = classes;

  const {
    available,
    objectID,
    price,
    shippingData,
    stockEstablishments,
    stockEstablishmentsAvailable,
    technicalSpecs,
    virtualStock,
    priceEstablishments,
  } = product;

  const createNewCheckout = (productId, qty) => {
    const checkoutInfo = {
      idVendedor: user.idVendedor,
      idFilial: filialUserLinked,
    };

    const productInfo = [{
      quantity: Number(qty) || 1,
      idSku: voltageIdSelected || +productId,
      isPPL: !!pplInfo,
      ppl: pplInfo ? pplInfo.hash : null,
      warrantyId: guaranteeIdSelected || null,
      isCompreJunto: false,
    }];

    newCheckout({checkout: checkoutInfo, product: productInfo});
  };

  const addProduct = async (productId, qty) => {
    try {
      if(!client) {
        localStorage.setItem('productInitialToLogin', JSON.stringify([{
          quantity: Number(qty) || 1,
          idSku: voltageIdSelected || +productId,
          isPPL: !!pplInfo,
          ppl: pplInfo ? pplInfo.hash : null,
          warrantyId: guaranteeIdSelected || null,
          isCompreJunto: false,
        }]));
        resetSearch(true);
      } else {
        if (!isCheckout && !client.checkoutId) {
          await createNewCheckout(productId, qty);
          setVoltageIdSelected(0);
          setGuaranteeIdSelected(0);
          setOpenVoltageDialog(false);
          setOpenDialog(false);
        } else {
          if(!isCheckout) {
            dispatch({
              type: types.CHECKOUT_LOADING,
              payload: { loading: true },
            });
          }

          const data = await apiService.post(`/carrinho/adicionar-produto`, {
            checkoutId: client.checkoutId || checkout.checkoutId,
            quantity: Number(qty),
            idFilial: filialUserLinked,
            idSku: voltageIdSelected ? voltageIdSelected : +productId,
            warrantyId: guaranteeIdSelected ? guaranteeIdSelected : null,
            ppl: pplInfo ? pplInfo.hash : null,
            isCompreJunto: false,
            isPPL: !!pplInfo,
            userId: user.idVendedor,
            cep: checkout.customer?.address?.zipCode,
            customerId: client.codCliente,
            isCatalogo: false,
          }).then((response) => response.data.results.response);

          if (data) {
            setAlert({...alert, open: true});
            setVoltageIdSelected(0);
            setGuaranteeIdSelected(0);
            setOpenVoltageDialog(false);
            setOpenDialog(false);

           if(!isCheckout) {
             setClient({
               ...client,
               qtyProducts: Number(client.qtyProducts) + Number(qty),
             });

             dispatch({
               type: TypesClient.SET_CLIENT_SUCCESS,
               payload: {
                 client: {
                   ...client,
                   qtyProducts: Number(client.qtyProducts) + Number(qty),
                 },
                 clients: null,
               },
             });

             dispatch({
               type: types.CHECKOUT_LOADING,
               payload: { loading: false },
             });
           }
          }
        }
      }
    } catch (e) {
      setAlert({
        open: true,
        message: e,
        severity: 'error',
      })
    }
  };

  const handleVoltage = (voltage) => {
    setVoltage(voltage);
  };

  const handleTooltip = () => setOpenTooltip(!openTooltip);

  const copyToClipboard = (idProduct) => {
    navigator.clipboard.writeText(idProduct).catch((err) => console.log(err.message));
    handleTooltip();
  };

  const handleStockTotal = (Object.keys(stockEstablishments).length
    ? (Object.values(stockEstablishments).reduce((acum, value) => acum + value)) : 0);

  const handleLocalFilial = () => {
    const filial = Array(stockEstablishments).map((value) => value[userFilialId]);
    const qty = filial[0] || 0;
    setLocalFilial(!!qty);
    setQtyLocalFilial(qty);
  };

  const handlePriceEstablishments = () => {
    if (filialUserLinked) {
      const index = Number(
        Object.keys(priceEstablishments).find(
          (key) => priceEstablishments[key].branchId === Number(filialUserLinked),
        ),
      ) || null;

      if (priceEstablishments[index]) {
        const { term, name } = priceEstablishments[index];

        setIndexFilialUser(index);
        setPriceEstablishment({
          ...price,
          name,
          term,
        });
      } else {
        setPriceEstablishment({
          ...price,
          name: '',
        });
      }
    } else {
      setPriceEstablishment({
        ...price,
        name: '',
      });
    }
  };

  const handleProductAvailable = () => setProductAvailable(available);

  const handleVoltageInitial = () => {
    setVoltage(technicalSpecs.voltage || '');
  };

  useEffect(() => {
    handleProductAvailable();
    handleVoltageInitial();
    handlePriceEstablishments();
  }, [filialUserLinked]);

  useEffect(() => {
    handleLocalFilial();
  }, [userFilialId]);

  useEffect(() => {
    if (openTooltip) {
      setTimeout(() => {
        handleTooltip();
      }, 1000);
    }
  }, [openTooltip]);

  if (productAvailable) {
    if (stockEstablishmentsAvailable.some((item) => item === 'CrossDocking') || virtualStock) {
      deliveryType.push({
        textPrimary: virtualStock ? 'Estoque virtual' : 'Produto crossdocking',
        textSecondary: `Produto disponível após <b>${technicalSpecs.virtualDays} dias</b>`,
        name: `<b>${virtualStock ? 'VT' : 'XD'}</b>`,
        color: 'colorYellow',
      });
    }
    if (shippingData.freeShipping) {
      deliveryType.push({
        textPrimary: 'Frete grátis',
        textSecondary: 'Para todo o Brasil',
        name: 'FG - BR',
        color: 'colorGreen',
      });
    }
    if (shippingData.expressDelivery) {
      deliveryType.push({
        textPrimary: 'Entrega expressa',
        name: 'EE',
        color: 'colorBlue',
      });
    }
  }
  const handleVoltageModal = async () => {
    if (technicalSpecs.voltage) {
      await getDetailProduct(objectID, technicalSpecs.voltage);
    } else await addProduct(objectID, qtyRequested);
  };

  const getDetailProduct = async (idSkus) => {
    try {
      const data = await apiService.get(`/produtos/${idSkus}/?qtd=1`).then((response) => response.data.results);
      if (data === null) {
        snack.enqueueSnackbar(`problema com o produto ${idSkus}`, { variant: 'error' });
      }
      if (data.tensao.length) {
        const resultVoltage = data.tensao.filter(({ tensao }) => tensao.match(technicalSpecs.voltage));
        const res = resultVoltage.length ? resultVoltage[0] : data.tensao[0];

        setVoltageOptions([{
          ...res,
          selected: true,
        }]);
        setVoltageIdSelected(res.id);
        setGuaranteesOptions(data.garantias);
        setOpenVoltageDialog(true);
      } else await addProduct(objectID, qtyRequested);
    } catch (e) {
      snack.enqueueSnackbar(e.message || e, { variant: 'error' });
    }
  };

  return (
    <>
      {
        listCard ? (
          <ProductListCard
            handleVoltageModal={handleVoltageModal}
            addProduct={() => addProduct(objectID, qtyRequested)}
            product={product}
            productAvailable={productAvailable}
            data={{
              stockText,
              filialText,
              localFilial,
              qtyLocalFilial,
            }}
            className={className}
            priceEstablishment={priceEstablishment}
            guaranteesOptions={guaranteesOptions}
            voltageOptions={voltageOptions}
            qtyLocalFilial={qtyLocalFilial}
            localFilial={localFilial}
            filialText={filialText}
            voltage={voltage}
            handleVoltage={() => !voltage && handleVoltage(technicalSpecs.voltage)}
            handleStockTotal={handleStockTotal}
            copyToClipboard={() => copyToClipboard(objectID)}
            openTooltip={openTooltip}
            handleTooltip={handleTooltip}
            handleQtyRequested={{qtyRequested, setQtyRequested}}
            userFilialId={userFilialId}
            pplInfo={pplInfo}
            loading={loading}
            isFisicStore={isFisicStore}
            handleOpenDialog={{openDialog, setOpenDialog}}
            handleGuaranteeIdSelected={{guaranteeIdSelected, setGuaranteeIdSelected}}
            handleOpenVoltage={{openVoltageDialog, setOpenVoltageDialog}}
            stockText={stockText}
            deliveryType={deliveryType}
          />
        ) : (
          <ProductCard
            product={product}
            userFilialId={userFilialId}
            pplInfo={pplInfo}
            loading={loading}
            className={className}
            isFisicStore={isFisicStore}
            productAvailable={productAvailable}
            handleQtyRequested={{qtyRequested, setQtyRequested}}
            handleVoltageModal={handleVoltageModal}
            data={{
              stockText,
              filialText,
              localFilial,
              qtyLocalFilial,
            }}
            handleOpenDialog={{openDialog, setOpenDialog}}
            priceEstablishment={priceEstablishment}
            handleGuaranteeIdSelected={{guaranteeIdSelected, setGuaranteeIdSelected}}
            guaranteesOptions={guaranteesOptions}
            voltageOptions={voltageOptions}
            addProduct={() => addProduct(objectID, qtyRequested)}
            handleOpenVoltage={{openVoltageDialog, setOpenVoltageDialog}}
            qtyLocalFilial={qtyLocalFilial}
            localFilial={localFilial}
            stockText={stockText}
            filialText={filialText}
            voltage={voltage}
            handleVoltage={() => !voltage && handleVoltage(technicalSpecs.voltage)}
            deliveryType={deliveryType}
            handleStockTotal={handleStockTotal}
            copyToClipboard={() => copyToClipboard(objectID)}
            openTooltip={openTooltip}
            handleTooltip={handleTooltip}
          />
        )
      }
      <Snackbar
        className={snackbar}
        alertOptions={alert}
        setAlertOptions={setAlert}
      />
    </>
  )
};

Product.defaultProps = {
  className: '',
  userFilialId: 0,
  snack: null,
  pplInfo: null,
  isCheckout: false,
  loading: false,
  isFisicStore: false,
};

Product.propTypes = {
  className: PropTypes.string,
  product: PropTypes.instanceOf(Object).isRequired,
  userFilialId: PropTypes.number,
  addProduct: PropTypes.func.isRequired,
  snack: PropTypes.instanceOf(Object),
  filialUserLinked: PropTypes.string.isRequired,
  pplInfo: PropTypes.instanceOf(Object),
  isCheckout: PropTypes.bool,
  newCheckout: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  resetSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isFisicStore: PropTypes.bool,
};

const mapState = (state) => {
  const { snack } = state.main.app;
  const { checkout, loading } = state.main.checkout.geral;
  return ({
    ...state.main.app,
    user: state.auth.login.user,
    client: getClient(),
    checkout,
    loading,
    snack,
  });
};

export default connect(mapState, {
  getCheckout: getCheckoutAction,
  newCheckout: newCheckoutAction,
  resetSearch: resetSearchAction,
})(Product);
