import React, { useEffect, useState } from 'react';
import {
  Box, Button, Grid, IconButton, LinearProgress,
} from '@material-ui/core';
import { connect, useDispatch } from 'react-redux';
import {
  change,
  formValueSelector,
  reduxForm,
} from 'redux-form';
import * as PropTypes from 'prop-types';
import { Search } from '@material-ui/icons';
import useStyles from './styles';
import {
  cellphone,
  cnpjValid,
  email,
  maxLenght100,
  maxLenght9,
  maxLength14,
  minLength10,
  minLength3,
  minLength6,
  minLength8,
  required,
} from '../../../../../../shared/fields/validate';
import { InputFieldForm } from '../../../../../authClient/_components/forms';
import { InputCEPRender, InputCnpjRender, InputPhoneRender } from '../../../../../../shared/fields';
import { getZipcodeDataAction } from '../../../../../authClient/register/actions';
import { AlertMessage, CheckboxForm, SelectForm } from '../../../../../authClient/register/_components';
import {
  registerNewAddressClientPFAction,
  registerNewAddressClientPJAction,
} from '../../actions';
import { goBack } from '../../../../../../history';
import { getClientByDocumentAction } from '../../../../keyConsults/actions';
import CircularLoading from '../../../../../../shared/components/loading/CircularLoading';
import { getClient, setClient } from '../../../../../../shared/services/authClient';
import { Types } from '../../../../../authClient/login';

const AddressNewForm = ({
  handleSubmit,
  getZipcodeData,
  registerNewAddressClientPF,
  registerNewAddressClientPJ,
  addressSelector,
  client,
  loading,
  cnpj,
  keyConsultsLoading,
  getClientByDocument,
}) => {
  const {
    semNumero,
    bitGenerico,
    tipoContribuinte,
    isSuframa,
    numero,
    inscricaoEstadual,
    inscricaoSuframa,
  } = addressSelector;
  const keyConsultsMessageList = [
    'Consultando CNPJ na Receita. Por favor, aguarde',
    'Buscando os dados. Por favor, aguarde',
  ];
  const isCorporate = client && client.tipoPessoa === 'J';
  const showIE = tipoContribuinte !== 0 && [1, 6, 8].includes(tipoContribuinte);
  const dispatch = useDispatch();
  const [messageLoading, setMessageLoading] = useState('');

  const {
    addressGrid,
    editFormGrid,
    numberGrid,
    noNumberGrid,
    numberMainGrid,
    btnBox,
    cepInfoGrid,
    info,
    corporateAddressGrid,
    suframaMainGrid,
    suframaGrid,
  } = useStyles();

  const handleClient = () => {
    const cookiesClient = getClient();
    if (client && cookiesClient) {
      if (client.codCliente !== cookiesClient.codCliente) {
        setClient(client);

        dispatch({
          type: Types.SET_CLIENT_MATRIX_ID,
          payload: {
            clientMatrixId: client.codClienteMatriz || client.codCliente,
          },
        });

        dispatch({
          type: Types.SET_CLIENT_SUCCESS,
          payload: { client },
        });
      }
    }
  };

  const submitAddress = async () => {
    await handleClient();
    if (isCorporate) {
      await registerNewAddressClientPJ();
    } else {
      await registerNewAddressClientPF();
    }
  };

  const searchCEP = () => {
    window.open('http://www.buscacep.correios.com.br/', '_blank');
  };

  const handleCepRequest = () => {
    const { cep } = addressSelector;
    if (loading.zipcode) return;
    if (cep.length < 8) return;

    getZipcodeData(cep).then((res) => {
      dispatch(change('client/address/new', 'bitGenerico', res.flagcepgenerico));
      dispatch(change('client/address/new', 'logradouro', res.logradouro));
      dispatch(change('client/address/new', 'bairro', res.bairro));
      dispatch(change('client/address/new', 'cidade', res.localidade));
      dispatch(change('client/address/new', `${isCorporate ? 'estado' : 'uf'}`, res.uf));
    }).catch(() => {
      dispatch(change('client/address/new', 'bitGenerico', false));
      dispatch(change('client/address/new', 'rua', ''));
      dispatch(change('client/address/new', 'bairro', ''));
      dispatch(change('client/address/new', 'cidade', ''));
      dispatch(change('client/address/new', 'uf', ''));
      dispatch(change('client/address/new', `${isCorporate ? 'estado' : 'uf'}`, ''));
    });
  };

  const consultClientByCnpjRequest = () => {
    if (keyConsultsLoading.cnpj) return;
    if (cnpj.length < 8) return;

    getClientByDocument(cnpj).then((res) => {
      dispatch(change('client/address/new', 'bitGenerico', false));
      dispatch(change('client/address/new', 'tipoContribuinte', res.tipoContribuinte));
      dispatch(change('client/address/new', 'semNumero', res.semNumero));
      dispatch(change('client/address/new', 'logradouro', res.logradouro));
      dispatch(change('client/address/new', 'bairro', res.bairro));
      dispatch(change('client/address/new', 'cidade', res.cidade));
      dispatch(change('client/address/new', 'estado', res.estado));
      dispatch(change('client/address/new', 'cep', res.cep));
      dispatch(change('client/address/new', 'numero', res.numero));
      dispatch(change('client/address/new', 'razaoSocial', res.razaoSocial));
      dispatch(change('client/address/new', 'inscricaoEstadual', res.ie));
      setMessageLoading('');
    }).catch((_) => {
      dispatch(change('client/address/new', 'bitGenerico', false));
      dispatch(change('client/address/new', 'logradouro', ''));
      dispatch(change('client/address/new', 'bairro', ''));
      dispatch(change('client/address/new', 'cidade', ''));
      dispatch(change('client/address/new', 'estado', ''));
      dispatch(change('client/address/new', 'cep', ''));
      dispatch(change('client/address/new', 'numero', ''));
      dispatch(change('client/address/new', 'semNumero', ''));
      dispatch(change('client/address/new', 'razaoSocial', ''));
      dispatch(change('client/address/new', 'inscricaoEstadual', ''));
      dispatch(change('client/address/new', 'tipoContribuinte', ''));

      setMessageLoading('');
    });
  };

  useEffect(() => {
    dispatch(change('client/address/new', 'numero',
      // eslint-disable-next-line no-nested-ternary
      semNumero ? 'S/N'
        : (numero === 'S/N' ? '' : numero)));
  }, [semNumero]);

  useEffect(() => {
    dispatch(change('client/address/new', 'inscricaoEstadual',
      showIE ? inscricaoEstadual : ''));
  }, [showIE]);

  useEffect(() => {
    dispatch(change('client/address/new', 'inscricaoSuframa',
      isSuframa ? inscricaoSuframa : ''));
  }, [isSuframa]);

  useEffect(() => {
    let index = 0;
    if (!keyConsultsLoading.cnpj) {
      setMessageLoading('');
      return;
    }
    setMessageLoading(keyConsultsMessageList[index]);
    index = 1;

    const interval = setInterval(() => {
      setMessageLoading(keyConsultsMessageList[index]);
      index = index ? 0 : 1;
    }, 7000);

    return () => clearInterval(interval);
  }, [keyConsultsLoading.cnpj]);

  useEffect(() => {
    handleClient();
  }, []);

  return (
    <Grid className={editFormGrid}>
      <CircularLoading
        open={keyConsultsLoading.cnpj}
        message={messageLoading}
      />
      <form onSubmit={handleSubmit(submitAddress)}>
        <Grid container spacing={2}>
          {
            isCorporate ? (
              <>
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <InputFieldForm
                    name="cnpj"
                    component={InputCnpjRender}
                    placeholder="ex. XX.XXX.XXX/0001-XX"
                    label="CNPJ"
                    required
                    disabled={loading.customerDocumentValidate || keyConsultsLoading.cnpj}
                    onBlur={consultClientByCnpjRequest}
                    endAdornment={(
                      <IconButton
                        style={{ marginTop: 10 }}
                        onClick={consultClientByCnpjRequest}
                      >
                        <Search />
                      </IconButton>
                    )}
                    validate={[required, cnpjValid]}
                  />
                  <LinearProgress hidden={!keyConsultsLoading.cnpj} />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <InputFieldForm
                    name="razaoSocial"
                    minLength={6}
                    maxLength={100}
                    label="Razão Social"
                    validate={[required, minLength6, maxLenght100]}
                    required
                  />
                </Grid>

                <Grid item xs={12} className={corporateAddressGrid}>
                  <Grid item sm={6} xs={12}>
                    <SelectForm
                      name="tipoContribuinte"
                      label="Tipo de Contribuinte"
                      required
                      validate={[required]}
                      options={[
                        {
                          id: 1,
                          label: 'Contribuinte',
                        },
                        {
                          id: 5,
                          label: 'Não Contribuinte',
                        },
                        {
                          id: 8,
                          label: 'Não contribuinte com I.E',
                        },
                        {
                          id: 6,
                          label: 'Órgão Público',
                        },
                      ]}
                    />
                  </Grid>

                  {showIE && (
                    <Grid item sm={6} xs={12}>
                      <InputFieldForm
                        name="inscricaoEstadual"
                        label="Inscrição Estadual"
                        maxLength={14}
                        validate={[required, maxLength14]}
                        required
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <InputFieldForm
                    name="emailNf"
                    maxLength={60}
                    label="E-mail para Nota Fiscal"
                    validate={[email]}
                  />
                </Grid>

                <Grid xs={12} className={suframaMainGrid}>
                  <Grid
                    item
                    sm={6}
                    className={suframaGrid}
                    style={{ marginTop: isSuframa ? 18 : 0 }}
                  >
                    <Box height="100%">
                      <CheckboxForm
                        name="isSuframa"
                        label="Tem inscrição no SUFRAMA"
                      />
                    </Box>
                  </Grid>

                  { isSuframa && (
                    <Grid item sm={6}>
                      <InputFieldForm
                        name="inscricaoSuframa"
                        maxLength={9}
                        label="Número SUFRAMA"
                        validate={[required, maxLenght9]}
                        required
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid item sm={6} xs={12}>
                  <InputFieldForm
                    name="telefoneContato"
                    component={InputPhoneRender}
                    label="Telefone de Contato"
                    validate={[required, cellphone]}
                    required
                  />
                  <span className={info}>
                    Este número será usado apenas para auxiliar a entrega
                  </span>
                </Grid>

                <Grid item xs={12}>
                  <AlertMessage icon="warning">
                    {`Por ser empresa, o endereço de envio e faturamento
                  deve ser o mesmo. Validamos os dados junto à SEFAZ.`}
                  </AlertMessage>
                </Grid>
              </>
            ) : (
              <>
                <Grid item sm={6} xs={12}>
                  <InputFieldForm
                    name="responsavel"
                    label="Nome Completo"
                    minLength={3}
                    maxLength={50}
                    validate={[required, minLength3]}
                    required
                  />
                  <span className={info}>Como aparece no RG</span>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <InputFieldForm
                    name="telefoneContato"
                    component={InputPhoneRender}
                    label="Telefone de Contato"
                    validate={[required, cellphone]}
                    required
                  />
                  <span className={info}>
                    Este número será usado apenas para auxiliar a entrega
                  </span>
                </Grid>
              </>
            )
          }

          <Grid item sm={6} xs={12}>
            <InputFieldForm
              name="cep"
              label="CEP"
              component={InputCEPRender}
              placeholder="ex. 12345-789"
              onBlur={handleCepRequest}
              endAdornment={(
                <IconButton
                  style={{ marginTop: 10 }}
                  onClick={handleCepRequest}
                >
                  <Search />
                </IconButton>
              )}
              validate={[required, minLength8]}
              required
            />
            <LinearProgress hidden={!loading.zipcode} />
          </Grid>
          <Grid item sm={6} xs={12} className={cepInfoGrid}>
            <Button
              onClick={searchCEP}
            >
              Não sei o meu CEP
            </Button>
          </Grid>

          <Grid item xs={12} className={addressGrid}>
            <Grid item sm={6} xs={12}>
              <InputFieldForm
                name="logradouro"
                label="Rua / Avenida"
                maxLength={60}
                validate={[required]}
                required
                disabled={loading.zipcode || !bitGenerico}
              />
            </Grid>

            <Grid sm={6} xs={12} className={numberMainGrid}>
              <Grid item sm={6} className={numberGrid}>
                <InputFieldForm
                  name="numero"
                  maxLength={6}
                  type="number"
                  label="Número"
                  validate={!semNumero && [required]}
                  required
                  disabled={loading.zipcode || semNumero}
                />
              </Grid>

              <Grid item sm={6} className={noNumberGrid}>
                <Box marginLeft={2} height="100%" marginTop={2}>
                  <CheckboxForm
                    name="semNumero"
                    label="Sem Número"
                    color="primary"
                    disabled={loading.zipcode}
                    active
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            sm={6}
          >
            <InputFieldForm
              name="complemento"
              label="Complemento"
              maxLength={50}
              disabled={loading.zipcode}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <InputFieldForm
              name="bairro"
              label="Bairro"
              validate={[required]}
              maxLength={50}
              required
              disabled={loading.zipcode || !bitGenerico}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <InputFieldForm
              name="cidade"
              label="Cidade"
              validate={[required]}
              maxLength={50}
              required
              disabled
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <InputFieldForm
              name={isCorporate ? 'estado' : 'uf'}
              label="Estado"
              validate={[required]}
              maxLength={2}
              required
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <InputFieldForm
              style={{ height: 214 }}
              name="informacoesAdicionais"
              label="Informações Adicionais para Entrega"
              required={semNumero}
              disabled={loading.zipcode}
              multiline
              maxLength={200}
              rows={4}
              validate={semNumero && [required, minLength10]}
              placeholder="Descrição da fachada, pontos de referência, informações de segurança etc."
            />
          </Grid>

          <Grid item xs={12}>
            <Box className={btnBox}>
              <Button
                onClick={() => goBack()}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
              >
                Adicionar Endereço
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

const addressOptions = [
  'cep',
  'bitGenerico',
  'semNumero',
  'tipoContribuinte',
  'numero',
  'inscricaoEstadual',
  'isSuframa',
  'inscricaoSuframa',
];

const mapState = (state) => {
  const { snack } = state.main.app;
  const { client } = state.authClient;
  const selector = formValueSelector('client/address/new');
  const addressSelector = selector(state, ...addressOptions);
  const cnpj = selector(state, 'cnpj');

  return {
    snack,
    client,
    cnpj,
    addressSelector,
    keyConsultsLoading: state.keyConsults.loading,
    ...state.register,
  };
};

AddressNewForm.defaultProps = {
  cnpj: '',
};

AddressNewForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  getZipcodeData: PropTypes.func.isRequired,
  registerNewAddressClientPF: PropTypes.func.isRequired,
  registerNewAddressClientPJ: PropTypes.func.isRequired,
  addressSelector: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.instanceOf(Object).isRequired,
  client: PropTypes.instanceOf(Object).isRequired,
  keyConsultsLoading: PropTypes.instanceOf(Object).isRequired,
  getClientByDocument: PropTypes.func.isRequired,
  cnpj: PropTypes.string,
};

export default connect(mapState, {
  getZipcodeData: getZipcodeDataAction,
  registerNewAddressClientPF: registerNewAddressClientPFAction,
  registerNewAddressClientPJ: registerNewAddressClientPJAction,
  getClientByDocument: getClientByDocumentAction,
})(reduxForm({
  onSubmitFail: (errors) => {
    const firstElementError = Object.keys(errors).map((fieldName) => `[name="${fieldName}"]`).join(',');
    document.querySelector(firstElementError).focus();
  },
  form: 'client/address/new',
  initialValues: {
    telefoneContato: '',
    cep: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    uf: '',
    complemento: '',
    informacoesAdicionais: '',
    responsavel: '',
    semNumero: false,
    bitPrincipal: true,
    bitInativo: false,
    bitGenerico: false,
    codCliente: '',
    tipoContribuinte: null,
    inscricaoEstadual: '',
    estado: '',
    idFilial: '',
    razaoSocial: '',
    cnpj: '',
    emailNf: '',
    isSuframa: false,
    inscricaoSuframa: '',
  },
})(AddressNewForm));
