import apiService from '../../../shared/services/apiService';

// eslint-disable-next-line import/prefer-default-export
export const getDiscountDetails = async (descountId, idUser) => {
  const {
    data: { content },
  } = await apiService.get(`/solicitacao-desconto/detalhes/${descountId}/aprovador/${idUser}`);

  return {
    content,
  };
};
