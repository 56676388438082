import { handleActions } from 'redux-actions';
import Types from './types';

const INITIAL_STATE = {
  orders: null,
  meta: {
    page: 1,
    rpp: 10,
    size: 0,
    totalpages: 0
  },
  orderById: null,
  detailedTracking: null,
  isLoading: false,
  isLoadingById: false,
  isLoadingSendEmail: false,
  isLoadingNewCheckout: false,
  isLoadingNfe: false,
};

export default handleActions({
  [Types.GET_ORDERS_LIST_REQUEST]: (state) => ({
    ...state,
    isLoading: true,
    orders: null,
      meta: {
        page: 1,
        rpp: 10,
        size: 0,
        totalpages: 0
      },
  }),
  [Types.GET_ORDERS_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    orders: payload.orders,
    meta: payload.meta
  }),
  [Types.GET_ORDERS_LIST_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
    orders: null,
      meta: {
        page: 1,
        rpp: 10,
        size: 0,
        totalpages: 0
      },
  }),

  [Types.GET_ORDERS_BY_ID_REQUEST]: (state) => ({
    ...state,
    isLoadingById: true,
    orderById: null,
  }),
  [Types.GET_ORDERS_BY_ID_SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoadingById: false,
    orderById: payload.orderById
  }),
  [Types.GET_ORDERS_BY_ID_FAILURE]: (state) => ({
    ...state,
    isLoadingById: false,
    orderById: null
  }),
  [Types.CLEAR_ORDERS_BY_ID]: (state) => ({
    ...state,
    orderById: null
  }),

  [Types.SEND_EMAIL_REQUEST]: (state) => ({
    ...state,
    isLoadingSendEmail: true
  }),
  [Types.SEND_EMAIL_SUCCESS]: (state) => ({
    ...state,
    isLoadingSendEmail: false
  }),
  [Types.SEND_EMAIL_FAILURE]: (state) => ({
    ...state,
    isLoadingSendEmail: false
  }),

  [Types.NEW_CHECKOUT_REQUEST]: (state) => ({
    ...state,
    isLoadingNewCheckout: true
  }),
  [Types.NEW_CHECKOUT_SUCCESS]: (state) => ({
    ...state,
    isLoadingNewCheckout: false
  }),
  [Types.NEW_CHECKOUT_FAILURE]: (state) => ({
    ...state,
    isLoadingNewCheckout: false
  }),

  [Types.GET_NFE_REQUEST]: (state) => ({
    ...state,
    isLoadingNfe: true
  }),
  [Types.GET_NFE_SUCCESS]: (state) => ({
    ...state,
    isLoadingNfe: false
  }),
  [Types.GET_NFE_FAILURE]: (state) => ({
    ...state,
    isLoadingNfe: false
  }),

  [Types.GET_DETAILED_DELIVERY_REQUEST]: (state) => ({
    ...state,
    isLoading: true
  }),
  [Types.GET_DETAILED_DELIVERY_SUCCESS]: (state, { payload }) => ({
    ...state,
    isLoading: false,
    detailedTracking: payload.detailedTracking
  }),
  [Types.GET_DETAILED_DELIVERY_FAILURE]: (state) => ({
    ...state,
    isLoading: false
  }),
  [Types.CLEAR_DETAILED_DELIVERY]: (state) => ({
    ...state,
    isLoading: false,
    detailedTracking: null
  }),
}, INITIAL_STATE);
