import { handleActions, combineActions } from 'redux-actions';
import { combineReducers } from 'redux';
import types from './types';
import advanced from './_components/payment/advanced/reducer';
import payment from './_components/payment/reducer';

const INITIAL_STATE = {
  loading: false,
  loadingCheckout: false,
  saving: false,
  printing: false,
  message: null,
  forceAddInfo: false,
  invalidDialog: null,
  checkout: { customer: { address: {} } },
  condiditions: null,
  dialogCouponOpened: false,
  dialogDiscountOpened: false,
  dialogSchedule: false,
  justificativa: false,
  aguardandoSolicitacao: false,
  dialogConfirm: false,
  chosedFreight: false,
  chosedSchedule: false,
  dateSchedule: '',
  showItem: null,
  showItemSearch: null,
  freighConfirmed: false,
  couponValeList: false,
  totalCouponsVouchers: 0,
  resetSearch: false,
  inputSearch: null,
  creditRequestStatus: null,
  loadingCreditRequestStatus: false,
  category: null,
  categoryHierarchy: null,
  qtyProducts: 0,
};

const startLoading = combineActions(
  types.GET_CHECKOUT_REQUEST,
  types.CHECKOUT_DISCOUNT_REQUEST,
  types.ITEM_DISCOUNT_REQUEST,
  types.REMOVE_ITEM_REQUEST,
  types.UPDATE_INFO_REQUEST,
  types.SUBMIT_ORDER_REQUEST,
  types.PDF_REQUEST,
  types.ADD_ALL_VOUCHERS_REQUEST,
  types.UPDATE_ITEM_REQUEST,
  types.GET_TOTAL_COUPONS_AND_VOUCHERS_REQUEST,
  types.NEW_CHECKOUT_REQUEST,
  types.UPDATE_ADD_INFO_REQUEST,
  types.GET_ADD_INFO_REQUEST
);

const stopLoading = combineActions(
  types.CHECKOUT_DISCOUNT_SUCCESS,
  types.CHECKOUT_DISCOUNT_FAIL,
  types.ITEM_DISCOUNT_SUCCESS,
  types.ITEM_DISCOUNT_FAIL,
  types.REMOVE_ITEM_SUCCESS,
  types.REMOVE_ITEM_FAIL,
  types.UPDATE_INFO_SUCCESS,
  types.UPDATE_INFO_FAIL,
  types.SUBMIT_ORDER_SUCCESS,
  types.SUBMIT_ORDER_FAIL,
  types.PDF_SUCCESS,
  types.PDF_FAIL,
  types.ADD_ALL_VOUCHERS_SUCCESS,
  types.ADD_ALL_VOUCHERS_FAIL,
  types.GET_CREDIT_AVAILABLE_FAIL,
  types.GET_TOTAL_COUPONS_AND_VOUCHERS_SUCCESS,
  types.GET_TOTAL_COUPONS_AND_VOUCHERS_FAIL,
  types.NEW_CHECKOUT_FAILURE,
  types.UPDATE_ADD_INFO_FAIL,
  types.GET_ADD_INFO_FAIL
);

export const geral = handleActions({
  [startLoading]: (state) => ({
    ...state,
    loading: true,
  }),
  [stopLoading]: (state) => ({
    ...state,
    loading: false,
  }),
  [types.RESET_CHECKOUT]: (state) => ({
    ...state,
    checkout: {
      customer: {
        address: {},
      },
    },
  }),
  [types.GET_CHECKOUT_REQUEST]: (state) => ({
    ...state,
    loadingCheckout: true,
  }),
  [types.GET_CHECKOUT_FAIL]: (state) => ({
    ...state,
    loadingCheckout: false,
  }),
  [types.GET_CHECKOUT_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    loadingCheckout: false,
    checkout: payload.checkout,
    chosedFreight: payload.chosedFreight || state.chosedFreight,
  }),
  [types.UPDATE_ITEM_REQUEST]: (state) => ({
    ...state,
    saving: true,
  }),
  [types.UPDATE_ITEM_SUCCESS]: (state) => ({
    ...state,
    saving: false,
  }),
  [types.OPEN_DIALOG_COUPON]: (state) => ({
    ...state,
    dialogCouponOpened: true,
  }),
  [types.OPEN_DIALOG_SCHEDULE]: (state) => ({
    ...state,
    dialogSchedule: true,
  }),
  [types.CLOSE_DIALOG_SCHEDULE]: (state) => ({
    ...state,
    dialogSchedule: false,
  }),
  [types.CLOSE_DIALOG_COUPON]: (state) => ({
    ...state,
    dialogCouponOpened: false,
  }),
  [types.OPEN_DIALOG_DISCOUNT]: (state) => ({
    ...state,
    dialogDiscountOpened: true,
    justificativa: false,
    aguardandoSolicitacao: false,
  }),
  [types.OPEN_DIALOG_DISCOUNT_OBS]: (state) => ({
    ...state,
    dialogDiscountOpened: true,
    justificativa: true,
    aguardandoSolicitacao: false,
  }),
  [types.OPEN_DIALOG_DISCOUNT_OBS_DESCRIPTION]: (state, { description }) => ({
    ...state,
    observacaoDesconto: description,
  }),
  [types.OPEN_DIALOG_DISCOUNT_REQUEST]: (state) => ({
    ...state,
    dialogDiscountOpened: true,
    justificativa: false,
    aguardandoSolicitacao: true,
  }),
  [types.CLOSE_DIALOG_DISCOUNT]: (state) => ({
    ...state,
    dialogDiscountOpened: false,
  }),
  [types.OPEN_CONFIRM]: (state) => ({
    ...state,
    dialogConfirm: true,
    freighConfirmed: false,
  }),
  [types.COUPON_VALE_LIST_LOAD]: (state) => ({
    ...state,
    couponValeList: false,
  }),
  [types.COUPON_VALE_LIST]: (state) => ({
    ...state,
    couponValeList: true,
  }),
  [types.CONFIRM_FREIGHT]: (state) => ({
    ...state,
    freighConfirmed: true,
  }),
  [types.CLOSE_CONFIRM]: (state) => ({
    ...state,
    dialogConfirm: false,
  }),
  [types.INSERT_COUPON_REQUEST]: (state) => ({
    ...state,
    saving: true,
  }),
  [types.INSERT_COUPON_SUCCESS]: (state) => ({
    ...state,
    saving: false,
    dialogCouponOpened: false,
  }),
  [types.INSERT_COUPON_FAIL]: (state) => ({
    ...state,
    saving: false,
  }),
  [types.SHOW_ADD_INFO]: (state) => ({
    ...state,
    forceAddInfo: true,
  }),
  [types.HIDE_ADD_INFO]: (state) => ({
    ...state,
    forceAddInfo: false,
  }),
  [types.SHOW_INVALID_DIALOG]: (state, { payload }) => ({
    ...state,
    invalidDialog: payload.invalidDialog,
  }),
  [types.HIDE_INVALID_DIALOG]: (state) => ({
    ...state,
    invalidDialog: null,
  }),
  [types.UNCHOSE_FREIGHT]: (state) => ({
    ...state,
    chosedFreight: false,
  }),
  [types.CHOSE_FREIGHT]: (state) => ({
    ...state,
    chosedFreight: true,
  }),
  [types.CHOSE_SCHEDULE]: (state , { payload }) => ({
    ...state,
    chosedSchedule: payload,
  }),
  [types.DATE_SCHEDULE]: (state , { payload }) => ({
    ...state,
    dateSchedule: payload,
  }),
  [types.SHOW_ITEM]: (state, { payload }) => ({
    ...state,
    showItem: payload.item,
  }),
  [types.HIDE_ITEM]: (state) => ({ ...state, showItem: null }),
  [types.SHOW_ITEM_SEARCH]: (state, { payload }) => ({
    ...state,
    showItemSearch: payload,
  }),
  [types.HIDE_ITEM_SEARCH]: (state) => ({ ...state, showItemSearch: null }),
  [types.OPEN_CUSTOM_LOADING]: (state, { payload }) => ({
    ...state,
    printing: payload.status,
    message: payload.message,
  }),
  [types.CLOSE_CUSTOM_LOADING]: (state) => ({
    ...state,
    printing: false,
    message: null,
  }),
  [types.GET_TOTAL_COUPONS_AND_VOUCHERS_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    totalCouponsVouchers: payload.totalCouponsVouchers,
  }),
  [types.RESET_SEARCH]: (state, { reset, inputValue }) => ({
    ...state,
    inputSearch: inputValue || '',
    resetSearch: reset,
  }),
  [types.SEARCH_BY_CATEGORY]: (state, { categoryFilter, categoryList }) => ({
    ...state,
    category: categoryFilter,
    categoryHierarchy: categoryList,
  }),
  [types.NEW_CHECKOUT_SUCCESS]: (state) => ({
    ...state,
    loading: false,
  }),
  [types.CHECKOUT_LOADING]: (state, { payload }) => ({
    ...state,
    loading: payload.loading,
  }),
  [types.GET_CREDIT_REQUEST_STATUS_REQUEST]: (state) => ({
    ...state,
    loadingCreditRequestStatus: true,
  }),
  [types.GET_CREDIT_REQUEST_STATUS_SUCCESS]: (state, { payload }) => ({
    ...state,
    loadingCreditRequestStatus: false,
    creditRequestStatus: payload,
  }),
  [types.GET_CREDIT_REQUEST_STATUS_FAIL]: (state) => ({
    ...state,
    loadingCreditRequestStatus: false,
    creditRequestStatus: null,
  }),
  [types.UPDATE_ADD_INFO_SUCCESS]: (state) => ({
    ...state,
    loading: false
  }),
  [types.GET_ADD_INFO_SUCCESS]: (state, { payload }) => ({
    ...state,
    checkout: {
      ...state.checkout,
      customer: {
        ...state.checkout.customer,
        address: {
          ...state.checkout.customer.address,
          addInfo: payload
        }
      }
    },
    loading: false,
  })
}, INITIAL_STATE);

export default combineReducers({
  geral,
  advanced,
  payment,
});
